.CancelUploadFooter .cancel-upload-footer-inner {
    align-content: flex-end;
    background-color: var(--white2-color);
    bottom: 0;
    flex: 1;
    flex-wrap: nowrap;
    margin-top: var(--spacing-4x);
    margin-right: 0;
    width: 100%;
}

.CancelUploadFooter .cancel-upload-footer-inner .Button {
   flex-grow: 1;
}

.CancelUploadFooter .cancel-upload-footer-inner .upload-progress-container {
    margin-left: 0;
    margin-right: var(--spacing-4x);
    width: var(--spacing-10x);
 }

.CancelUploadFooter .cancel-upload-footer-inner .upload-progress-container.hide-button {
    margin-left: var(--spacing-4x);
    margin-right: var(--spacing-4x);
    width: var(--spacing-10x);
}

.CancelUploadFooter .cancel-upload-footer-message {
    border-radius: var(--spacing-4x);
    border-width: 2px;
    border-style: solid;
    padding: var(--spacing-4x);
    margin-right: 0;
}

.CancelUploadFooter .cancel-upload-footer-inner.warning {
    border-color: var(--warning-color);
    background-color: var(--warning-tint2-color);
    margin-right: 0;
}

.CancelUploadFooter .cancel-upload-footer-inner.info {
    border-color: var(--sky-color);
    background-color: var(--sky-tint2-color);
    margin-right: 0;
}

/* Confirm Cancel Button */

.CancelUploadFooter .cancel-upload-footer-inner .Button {
    flex-basis: 47%;
}

.CancelUploadFooter .cancel-upload-footer-inner div {
    padding-right: 0;
    padding-left: 0;
}

.CancelUploadFooter .button-confirm-cancel.Button.primary {
    order: 1;
    background: var(--error-color);
    color: var(--white2-color);
    border-color: var(--error-color);
}

.CancelUploadFooter .button-confirm-cancel.Button.primary:hover {
    background: var(--error-shade2-color);
    border-color: var(--error-tint2-color);
}

.CancelUploadFooter .button-confirm-cancel.Button.primary:active {
    background: var(--error-shade-color);
    border-color: var(--error-tint2-color);
}