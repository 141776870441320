.InfoTooltip .tooltip-content {
    background: white;
    padding: var(--spacing-8x);
    padding-bottom: var(--spacing-12x);
    border-top-left-radius: var(--spacing-8x);
    border-top-right-radius: var(--spacing-8x);
}

.InfoTooltip .szh-menu__item {
    cursor: auto;
    pointer-events: none;
}

.InfoTooltip .button-tooltip-info {
    height: auto;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {

    .InfoTooltip .tooltip-content {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        padding: var(--spacing-2x);
        max-width: 300px;
    }
    
}