.DropdownMenuOptionsMobile .avatardropdown-inner-content,
.DropdownMenuContent.AvatarDropdown {
    background: var(--white2-color);
    border-radius: var(--spacing-4x) var(--spacing-4x) 0 0;
    padding: var(--spacing-2x) var(--spacing-6x) var(--spacing-4x);
}

.DropdownMenuTarget .Avatar {
    cursor: pointer;
}

.DropdownMenuContent.AvatarDropdown .szh-menu {
    padding-top: 0;
}

.avatardropdown-inner-content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.avatardropdown-inner-content .DropdownPanel.family {
    border-top: 2px solid var(--grey3-color);
}

@media only screen and (min-width: 541px) {

    .DropdownMenuOptionsMobile .avatardropdown-inner-content,
    .DropdownMenuContent.AvatarDropdown {
        background: var(--transparent-color);
    }

    .DropdownMenuContent.AvatarDropdown {
        border-radius: var(--spacing-4x);
    }

    .avatardropdown-inner-content {
        width: 326px;
    }
}