svg.rsm-svg {
    width: 100%;
    height: 100%;
}

.map-tooltip .game-summary-row {
    padding: 0;
}

.map-tooltip .group-tip {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--grey2-color);
}

div.__react_component_tooltip.map-tooltip {
    box-shadow: var(--shadow-sm);
    border-radius: 5px;
}

.circle-appear {
    animation: pulse ease-in-out 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0);
    }
    30% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
