.GamesListGame .text-container {
    max-width: calc(100% - 80px - var(--spacing-2x));
}

.GamesListGame .text-top {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.GamesListGame img {
    border-radius: var(--spacing);
}

.GamesListGame:hover .text-top {
    color: var(--accent-color-dark);
}

.GamesListGame .img-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GamesListGame .churned-overlay {
    background-color: rgba(0,0,0,0.3);
    height: 48px;
    position: absolute;
    width: 80px;
    border-radius: var(--spacing);
    top: 0;
}

.GamesListGame .icon-lock {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

}
