.PlaylistCreator .Input input {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - var(--spacing-2x));
}


@media only screen and (min-width: 541px) and (min-height: 541px) {
    
    .PlaylistCreator {
        max-height: 375px; 
        min-height: min(375px, 90vh);   
    }
    
}