.toast {
    position: fixed;
    z-index: 20000;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s;
    overflow: hidden;
}

.toast.in, .toast.out {
    top: 100px;
    left: 0;
    height: 56px;
    width: 100%;
}
.toast.in {
    opacity: 1;
}
.toast.out {
    opacity: 0;
}

.toast .toast-message {
    background-color: rgba(74, 74, 74, 0.5);
    line-height: 2;
    padding: 0 20px;
    border-radius: 28px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    display: inline-block;
}


@media only screen and (min-width: 800px) {
    .toast.in, .toast.out {
        top: calc(50% - 75px);
        height: 150px;
    }
    .toast .toast-message {
        padding: 0 50px;
        border-radius: 100px;
        font-size: 48px;
        line-height: 150px;
    }
}
