.NotificationPreviewContent .preview-content-subtitle {
    width: 100%;
}

.NotificationPreviewContent .preview-content-tooltip {
    background: var(--sky-color);
    border-radius: var(--spacing-2x);
    padding: var(--spacing-2x) var(--spacing-4x);
}

.NotificationPreviewContent .tooltip-arrow {
    width: 0;
    height: 0;
    border-left: var(--spacing-2x) solid transparent;
    border-right: var(--spacing-2x) solid transparent;
    border-top: var(--spacing-2x) solid var(--sky-color);
    align-self: end;
    margin-right: var(--spacing-8x);
}

.NotificationPreviewContent .buttons-row {
    width: 100%;
}