.web-notification {
    background: var(--white2-color);
    width: 100vw;
    cursor: pointer;
    align-items: center;
    padding: var(--spacing-6x) var(--spacing-9x) var(--spacing-6x) var(--spacing-4x)
}

.web-notification.web-notification-nonclickable {
    cursor: default;
}

.web-notification .notification-circle {
    width: var(--spacing-3x);
    height: var(--spacing-3x);
    background: var(--error-color);
    border-radius: var(--spacing-5x);
}

.web-notification .notification-circle.viewed {
    visibility: hidden;
}

.web-notification .web-notification-middle-content {
    width: calc(100vw - 52px - 92px)
}

.web-notification .web-notification-image {
    width: 185px;
    height: 100px;
    border-radius: var(--spacing-4x);
}

.web-notification [class^="icon-"]:before,
.web-notification [class*=" icon-"]:before {
    margin-left: 0;
    margin-right: 0;
}

.web-notification .web-notification-side-info {
    position: relative;
    width: 100%;
    margin-left: var(--spacing-19x);
    margin-top: var(--spacing);
}

.web-notification .notification-button-play {
    position: absolute;
    top: 34px;
    left: var(--spacing-19x);
}

.web-notification .notification-user-celebration-container {
    position: relative;
}

.web-notification .notification-user-celebration {
    top: 5px;
    right: 18px;
    position: absolute;
    z-index: 400;
}

.web-notification:active {
    background: var(--sky-tint2-color);
}

.web-notification.web-notification-nonclickable:active {
        background: var(--white2-color);
}

@media only screen and (min-width: 541px) {
    .web-notification {
        padding: var(--spacing-4x) var(--spacing-9x)
    }

    .web-notification:hover {
        background: var(--sky-tint2-color);
    }


    .web-notification.web-notification-nonclickable:hover {
        background: var(--white2-color);
    }

    .web-notification .web-notification-middle-content {
        margin-right: var(--spacing-9x);
        width: 350px;
    }

    .web-notification .web-notification-side-info {
        position: relative;
        width: 184px;
        margin-left: unset;
        margin-top: unset;
    }
}