:root {
    /** Color Variables **/
    --accent-color: #37C2AC;
    --accent-color-dark: #30A290;
    --component-border-color: #CCC;

    /** Font Size Variables **/
    --body-font-size: 16px;
    --chip-font-size: 14px;
    --small-font-size: 12px;
    --heading1-font-size: 50px;
    --heading2-font-size: 38px;
    --heading3-font-size: 28px;
    --heading4-font-size: 21px;
    --heading5-font-size: 12px;

    /** Font Weight Variables **/
    --body-font-weight: normal;
    --bold-font-weight: bold;
    --heavy-font-weight: bolder;

    /** Font Variables **/
    --heading-font-family: 'Montserrat', sans-serif;
    --body-font-family: 'Open Sans', sans-serif;

    /** Padding and Margin Variables **/
    --spacing: 4px;
    --spacing-2x: 8px;
    --spacing-3x: 12px;
    --spacing-4x: 16px;
    --spacing-5x: 20px;
    --spacing-6x: 24px;
    --spacing-7x: 28px;
    --spacing-8x: 32px;
    --spacing-9x: 36px;
    --spacing-10x: 40px;
    --spacing-11x: 44px;
    --spacing-12x: 48px;
    --spacing-13x: 52px;
    --spacing-14x: 56px;
    --spacing-15x: 60px;
    --spacing-16x: 64px;
    --spacing-17x: 68px;
    --spacing-18x: 72px;
    --spacing-19x: 76px;
    --spacing-20x: 80px;

    /* Updated Brand Colors */
    --teal-50-color: #1F616D;
    --teal-40-color: #18929A;
    --teal-30-color: #7CDCD1;
    --teal-30-hover-color: #66D6C9;
    --teal-30-pressed-color: #52D1C2;
    --teal-20-color: #C0EEE8;
    --teal-10-color: #E7F8F5;

    --red-50-color: #F35C2B;
    --red-40-color: #F5774E;
    --red-40-hover-color: #F46334;
    --red-40-pressed-color: #F2511C;
    --red-30-color: #F79272;
    --red-20-color: #FBC9B8;
    --red-10-color: #FDDED5;

    --yellow-50-color: #F8FC52;
    --yellow-40-color: #F9FC6F;
    --yellow-40-hover-color: #F8FC55;
    --yellow-40-pressed-color: #F7FB3C;
    --yellow-30-color: #FAFD8C;
    --yellow-20-color: #FDFEC5;
    --yellow-10-color: #FEFEDC;

    --green-tint-color: #72F380;
    --green-tint-hover-color: #5BF16B;
    --green-tint-pressed-color: #43EF56;

    --blue-tint-color: #75D3FD;
    --blue-tint-hover-color: #63CDFD;
    --blue-tint-pressed-color: #4AC5FC;


    --charcoal-50-color: #393C3C;
    --charcoal-40-color: #5A5C5C;
    --charcoal-30-color: #7B7D7D;
    --charcoal-20-color: #BDBEBE;
    --charcoal-10-color: #D7D8D8;
    --charcoal-10-hover-color: #CCCCCC;
    --charcoal-10-pressed-color: #BFC0C0;
    --charcoal-1-color: #F2F2F2;
    --charcoal-1-hover-color: #E5E6E6;
    --charcoal-1-pressed-color: #D8D9D9;

    --charcoal-50-transparent-75-color: rgba(57, 60, 60, 0.75);
    --charcoal-50-transparent-50-color: rgba(57, 60, 60, 0.50);
    --charcoal-50-transparent-25-color: rgba(57, 60, 60, 0.25);

    --sand-color: #FCF8F4;

    --white-color: #FFFFFF;
    --white-hover-color: #F2F2F2;
    --white-pressed-color: #E5E6E6;


    --white-transparent-90-color: rgba(255, 255, 255, 0.9);
    --white-transparent-75-color: rgba(255, 255, 255, 0.75);
    --white-transparent-50-color: rgba(255, 255, 255, 0.50);
    --white-transparent-25-color: rgba(255, 255, 255, 0.25);
    /* End updated brand colors */

    /** Colors **/
    --transparent-color: transparent;
    --other-note-color: #FF00FF;

    --trace-shade-color: #278878;
    --trace-shade2-color: #32AF9B;
    --trace-regular-color: #37C2AC;
    --trace-color: #37C2AC;
    --trace-tint1-color: #73D4C5;
    --trace-tint2-color: #AFE7DE;

    --rust-shade-color: #9F5A3E;
    --rust-regular-color: #E38159;
    --rust-color: #E38159;
    --rust-tint1-color: #EBA78B;
    --rust-tint2-color: #F4CDBD;

    --sky-shade-color: #468BAC;
    --sky-shade2-color: #5EBBE8;
    --sky-regular-color: #64C6F6;
    --sky-color: #64C6F6;
    --sky-tint1-color: #93D7F9;
    --sky-tint2-color: #C1E8FB;

    --error-shade-color: #A53A47;
    --error-shade2-color: #E04F61;
    --error-regular-color: #EC5366;
    --error-color: #EC5366;
    --error-tint1-color: #F28794;
    --error-tint2-color: #FDEEF0;

    --warning-shade-color: #B08819;
    --warning-regular-color: #FBC224;
    --warning-color: #FBC224;
    --warning-tint1-color: #FCD466;
    --warning-tint2-color: #FEF3D3;

    --confirmed-shade-color: #589417;
    --confirmed-regular-color: #7ED321;
    --confirmed-color: #7ED321;
    --confirmed-tint1-color: #A5E064;
    --confirmed-tint2-color: #E5F6D3;

    --black-color: #323333;
    --pure-black-color: #000000;
    --grey1-color: #656666;
    --grey2-color: #979999;
    --grey3-color: #CACCCC;
    --grey4-color: #F0F2F2;
    --grey4-pressed-color: #E3E6E5;
    --grey5-color: #DEE0E0;
    --white1-color: #F8FAFA;
    --white2-color: #FFFFFF;

    --shadow-xs: 0px 2px 2px rgba(50, 51, 51, 0.2);
    --shadow-sm: 0px 4px 4px rgba(50, 51, 51, 0.2);
    --shadow-md: 0px 8px 8px rgba(50, 51, 51, 0.2);
    --shadow-lg: 0px 16px 16px rgba(50, 51, 51, 0.2);
    --notification-shadow: 0px 8px 24px rgba(24, 84, 74, 0.4);
    --grey-transparent-color: rgba(50, 51, 51, 0.5);
    --grey-transparent-hover-color: rgba(50, 51, 51, 0.75);
    --white-transparent-color: rgba(255, 255, 255, 0.5);
    --white2-transparent-color: rgba(255, 255, 255, 0.25);

    --mobile-port-break: 540px;
    --mobile-land-break: 541px;
    --tablet-break: 768px;
    --desktop-break: 1024px;
}
