.UploadMoment .Page .Column {
    height: auto;
}

.UploadMoment {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: none;
    margin: 0px;
}

.UploadMoment .dropzone-container-inner {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.UploadMoment .upload-moment-preview-container {
    align-self: center;
    background-color: var(--white2-color);
    flex-direction: column;
    position: absolute;
    height: 100%;
    max-height: 100vh;
    width: 100%;
}

.UploadMoment .FooterSection {
    flex-direction: column;
    width: 100%;
}

.UploadMoment .Shade {
    display: none;
}

.UploadMoment.Row {
    flex-wrap: wrap;
    height: 100%;
}

.UploadMoment.open {
    display: block;
}

.UploadMomentHeader {
    align-items: center;
    position: relative;
}

.upload-moment-preview {
    position: relative;
}

.upload-moment-preview .dropzone-container {
    height: 100%;
    width: 100%;
}

.upload-moment-step-content {
    padding: var(--spacing-6x);
    flex-grow: 1;
}

.upload-moment .sport-type {
    width: 100%;
}

.upload-moment-preview .upload-moment-preview-progress-bar {
    margin-top: -5px;
    z-index: 505;
}

.UploadMoment .upload-moment-controls {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
}

.UploadMoment .upload-moment-controls .controls-content {
    max-height: calc(100% - var(--spacing-14x) - var(--spacing-14x) - var(--spacing-8x));
    flex-grow: 1;
    overflow-y: auto;
}

.upload-moment-preview {
    background: var(--black-color);
}

.upload-moment-preview,
.upload-moment-controls {
    background: var(--white2-color);
    overflow-y: hidden;
    z-index: 1;
}

.upload-moment-preview .upload-moment-video-container {
    min-height: min(56.25vw, calc(100vh - 345px - var(--spacing-14x)));
    position: relative;
}

.upload-moment-view .upload-moment-video-container .VideoPlayer .video {
    left:0 ;
    right: 0;
    margin: auto;
}

.upload-moment-video-container.upload .VideoPlayer video {
    background: var(--white1-color);
}

.upload-moment-preview .upload-moment-video-container,
.upload-moment-preview .upload-moment-video-container .VideoPlayer,
.upload-moment-preview .upload-moment-video-container .VideoPlayer video,
.upload-moment-preview .upload-moment-video-container .VideoPlayer canvas {
    max-height: min(56.25vw, calc(100vh - 56px - 345px));
    height: 100%;
}

.upload-moment-video-container .VideoPlayer .video-container {
    height: 100%;
}

.upload-moment-preview.mobile-hide .upload-moment-video-container {
    display: none;
}


@media only screen and (max-width: 540px) {

    .UploadMoment .FooterSection.UploadMomentFooter .upload-moment-controls-footer-inner {
        bottom: var(--spacing-4x);
    }

    .UploadMoment .FooterSection.UploadMomentFooter .upload-moment-controls-footer-inner .Button.primary {
        margin-right: 0;
    }
}

/* Tablet */

@media only screen and (min-width: 541px) {

    .upload-moment-preview-container {
        overflow: hidden;
        height: 100%;
        max-height: 100vh;
        width: 100%;
    }

    .UploadMoment .Shade {
        display: block;
    }
}

/* Desktop */

@media only screen and (min-width: 1023px) {

    .UploadMoment .upload-moment-preview-container {
        flex-direction: row;
    }

    .upload-moment-preview-container {
        border-radius: var(--spacing-4x);
    }

    .UploadMoment .VideoPlayer .placeholder {
        padding-top: 0%;
    }

    .upload-moment-preview .upload-moment-preview-progress-bar {
        margin-top: calc(-1 * var(--spacing-11x));
        max-width: calc(100% - var(--spacing-10x));
        margin-left: var(--spacing-9x);
        margin-right: var(--spacing-9x);
    }

    .upload-moment-preview .upload-moment-preview-progress-bar .progress-bar-elements {
        border-radius: var(--spacing-2x);
    }

    .upload-moment-preview .upload-moment-video-container,
    .upload-moment-preview .upload-moment-video-container .VideoPlayer,
    .upload-moment-preview .upload-moment-video-container .VideoPlayer video,
    .upload-moment-preview .upload-moment-video-container .VideoPlayer canvas {
        max-height: none;
        height: 100%;
    }


    .upload-moment-preview.mobile-hide .upload-moment-video-container {
        display: block;
    }

    .upload-moment-controls {
        background: var(--white2-color);
        height: 100%;
    }

    .UploadMoment .upload-moment-controls {
        flex-shrink: 1;
        flex-grow: 0;
        width: 100%;
    }

    .upload-moment-preview .upload-moment-video-container{
        min-height: 0px;
        max-height: none;
    }

    .upload-moment-preview .upload-moment-video-container video {
        max-width: calc(100vw - 128px - 375px);
        margin-left: auto;
        margin-right: auto;
    }

    .upload-moment-preview {
        background: var(--black-color);
        flex: 1 0 0;
        justify-content: center;
    }

    .UploadMoment {
        margin: var(--spacing-16x);
    }

    .upload-moment-preview-container {
        border-radius: var(--spacing-6x);
        overflow: hidden;
        height: 100%;
        max-height: 100vh;
        width: 100%;
        z-index: 505;
    }

    .UploadMoment .upload-moment-controls {
        width: 375px;
    }

    .UploadMomentHeader.mobile {
        display: none;
    }
    .UploadMomentHeader.desktop {
        display: flex;
    }
    

}

/* Desktop */

@media only screen and (min-width: 1024px) {

    .upload-moment-preview {
        border-top-left-radius: var(--spacing-6x);
        border-bottom-left-radius: var(--spacing-6x);
    }

    .upload-moment-preview .upload-moment-video-container,
    .upload-moment-preview .upload-moment-video-container .VideoPlayer,
    .upload-moment-preview .upload-moment-video-container .VideoPlayer video,
    .upload-moment-preview .upload-moment-video-container .VideoPlayer canvas {
        border-top-left-radius: var(--spacing-6x);
        border-bottom-left-radius: var(--spacing-6x);
    }
}

/* Larger Screens */

@media only screen and (min-width: 1200px) {

    .UploadMoment {
        margin: 120px;
    }

    .upload-moment-preview .upload-moment-video-container video {
        max-width: calc(100vw - 240px - 375px);
    }
}
