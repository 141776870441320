.player-matches {
    align-content: center;
    min-height: calc(100vh - 330px);
}

.player-matches img {
    display: block;
    margin: 0 auto var(--spacing-9x);
}

.player-matches .row {
    width: 100%;
    background: var(--white2-color);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 1px solid var(--grey4-color);
    padding: var(--spacing-4x) var(--spacing-6x) var(--spacing-4x) var(--spacing-4x);
}


.player-matches .watch-full-match-link {
    font-size: var(--body-font-size);
    line-height: var(--spacing-5x);
    font-weight: var(--bold-font-weight);
    color: var(--trace-regular-color);
    cursor: pointer;
}
.player-matches .loading,
.player-matches .no-matches {
    padding-top: var(--spacing-14x);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 105px);
}

.player-matches .loading p,
.player-matches .no-matches p {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: var(--grey2-color);
    margin-bottom: 1rem;
    z-index: 99999;
}

.player-matches .game-summary-row {
    padding: 0;
}
