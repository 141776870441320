.MulticamOnboardingDialog .banner {
    background: var(--warning-color);
    border-radius: 0;
    min-height: 170px;
    padding: 0;
}

.MulticamOnboardingDialog .ContentSection {
    border-radius: 0;
    padding: 0;
}

.MulticamOnboardingDialog .FooterSection {
    padding-bottom: var(--spacing-8x)
}

@media only screen and (min-width: 541px) and (min-height: 541px) {

    .MulticamOnboardingDialog .ModalWindowInner.partial-screen-fill,
    .MulticamOnboardingDialog .ModalWindowInner {
        max-width: 344px;
        min-width: 344px;
    }

    .MulticamOnboardingDialog .banner {
        border-top-left-radius: var(--spacing-6x);
        border-top-right-radius: var(--spacing-6x);
    }

    .MulticamOnboardingDialog .ContentSection {
        border-top-left-radius: var(--spacing-6x);
        border-top-right-radius: var(--spacing-6x);
        padding: 0;
    }
}