.ErrorSection {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  text-align: center;
  padding-bottom: var(--spacing-5x);
  padding-top: var(--spacing-10x);
  margin: auto;
  max-width: 600px;
}