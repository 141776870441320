.MulticamTooltip {
    background: var(--warning-color);
    border-radius: var(--spacing-2x);
    cursor: pointer;
    max-width: 188px;
    width: 188px;
    padding: var(--spacing-2x) var(--spacing-4x);
    animation: fadeIn linear 500ms;
    -webkit-animation: fadeIn linear 500ms;
    -moz-animation: fadeIn linear 500ms;
    -o-animation: fadeIn linear 500ms;
    -ms-animation: fadeIn linear 500ms;
}

.MulticamTooltip .arrow {
    position: absolute;
    right: var(--spacing-4x);
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid var(--warning-color);
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}