.UsersListDialog .user-item {
    cursor: pointer;
    min-height: var(--spacing-15x);
    width: 100%;
}

.UsersListDialog .user-item.anonymous {
  cursor: unset;
}

.UsersListDialog .user-list-dialog-content-section {
  padding: var(--spacing-8x);
}

@media only screen and (min-width: 1024px) {
    .UsersListDialog .ModalWindowInner.partial-screen-fill {
      width: 500px;
      min-width: unset;
      max-height: min(500px, 75%);
    }

    .UserLists .list {
      overflow: scroll;
    }
}