.Row.MomentFilters {
    background-color: var(--white2-color);
    border-bottom: 2px solid var(--grey4-color);
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;   
}

.Row.MomentFilters::-webkit-scrollbar {
    height: 0;
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.Row.MomentFilters .moment-filter {
    display: flex;
    flex-wrap: nowrap;
}

.Row.MomentFilters .moment-filter p {
    white-space: nowrap;
}

.MomentFiltersModal .moment-filter {
    margin-bottom: var(--spacing-4x);
}

@media only screen and (min-width: 541px) and (min-height: 541px) {
    .Row.MomentFilters {
        justify-content: center;
    }

    .MomentFiltersModal .ModalWindowInner {
        max-width: 541px;
    }
}