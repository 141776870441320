.UpgradeDialog .body {
    max-width: 500px;
    padding: 0;
}

.UpgradeDialog .list-features {
    list-style: inside;
    margin-bottom: var(--spacing-6x);
}

.UpgradeDialog .list-features li {
    margin-left: var(--spacing-2x);
    margin-bottom: var(--spacing);
}

.UpgradeDialog .feature {
    display: inline;
}

.UpgradeDialog .FooterSection {
    padding-bottom: var(--spacing-4x);
}

.UpgradeDialog .button-upgrade > .Row {
    padding: var(--spacing-4x) var(--spacing-4x);
}

.UpgradeDialog .button-upgrade .content {
    flex-wrap: nowrap;
}

.UpgradeDialog .TRCToggle input:checked + .switch-label .switch-rail {
    transition: all 0.2s ease-in-out;
    background: var(--grey3-color);
}