.make-private-label {
    flex: 1 1;
    justify-content: left;
}

.playlist-colors {
    justify-content: space-evenly;
    align-items: center;
}

.make-private-switch {
     align-self: center;
}