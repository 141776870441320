.DashboardPanel .link {
    color: var(--trace-color);
    display: flex;
    font-weight: 600;
    margin-bottom: var(--spacing-2x);
    margin-top: var(--spacing-2x);
}

.DashboardPanel .link:hover {
    color: var(--accent-color-dark);
}

.DashboardPanel i {
    margin-right: var(--spacing-2x);
}