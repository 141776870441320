.TRCInput input[type="submit"],
.TRCButton {
    display: inline-block;

    height: var(--spacing-15x);
    padding: 17px 18px 18px 18px;

    cursor: pointer;
    background-color: var(--trace-regular-color);
    font-weight: bold;
    color: var(--white2-color);
    line-height: 26px;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 40px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    white-space: nowrap;


}

.TRCButton i.trace-icon {
    margin: 0 5px -10px 5px;
}

a.TRCButton:hover {
    color: #fff;
}

.TRCInput input[type="submit"]:focus,
.TRCButton:focus {
    outline: none;
}

.TRCInput input[type="submit"]:active,
.TRCButton:active {
    box-shadow: none;
}

.TRCButton.disabled {
    cursor: default;
    background-color: #c4c4c4;
    color: #fff;
    box-shadow: none;
}

.TRCButton.secondary {
    padding: var(--spacing-4x);

    background-color: transparent;
    color: var(--trace-regular-color);
    border: 2px solid var(--trace-regular-color);
    box-shadow: none;
}

.TRCButton.secondary:hover {
    color: var(--trace-regular-color);
}

.TRCButton.secondary.disabled {
    color: #ccc;
    border-color: #ccc;
}

.TRCButton.ghost {
    padding: 12px;
    background-color: transparent;
    font-weight: normal;
    color: var(--trace-regular-color);
    box-shadow: none;
}

.TRCButton.ghost.secondary {
    padding: 15px 16px 10px 16px;
    font-weight: bold;
    border-color: transparent;
}

a.TRCButton.ghost:hover {
    color: #30A290;
}

.TRCButton.ghost.disabled {
    color: #777;
}

.TRCButton.whoops {
    background-color: #FF435A;
}

.TRCButton.whoops:hover {
    background-color: #ff435a;
    color: #FFF;
}

.TRCButton.whoops.ghost {
    background-color: transparent;
    color: #FF435A;
}

.TRCButton.whoops.ghost:hover {
    color: #ff435A;
}

.TRCButton.whoops.secondary {
    background-color: transparent;
    color: #FF435A;
    border: 2px solid #FF435A;
}

.TRCButton.whoops.secondary:hover {
    color: #FF435A;
}

.TRCButton.full {
    width: 100%;
}
