.VideoSlider {
    background-color: transparent;
    padding-top: var(--spacing-4x);
}

.VideoSlider input[type="range"] {
    -webkit-appearance: none;
    height: var(--spacing-2x);
    border-width: 0;
    background: transparent;
    background-color: var(--white-transparent-color);
    border-radius: var(--spacing-6x) var(--spacing-6x) var(--spacing-6x)
        var(--spacing-6x);
    margin-right: var(--spacing-4x);
    width: 100%;
    cursor: pointer;
}

.VideoSlider input[type="range"]:focus {
    outline: none;
}

.VideoSlider .range-fill {
    display: block;
    border-radius: var(--spacing-6x) 0 0 var(--spacing-6x);
    height: var(--spacing-2x);
    position: relative;
    z-index: 298;
    background: var(--trace-regular-color);
    top: var(--spacing-2x);
    pointer-events: none;
    touch-action: none;
}

.VideoSlider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: var(--spacing-6x);
    width: var(--spacing-6x);
    margin: 0;
    padding: 0;
    background: var(--white2-color);
    border: none;
    border-radius: 20px;
    z-index: 300;
    cursor: pointer;
    position: relative;
    z-index: 300;
}
