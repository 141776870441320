.MyMoment {
    border-radius: var(--spacing-6x);
    border: 2px solid var(--grey4-color);
    overflow: hidden;
    width: 320px;
}

@media only screen and (max-width: 671px) {
    .MyMoment  { max-width: 400px; width: 100% }
}

.MyMoment .tags {
    flex: 1 0 0;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}

.MyMoment .Row .MomentTags {
    max-height: 34px;
    flex-wrap: nowrap;
}

.MyMoment .Row .MomentTags .Chip {
    height: 34px;
    flex-shrink: 0;
}

.MyMoment .tags-mask {
    background: linear-gradient(to right,rgba(255, 255, 255, 0.05) 25%, var(--white2-color));
    height: var(--spacing-8x);
    position: absolute;
    right: 0;
    top: 0;
    width: var(--spacing-16x);
}

.MyMoment .tags p {
    white-space: nowrap;
}

.MyMoment .video-intersection-container {
    height: 178px;
}

@media only screen and (max-width: 671px) {
    .MyMoment .video-intersection-container { height: 203px;
    overflow:hidden; }

    .MyMoment .video-lite {
        height: unset;
    }
}

.MyMoment .momentShareButton {
    padding-left: 1px;
    padding-bottom: 2px;
}

.MyMoment .momentShareButton:hover {
    background-color: var(--grey4-color);
}

.MyMoment .momentShareButton:active {
    background-color: var(--grey4-pressed-color);
}

.MyMoment .video-lite {
    border-top-left-radius: var(--spacing-6x);
    border-top-right-radius: var(--spacing-6x);
}