.NotificationPreviewPlayer .notification-preview-player-container {
    width: 100%;
    height: 100%;
}

.NotificationPreviewPlayer .VideoContainer {
    width: 100vw;
    height: calc(100vw * 9 / 16);
    flex: none;
}

.NotificationPreviewPlayer .CloseButton {
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: var(--spacing);
    margin-right: var(--spacing);
}

.NotificationPreviewPlayer .ShareMenu {
    width: 55px;
}

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .NotificationPreviewPlayer .CloseButton {
        left: 0;
        top: 0;
        right: auto;
        margin-left: var(--spacing);
    }
}

/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {
    .NotificationPreviewPlayer .ModalWindowInner {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
        overflow: hidden;
    }

    .NotificationPreviewPlayer .VideoContainer {
        height: 100%;
        width: calc(100vw - 260px);
    }

    .NotificationPreviewPlayer .CloseButton {
        z-index: 5;
        background-color: rgba(0, 0, 0, 0);
    }
}

/* v2 Tablet and Larger (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .NotificationPreviewPlayer .ModalWindowInner {
        height: 80vh;
        width: 80vw;
        border-radius: var(--spacing-10x);
        overflow: hidden;
    }

    .NotificationPreviewPlayer .VideoContainer {
        height: calc(100% * 9 / 16);
        width: 100%;
    }

    .NotificationPreviewPlayer .CloseButton {
        z-index: 5;
        background-color: rgba(0, 0, 0, 0);
    }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .NotificationPreviewPlayer .ModalWindowInner {
        height: 50vh;
        width: calc(50vh * 16 / 9 + 350px);
        border-radius: var(--spacing-10x);
        overflow: hidden;
        max-width: 90%;
    }

    .NotificationPreviewPlayer .VideoContainer {
        height: 100%;
        flex: 10;
    }

    .NotificationPreviewPlayer .CloseButton {
        z-index: 5;
        background-color: rgba(0, 0, 0, 0);
    }
}