.TRCToggle {
    display: inline-block;
}

.TRCToggle input {
    display: none;
}

.TRCToggle .switch-label {
    cursor: pointer;
    margin: 0;
}

.TRCToggle .switch-rail {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
    width: var(--spacing-14x);
    height: var(--spacing-8x);
    background: var(--grey3-color);
    border-radius: var(--spacing-5x);
    overflow: hidden;
}

.TRCToggle.primary .switch-rail {
    width: var(--spacing-17x);
    height: var(--spacing-8x);
    background: var(--accent-color);
}

.TRCToggle .switch-slider {
    transition: all 0.2s ease-in-out;
    margin-left: var(--spacing);
    margin-right: 0;
    width: var(--spacing-6x);
    height: var(--spacing-6x);
    border-radius: 50%;
    background: var(--white2-color);
}

.TRCToggle.primary .switch-slider {
    width: var(--spacing-6x);
    height: var(--spacing-6x);
    margin-left: 0;
    margin-right: 0;
}

.TRCToggle .letterOn {
    display: none;
}

.TRCToggle.primary .letterOn {
    display: inline;
    padding-left: var(--spacing-3x);
    padding-right: var(--spacing);
    font-size: var(--body-font-size);
    font-weight: 500;
    font-style: normal;
    color: white;
}

.TRCToggle .letterOff {
    display: none;
}

.TRCToggle input:checked + .switch-label .switch-rail {
    transition: all 0.2s ease-in-out;
    background: var(--confirmed-regular-color);
}

.TRCToggle.primary input:checked + .switch-label .switch-rail {
    transition: all 0.2s ease-in-out;
    background: var(--grey3-color);
}

.TRCToggle input:checked + .switch-label .switch-slider {
    transition: all 0.2s ease-in-out;
    margin-left: var(--spacing-7x);
}

.TRCToggle.primary input:checked + .switch-label .switch-rail .letterOn {
    display: none;
    margin-left: var(--spacing);
}

.TRCToggle.primary input:checked + .switch-label .switch-rail .letterOff {
    display: inline;
    padding-right: var(--spacing-3x);
    font-size: var(--body-font-size);
    font-weight: 500;
    font-style: normal;
    color: var(--grey5-color);
}