.SaveToPlaylistDropdown .szh-menu {
    padding: 0;
    min-width: min(404px, 100vw);
}

.SaveToPlaylistDropdown .PlaylistCreator .ContentSection {
    padding: 0;
}

.SaveToPlaylistDropdown .DropdownMenuOptionsMobile {
    overflow: hidden;
}

.SaveToPlaylistDropdown .DropdownMenuOptionsMobile .Column.content {
    background-color: var(--white2-color);
}

.SaveToPlaylistDropdown .DropdownMenuOptionsMobile .Column.content > .ContentSection {
    background-color: var(--white2-color);
    min-height: min(100vh, 375px);
    max-height: min(100vh, 375px);
    height: 100%;
}