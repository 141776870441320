.UploadMoment .upload-moment-controls .SelectSportControl {
    flex-grow: 1;
    overflow-y: scroll;
}

.SelectSportControl .sport-type {
    border-radius: var(--spacing-4x);
    border: 2px solid var(--grey4-color);
    cursor: pointer;
    max-height: 104px;
    padding-bottom: var(--spacing-10x);
    padding-top: var(--spacing-10x);
    padding-right: var(--spacing-4x);
    padding-left: var(--spacing-4x);
    text-transform: capitalize;
}

.SelectSportControl .sport-type:hover {
    background-color: var(--grey4-color);
}

.SelectSportControl .icon-container,
.SelectSportControl .spacer {
    width: var(--spacing-10x);
}