
.MaxLoopOverlay {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(50, 51, 51, 0.75);
}

.MaxLoopOverlay .content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    color: var(--white1-color);
    transform: translate(0, -50%);
}

.MaxLoopOverlay .DropdownMenuOptionsMobile {
    top: calc(100vh * 0.5625 + 32px)
}

.MaxLoopOverlay .DropdownMenuShadeMobile {
    width: 100vw;
    height: 100vh;
}

.MaxLoopOverlay .DropdownMenuContentMobile .DropdownItem .Label{
    color: var(--trace-regular-color);
}

.MaxLoopOverlay .Label {
    color: var(--white1-color);
}

.MaxLoopOverlay .Button.icn {
    border-color: var(--white2-color);
    color: var(--white2-color);

    --button-border-color-hover: var(--white2-color);
    --button-border-color-active: var(--white2-color);
    --button-text-color-hover: var(--white2-color);
    --button-text-color-active: var(--white2-color);
    --button-background-color-hover: var(--white2-color);
    --button-background-color-active: var(--white2-color);
}

.MaxLoopOverlay .Button.icn i {
    border-color: var(--white2-color);
}

.MaxLoopOverlay .Button.icn:hover i {
    border-color: var(--white2-color);
    background-color: var(--white2-color);
}

.MaxLoopOverlay .Button.icn i::before {
    color: var(--white2-color);
}

.MaxLoopOverlay .Button.icn:hover i::before {
    color: var(--trace-regular-color);
}

@media only screen and (min-width: 417px) {
    .MaxLoopOverlay .content {
        top: 50%;
        left: 50%;
        max-width: 600px;
        transform: translate(-50%, -50%);
    }
}