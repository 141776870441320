.personalize-controls-footer {
    align-content: flex-end;
    bottom: 0;
    flex: 1;
    padding: var(--spacing-4x);
    width: 100%;
}

.personalize-controls-footer .Button {
    width: 100%;
}