.Tab {
  cursor: pointer;
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: var(--spacing-2x);
  padding-right: var(--spacing-2x);
}

.Tab .tab-row {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex-wrap: nowrap;
}

.Tab .tab-label {
  color: var(--grey3-color);
  padding-left: var(--spacing-x);
  width: auto;
}

.Tab i {
  color: var(--grey3-color);
  margin-right: var(--spacing-2x);
}

.Tab i::before {
  margin-left: 0px;
  margin-right: 0px;
}

.Tab *,
.Tab.selected .tab-label {
  transition: opacity 150ms linear, max-width 150ms linear;
}

.Tab.selected {
  border-bottom: 2px solid var(--trace-color);
}

.Tab.selected * {
  color: var(--black-color);
}

.Tab.selected .tab-label {
  max-width: 100px;
  opacity: 1;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {
  .Tab .tab-label {
    opacity: 1;
    max-width: none;
    padding-left: 0;
  }
}


