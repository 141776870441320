.EmptyState {
    margin-top: var(--spacing-4x);
    margin-left: var(--spacing-4x);
    margin-right: var(--spacing-4x);
    align-items: center;
}

@media only screen and (min-width: 1024px) {
    .EmptyState {
        margin: var(--spacing-8x);
        height: unset;
        flex-direction: row;
        justify-content: flex-start;
    }
}