.SaveToPlaylistModal {
    align-items: center;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
}

.SaveToPlaylistModal .ContentSection {
    padding: var(--spacing-4x);
}

.SaveToPlaylistModal.dropdown .szh-menu,
.SaveToPlaylistDropdown .szh-menu {
    padding: 0;
    min-width: min(375px, 100vw);
}

.SaveToPlaylistModal.dropdown .PlaylistCreator .ContentSection,
.SaveToPlaylistModal .PlaylistCreator .ContentSection {
    padding: 0;
}

.SaveToPlaylistModal.dropdown .ModalWindowInner {
    background-color: var(--black-color);
    max-width: 100vw;
    overflow: hidden;
    top: unset;
}

.SaveToPlaylistModal.dropdown .ModalWindowInner .TitleSection,
.SaveToPlaylistModal .ModalWindowInner .TitleSection {
    border-top-left-radius: var(--spacing-6x);
    border-top-right-radius: var(--spacing-6x);
    background-color: var(--white2-color);
}

.SaveToPlaylistModal .ModalWindowInner {
    border-top-left-radius: var(--spacing-6x);
    border-top-right-radius: var(--spacing-6x);
    overflow: hidden;
}

.SaveToPlaylistModal.dropdown .ModalWindowInner,
.SaveToPlaylistModal .ModalWindowInner {
    background-color: var(--white2-color);
}

.SaveToPlaylistModal.dropdown .Shade,
.SaveToPlaylistModal .Shade {
    display: none;
}

.SaveToPlaylistModal.dropdown .ModalWindowInner > .ContentSection,
.SaveToPlaylistModal .ModalWindowInner > .ContentSection {
    background-color: var(--white2-color);
    height: 100%;
}

@media screen and (min-width: 540px) {

    .SaveToPlaylistModal.dropdown .Shade,
    .SaveToPlaylistModal .Shade {
        display: block;
    }

    .SaveToPlaylistModal .ContentSection {
        padding: var(--spacing-8x);
    }

    .SaveToPlaylistModal.dropdown .ContentSection {
        padding: var(--spacing-4x);
    }

    .SaveToPlaylistModal.dropdown .UserPlaylists .side-left {
        max-width: 208px;
    }

    .SaveToPlaylistModal.dropdown .Shade {
        background: none;
        -webkit-backdrop-filter: unset;
        backdrop-filter: unset;
    }
    
    .SaveToPlaylistModal.dropdown .ModalWindowInner {
        background-color: transparent;
        left: unset;
        right: -138px;
        top: unset;
        bottom: -130px;
        max-width: min(375px, 100vw);
        width: min(375px, 100vw);
        min-width: unset;
        border-bottom-left-radius: var(--spacing-4x);
        border-bottom-right-radius: var(--spacing-4x);
    }

    .SaveToPlaylistModal.dropdown .ModalWindowInner .TitleSection,
    .SaveToPlaylistModal .ModalWindowInner .TitleSection {
        border-top-left-radius: var(--spacing-4x);
        border-top-right-radius: var(--spacing-4x);
        background-color: var(--white2-color);
    }

    .SaveToPlaylistModal.dropdown .ModalWindowInner > .ContentSection,
    .SaveToPlaylistModal .ModalWindowInner > .ContentSection {
        min-height: unset;
        max-height: unset;
    }

    .SaveToPlaylistModal .ModalWindowInner {
        border-bottom-left-radius: var(--spacing-4x);
        border-bottom-right-radius: var(--spacing-4x);
    }
}

