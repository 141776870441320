.HamburgerMenu .HamburgerMenuInner{
    background: var(--white2-color);
    height: 100vh;
    overflow: scroll;
    padding: var(--spacing-6x);
}

.TopBar .RoundButton.hamburger-menu-icon:hover {
    background: var(--grey4-color);
}

.TopBar .RoundButton.hamburger-menu-icon i[class^="icon"]:before {
    margin: 0;
}
