.TraceTeamInfoCard {
    border: 2px solid var(--grey4-color);
    border-radius: var(--spacing-4x);
    justify-content: space-between;
    padding: var(--spacing-4x);
    background: var(--white1-color);
}

.TraceTeamInfoCard .title,
.TraceTeamInfoCard .subtitle {
    width: 100%;
}

.TraceTeamInfoCard .left-column {
    max-width: 47%;
    text-align: left;
}

.TraceTeamInfoCard.as-row {
    justify-content: space-between;
    border-radius: 0px;
    border: 0px;
    padding: var(--spacing-4x) var(--spacing-6x);
    box-shadow: inset 0 -1px 0 var(--grey4-color);
}

.TraceTeamInfoCard.as-row .subtitle {
    max-width: unset;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {

    .TraceTeamInfoCard .left-column {
        max-width: 75%;
        text-align: left;
    }
}