.BottomBanner {
    bottom: 0;
    cursor: pointer;
    position: fixed;
    left: 0;
    padding: var(--spacing-2x);
    width: 100%;
    z-index: 2000;
}

@media only screen and (max-width: 541px) {
    .BottomBanner .bottom-banner-message {
        width: 50%;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .BottomBanner {
        position: relative;
        margin-top: -56px;
    }
}