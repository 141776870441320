.RenewPlanCard {
    cursor: pointer;
    padding: var(--spacing-6x);
    border-radius: var(--spacing-4x);
    border: 2px solid var(--grey4-color);
    max-height: 104px;
    overflow: hidden;
    min-width: 100%;
    position: relative;
    margin-bottom: var(--spacing-4x);
    transition: max-height 250ms linear;
}

.RenewPlanCard.recommended {
    max-height: 140px;
}

.RenewPlanCard.selected {
    border: 2px solid var(--trace-color);
}

.RenewPlanCard.expanded,
.RenewPlanCard.expanded.recommended {
    max-height: 480px;
    transition: max-height 250ms linear;
}

.RenewPlanCard.recommended {
    padding-top: var(--spacing-15x);
}

.RenewPlanCard.expanded.recommended {
    max-height: 514px;
}

.RenewPlanCard .recommended {
    align-items: center;
    background-color: var(--trace-tint2-color);
    color: var(--trace-shade-color);
    display: flex;
    font-size: var(--small-font-size);
    letter-spacing: 1px;
    height: var(--spacing-8x);
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.RenewPlanCard .subtitle-bottom {
    color: var(--sky-regular-color);
    text-transform: uppercase;
    min-height: var(--spacing-5x);
}

.RenewPlanCard .bottom {
    margin-top: var(--spacing-2x);
}

.RenewPlanCard.recommended .bottom {
    margin-top: 0;
}

.RenewPlanCard .list-bullets {
    list-style: outside;
    margin-bottom: var(--spacing-6x);
}

.RenewPlanCard .list-bullets li {
    margin-left: var(--spacing-4x);
    margin-bottom: var(--spacing);
}

.RenewPlanCard .bullet {
    display: inline;
}

.RenewPlanCard img {
    height: var(--spacing-6x);
}

.RenewPlanCard .top-container {
    margin-bottom: var(--spacing-2x);
}

.RenewPlanCard.expanded .top-container {
    margin-bottom: var(--spacing-4x);
}

.RenewPlanCard .bottom .button-purchase {
    width: 100%;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {
    .RenewPlanCard {
        min-width: 344px;
    }

    .RenewPlanCard .bottom .button-purchase {
        max-width: 292px;
    }

    .RenewPlanCard:not(.recommended) {
        margin-top: var(--spacing-8x);
    }
}