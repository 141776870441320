.VideoContainer {
    position: relative;
}

.VideoContainer .VideoPlayer {
    height: 100%;
}

.VideoContainer .VideoControlsBackground {
    display: none;
}

/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {
    .VideoContainer {
        overflow: hidden;
    }
    .VideoContainer .VideoControlsBackground {
        bottom: -100vh;
        display: block;
    }
    
    .VideoContainer .active .VideoControlsBackground {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.75) 60%);
    }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .VideoContainer {
        overflow: hidden;
    }
}
