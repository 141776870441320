.UpgradeToTraceTeamDialog .body {
    max-width: 500px;
    padding: 0;
}

.UpgradeToTraceTeamDialog .title {
    margin-left: var(--spacing-8x);
    text-align: center;
    width: 100%;
}

.UpgradeToTraceTeamDialog .subtitle {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    text-align: center;
}

.UpgradeToTraceTeamDialog .list-features {
    list-style: outside;
}

.UpgradeToTraceTeamDialog .list-features li {
    margin-left: var(--spacing-2x);
    margin-bottom: var(--spacing);
}

.UpgradeToTraceTeamDialog .feature {
    display: inline;
}

.UpgradeToTraceTeamDialog .FooterSection {
    padding-bottom: var(--spacing-4x);
}

.UpgradeToTraceTeamDialog .button-upgrade > .Row {
    padding: var(--spacing-4x) var(--spacing-4x);
}

.UpgradeToTraceTeamDialog .button-upgrade .content {
    flex-wrap: nowrap;
}

.UpgradeToTraceTeamDialog .Button {
    width: 100%;
}


.UpgradeToTraceTeamDialog .FooterSection {
    min-height: 106px;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {
    .UpgradeCompletionDialog .Button {
        max-width: 184px;
    }
    
}