.personalize-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: none;
    margin: 0px;
}

.personalize-container .Shade {
    background: var(--pure-black-color);
}

.personalize-container.Row {
    flex-wrap: wrap;
    height: 100%;
}

.personalize-container.open {
    display: block;
}

.personalize-controls-header {
    align-items: center;
    position: relative;
}

.personalize-preview {
    position: relative;
}

.personalize-preview .moment-preview-progress-bar {
    margin-top: -5px;
    z-index: 505;
}

.personalize-preview .moment-preview-progress-bar .progress-bar-elements {
    border-radius: 0px;
}

.personalize-preview-container {
    align-self: center;
    background-color: var(--white2-color);
    flex-direction: column-reverse;
    max-height: 100vh;
    height: 100vh;
    position: absolute;
    width: 100vw;
}

.personalize-container .personalize-controls {
    flex-shrink: 1;
    width: 100%;
}

.personalize-container .personalize-controls .controls-content {
    max-height: calc(100% - var(--spacing-14x) - var(--spacing-14x) - var(--spacing-8x));
    overflow-y: auto;
}

.personalize-preview {
    background: var(--black-color);
}

.personalize-preview {
    overflow-y: hidden;
}

.personalize-preview .moment-editor-video-container {
    min-height: min(56.25vw, calc(100vh - 345px - var(--spacing-14x)));
}

.personalize-preview .moment-editor-video-container .VideoPlayer video {
    left: 0;
    right: 0;
    margin: auto
}

.personalize-preview .moment-editor-video-container,
.personalize-preview .moment-editor-video-container .VideoPlayer,
.personalize-preview .moment-editor-video-container .VideoPlayer video,
.personalize-preview .moment-editor-video-container .VideoPlayer canvas {
    max-height: min(56.25vw, calc(100vh - 56px - 345px));
    height: 100%;
}

.moment-editor-video-container .VideoPlayer .video-container {
    height: 100%;
}

.personalize-preview.mobile-hide .moment-editor-video-container {
    display: none;
}

.personalize-container .StepCancel .FooterSection {
    width: 100%;
}

@media only screen and (max-width: 540px) {

    .personalize-container .FooterSection.personalize-controls-footer {
        position: fixed;
        padding: var(--spacing-4x);
    }

    .personalize-container .FooterSection.personalize-controls-footer .Button.primary {
        margin-right: 0;
    }
}

/* Tablet */

@media only screen and (min-width: 541px) {

}

/* Desktop */

@media only screen and (min-width: 1023px) {

    .personalize-container .VideoPlayer .placeholder {
        padding-top: 0%;
    }

    .personalize-preview .moment-preview-progress-bar {
        margin-top: calc(-1 * var(--spacing-5x));
        max-width: calc(100% - var(--spacing-10x));
        margin-left: var(--spacing-5x);
        margin-right: var(--spacing-5x);
    }

    .personalize-preview .moment-preview-progress-bar .progress-bar-elements {
        border-radius: var(--spacing-2x);
    }

    .personalize-preview .moment-editor-video-container,
    .personalize-preview .moment-editor-video-container .VideoPlayer,
    .personalize-preview .moment-editor-video-container .VideoPlayer video,
    .personalize-preview .moment-editor-video-container .VideoPlayer canvas {
        max-height: none;
        height: 100%;
    }


    .personalize-preview.mobile-hide .moment-editor-video-container {
        display: block;
    }

    .personalize-controls {
        height: 100%;
    }

    .personalize-container .personalize-controls {
        flex-shrink: 1;
        flex-grow: 0;
        width: 100%;
    }

    .personalize-preview .moment-editor-video-container{
        min-height: 0px;
        max-height: none;
    }

    .personalize-preview .moment-editor-video-container video {
        max-width: calc(100vw - 128px - 375px);
        margin-left: auto;
        margin-right: auto;
    }

    .personalize-preview {
        background: var(--black-color);
        flex: 1 0 0;
        justify-content: center;
    }

    .personalize-container {
        margin: var(--spacing-16x);
    }

    .personalize-preview-container {
        border-radius: var(--spacing-6x);
        overflow: hidden;
        height: 100%;
        max-height: 100vh;
        width: 100%;
    }

    .personalize-container .personalize-controls {
        width: 375px;
    }

    .personalize-controls-header.mobile {
        display: none;
    }
    .personalize-controls-header.desktop {
        display: flex;
    }
    

}

/* Desktop */

@media only screen and (min-width: 1024px) {

    .personalize-preview {
        border-top-left-radius: var(--spacing-6x);
        border-bottom-left-radius: var(--spacing-6x);
    }

    .personalize-preview .moment-editor-video-container,
    .personalize-preview .moment-editor-video-container .VideoPlayer,
    .personalize-preview .moment-editor-video-container .VideoPlayer video,
    .personalize-preview .moment-editor-video-container .VideoPlayer canvas {
        border-top-left-radius: var(--spacing-6x);
        border-bottom-left-radius: var(--spacing-6x);
    }
}

/* Larger Screens */

@media only screen and (min-width: 1200px) {

    .personalize-container {
        margin: 120px;
    }

    .personalize-preview .moment-editor-video-container video {
        max-width: calc(100vw - 240px - 375px);
    }
}
