.moment-preview-container {
    background-color: var(--white2-color);
    width: 100vw;
    align-self: center;
    height: 100vh;
    flex-direction: column-reverse;
    position: absolute;
}

.moment-preview-container .video-intersection-container {
    height: 100%;
}

.moment-preview-container.Row {
    flex-wrap: nowrap;
}

.preview-name-url {
    color: var(--black-color);
    display: inline-flex;
}

.preview-name-url:hover,
.preview-name-url:active {
    text-decoration: none;
    color: black;
}

.share-preview {
    position: fixed;
    bottom: var(--spacing-4x);
    padding: var(--spacing-4x);
    width: 100%;
}

/* .share-button-dropdown {
    width: calc(100% - var(--spacing-8x));
    bottom: var(--spacing-4x);
} */

.share-preview .DropdownMenuContent .Button:hover {
    color: var(--button-text-color-hover);
    border-color: var(--button-border-color-hover);
    background-color: var(--button-background-color-hover);
    box-shadow: var(--button-shadow-hover);
}

.moment-preview-content {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
}

.moment-preview-content .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    background: var(--grey4-color);
}

.moment-preview-container .user-data-column {
    /* calc = container width * 2/3 * 0.5625 */
    height: calc(50vw * 0.5625);
    overflow: auto;
    display: flex;
    flex: 10 1;
}

.moment-preview-container .user-data-wrapper {
    display: block;
    overflow: auto;
    /* height = card height - share button are height */
    max-height: calc(100% - 88px);
    width: 100%;
}

.user-data-wrapper .big-moment-text {
    display: flex;
    justify-content: flex-start;
}

.moment-preview-content .preview .video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.moment-preview-content .ProgressBar.moment-preview-progress-bar .progress-bar-elements {
    width: calc(100% - var(--spacing-2x));
}

.moment-preview-content .toast {
    position: absolute;
}

.go-to-game-dropdown-menu {
    position: absolute;
    bottom: 0;
    left: 0;
}

.Chip.full-game {
    z-index: 1;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    background: rgba(50, 51, 51, 0.5);
}

.Chip.full-game .Label{
    font-weight: var(--bold-font-weight);
    font-size: var(--chip-font-size);
    line-height: var(--spacing-5x);
    font-family: var(--heading-font-family);
}

.Chip.full-game:hover {
    background: rgba(50, 51, 51, 0.7);
}

.Chip.close-icon {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background: rgba(50, 51, 51, 0.5);
}

.play-button {
    position: absolute;
    /* We subtract 23 because width of the icon is 56px */
    top: calc(50% - 23px);
    /* We subtract 31 because width of the icon is 56px and padding is 8px on left and right */
    left: calc(50% - 31px);
    z-index: 100;
    background: rgba(50, 51, 51, 0.5);
}

.play-button:hover {
    background: rgba(50, 51, 51, 0.7);
}

.logo {
    position: absolute;
    bottom: var(--spacing-4x);
    right: var(--spacing-4x);
    width: 15%;
}

.preview-share-button {
    width: 100%;
    height: var(--spacing-14x);
}

.max-loop-overlay-dropdown.DropdownMenuTarget {
    background: none;
    border: none;
    padding: 0;
}

.preview-card-max-loop-overlay .DropdownMenuContent .Label {
    color: var(--trace-regular-color);
}

.preview-card.open {
    display: block;
}

@media only screen and (min-width: 320px) {
    .user-data-column {
        flex: 10 1;
    }

    .moment-preview-content-wrapper {
        flex: 2 1;
    }
}

@media only screen and (min-width: 760px) {
    .preview-card.open {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .user-data-column {
        flex: 4 1;
    }

    .moment-preview-content-wrapper {
        flex: 8 1;
    }

    .moment-preview-container {
        border-radius: var(--spacing-6x) var(--spacing-7x) var(--spacing-7x) var(--spacing-6x);
        align-self: center;
        height: auto;
        flex-direction: row;
        width: 85vw;
    }

    .moment-preview-container .user-data-column {
        /* height = container width * 2/3 * 0.5625 */
        height: calc(85vw * 2 / 3 * 0.5625);
        flex: 4 1;
    }

    .share-preview {
        position: absolute;
        align-content: flex-end;
        width: calc(100% / 3);
        bottom: 0;
        padding: var(--spacing-4x);
    }

    .moment-preview-content .preview {
        border-radius: 0 var(--spacing-6x) var(--spacing-6x) 0;
    }

    .moment-preview-content .video-cover {
        border-radius: 0 var(--spacing-6x) var(--spacing-6x) 0;
        /* We are multiplying by 56.25% and to avoid to have rounded numbers (and white thin line) we are adding 4px */
        width: calc(100% + 4px);
        height: calc(100% + 2px);
        overflow: hidden;
        position: sticky;
    }

    .moment-preview-content .ProgressBar.moment-preview-progress-bar {
        bottom: 2px;
    }

    .shadow-close-icon {
        position: absolute;
        right: 0;
        margin: var(--spacing-8x);
        cursor: pointer;
    }

    .Chip.full-game,
    .Chip.close-icon {
        display: none;
    }

    .moment-preview-content .full-game.show-chip,
    .moment-preview-content:hover .full-game {
        display: block;
    }

    .preview-card-max-loop-overlay {
        border-radius: 0 var(--spacing-6x) var(--spacing-6x) 0;
    }
}

@media only screen and (min-width: 760px) {
    .moment-preview-container {
        border-radius: var(--spacing-6x) var(--spacing-7x) var(--spacing-7x) var(--spacing-6x);
        align-self: center;
        height: auto;
        flex-direction: row;
    }

    .logo {
        width: 10%;
    }

    .moment-preview-container.Row {
        flex-wrap: wrap;
    }

    .moment-preview-container .icon-close {
        display: none;
    }
}

@media only screen and (min-width: 1024px) {
    .moment-preview-container .user-data-wrapper {
        padding: var(--spacing-8x);
    }

    .moment-preview-container .moment-players {
        font-size: var(--heading4-font-size);
        line-height: var(--spacing-6x);
    }

    .share-preview {
        padding: var(--spacing-8x);
    }
}
