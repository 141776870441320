.TagsToggler div.tags {
    padding: 0 var(--spacing-4x) 0 var(--spacing-4x);
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    margin-top: var(--spacing-4x);
    overflow-y: auto;
}

.TagsToggler p.button-group {
    padding: var(--spacing-5x) var(--spacing-4x) 0 var(--spacing-4x);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: nowrap;
}

.TagsToggler .button-group .Button:nth-child(1) {
    border-color: var(--trace-regular-color);
    border-radius: var(--spacing-8x) 0 0 var(--spacing-8x);
    border-right: 0px;
}

.TagsToggler .button-group .Button:nth-child(2) {
    border-color: var(--trace-regular-color);
    border-radius: 0;
}

.TagsToggler .button-group .Button:last-child {
    border-color: var(--trace-regular-color);
    border-radius: 0 var(--spacing-6x) var(--spacing-6x) 0;
    border-left: 0px;
}

.TagsToggler input[type="radio"]:checked+label {
    background: var(--trace-regular-color);
    border-color: var(--trace-regular-color);
    color: var(--white2-color);
    content: "";
}

.TagsToggler .tags input[type="checkbox"],
.Feedback-Reason input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
    outline: none;
}

.TagsToggler .tags input[type="checkbox"]+label,
.Feedback-Reason input[type="checkbox"]+label {
    display: inline-block;
    margin-bottom: var(--spacing-3x);
    margin-right: var(--spacing-3x);
    padding: 0 var(--spacing-4x);
    line-height: var(--spacing-9x);
    color: var(--grey1-color);
    border: 1px solid var(--grey5-color);
    border-radius: var(--spacing-5x);
    cursor: pointer;
    font-weight: var(--body-font-weight);
    background: var(--grey5-color);
    outline: none;
}

.TagsToggler .tags input[type="checkbox"]:checked+label {
    background: var(--trace-regular-color);
    border-color: var(--trace-regular-color);
    color: var(--white2-color);
    content: "";
    outline: none;
}
