.BottomToaster {
    align-items: center;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 30%);;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - var(--spacing-4x));
    background-color: var(--white2-color);
    border-radius: var(--spacing-2x);
    position: fixed;
    z-index: 20000;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    text-align: center;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    padding: var(--spacing-3x) var(--spacing-4x) var(--spacing-3x) var(--spacing-4x);
    overflow: hidden;
    min-height: 108px;
    height: auto;
    z-index: 100000;
    width: auto;
    left: 0;
    right: 0;
    transform: unset;
}

.BottomToaster.no-link {
    min-height: 60px;
}

.BottomToaster.no-link .toast-message {
    margin-bottom: 0px;
}

.BottomToaster.no-link .mobile-close-button {
    height: unset;
}

.BottomToaster a {
    text-decoration: underline;
}

.BottomToaster .top-row {
    min-height: 36px;
    align-items: center;
    width: 100%;
}

.BottomToaster.in {
    bottom: 10px;
}

.BottomToaster.out {
    bottom: -140px;
}

.BottomToaster.in {
    opacity: 1;
}

.BottomToaster.out {
    opacity: 0;
}

.BottomToaster .toast-message {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: var(--body-font-size);
    text-align: left;
    margin-bottom: var(--spacing-2x);
    max-width: 90%;
}

.BottomToaster .toast-button {
    flex-wrap: nowrap;
    font-size: var(--body-font-size);
    font-weight: var(--bold-font-weight);
    max-width: none;
    min-width: 100%;
    padding: var(--spacing-2x);
    max-height: var(--spacing-9x);
    margin-right: 0;
    width: 100%;
}

.BottomToaster .toast-button div {
    padding-left: 0;
    padding-right: 0;
}

.BottomToaster .mobile-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.BottomToaster .mobile-close-button:hover {
    background: var(--grey4-color);
}

.BottomToaster .mobile-close-button:active {
    background: var(--grey4-pressed-color);
}

.BottomToaster .desktop-close-button {
    display: none;
    padding: var(--spacing-2x);
}

.BottomToaster .desktop-close-button:hover {
    background: var(--grey4-color);
}

.BottomToaster .desktop-close-button:active {
    background: var(--grey4-pressed-color);
}

.BottomToaster .top-row {
    width: auto;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
}

@media only screen and (min-width: 800px) {

    .BottomToaster {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        max-width: min(calc(100vw - var(--spacing-4x)), 1000px);
        min-height: var(--spacing-17x);
        padding: var(--spacing-2x) var(--spacing-2x) var(--spacing-2x) var(--spacing-4x);
        left: 50%;
        right: unset;
        transform: translateX(-50%);
        width: auto;
    }

    .BottomToaster .top-row {
        max-width: calc(100% - 180px);
    }

    .BottomToaster.no-link .top-row {
        max-width: calc(100% - 40px);
    }

    .BottomToaster .toast-button {
        max-width: 160px;
        min-width: 0px;
        margin-right: var(--spacing-2x);
        margin-left: var(--spacing-2x);
        
    }

    .BottomToaster .toast-message {
        margin-bottom: 0;
        max-width: unset;
    }

    .BottomToaster.in {
        bottom: 10px;
    }

    .BottomToaster.out {
        bottom: -140px;
    }

    .BottomToaster .top-row {
        width: auto;
    }

    .BottomToaster .mobile-close-button {
        display: none;;
    }

    .BottomToaster .desktop-close-button {
        display: block;
        padding: var(--spacing-2x);
    }
}