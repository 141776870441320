.dropzone-container {
    cursor: pointer;
    padding: var(--spacing-8x) var(--spacing-8x) var(--spacing-4x);
    z-index: 10;
}

.dropzone-container-inner {
    cursor: pointer;
    padding: var(--spacing-8x) var(--spacing-8x) var(--spacing-8x);
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    min-width: 340px;
    position: absolute;
}

.dropzone-container-inner-content {
    border: 2px dashed var(--trace-color);
    border-radius: var(--spacing-4x);
    padding: var(--spacing-8x) var(--spacing-8x) var(--spacing-4x);
}

@media only screen and (min-width: 541px) {

    .dropzone-container  {
        width: auto;
    }
}

@media only screen and (min-width: 1024px) {

    .dropzone-container  {
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        min-width: 340px;
        position: absolute;
        width: auto;
    }

    .upload-moment-step-content .dropzone-container {
        display: none;
    }
}
