.ConvertChurnedModal .ModalWindowInner {
    background-color: var(--white1-color);
    border-top-left-radius: var(--spacing-4x);
    border-top-right-radius: var(--spacing-4x);
    max-width: unset;
    top: 158px;
}

.ConvertChurnedModal .ContentSection {
    padding-top: var(--spacing-6x);
    padding-bottom: var(--spacing-6x);
}

.ConvertChurnedModal .TitleSection {
    background-color: var(--white2-color);
    border-top-left-radius: var(--spacing-4x);
    border-top-right-radius: var(--spacing-4x);
}

.ConvertChurnedModal .plan-rows {
    background-color: var(--white1-color);
    flex-wrap: nowrap;
}

.ConvertChurnedModal .plan-rows {
    flex-wrap: wrap;
}

.ConvertChurnedModal .heading {
    display: none;
}

.ConvertChurnedModal .prompt-container {
    position: absolute;
    top: -86px;
    left: 0;
    right: 0;
    width: 100%;
}

.ConvertChurnedModal .prompt-container .prompt-team-name {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ConvertChurnedModal .prompt-container .prompt {
    top: -86px; 
    left: 0;
    right: 0;
}

.ConvertChurnedModal .inner-prompt-container {
    display: none;
    margin-top: var(--spacing-6x);
    margin-bottom: var(--spacing-12x);
}

@media only screen and (min-width: 541px) and (min-height: 541px) {

    .ConvertChurnedModal .ModalWindowInner {
        top: 50%;
    }

    .ConvertChurnedModal .prompt-container {
        display: none;
    }

    .ConvertChurnedModal .inner-prompt-container {
        display: flex;
    }

    .ConvertChurnedModal .plan-rows {
        flex-wrap: nowrap;
    }
    
    .ConvertChurnedModal .RenewPlanCard {
        background-color: var(--white2-color);
        margin-left: var(--spacing-3x);
        margin-right: var(--spacing-3x);
    }

    .ConvertChurnedModal .heading {
        font-weight: var(--bold-font-weight);
        font-size: var(--heading3-font-size);
        margin-bottom: var(--spacing-11x);
        margin-top: var(--spacing-9x);
    }

    .ConvertChurnedModal.minimal-styling .ModalWindowInner {
        background: transparent;
    }

    .ConvertChurnedModal.minimal-styling .TitleSection {
        display: none;
    }

    .ConvertChurnedModal.minimal-styling .plan-rows {
        background: transparent;
    }
}