.DashboardPanel {
    background: var(--white2-color);
}

.DashboardPanel.family {
    max-height: 50%;
    overflow-y: auto;
}

.avatardropdown-inner-content .divider,
.navdropdown-inner-content .divider  {
    border-bottom: 2px solid var(--grey4-color);
    margin-left: calc(-1 * var(--spacing-6x));
    width: calc(100% + var(--spacing-12x));
}

.DashboardPanel.containerized {
    border-radius: var(--spacing-4x);
    border: 2px solid var(--grey4-color);
}

.DashboardPanel .container-title {
    padding: var(--spacing-4x) 0 0;
    padding-bottom: 0px;
    width: 100%;
}

.DashboardPanel.containerized .container-title {
    padding: var(--spacing-4x);
    padding-bottom: 0px;
    width: 100%;
}

.DashboardPanel .container-title.containerized {
    border-bottom: 2px solid var(--grey4-color);  
    padding-bottom: var(--spacing-4x);
}

.DashboardPanel .container-title .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - var(--spacing-4x) - var(--spacing-15x));
    width: 100%;
}

.DashboardPanel.containerized .container-content {
    padding: var(--spacing-4x);
    width: 100%;
}

.DashboardPanel .container-content {
    height: 100%;
    padding: var(--spacing-4x) 0 0;
    overflow: unset;
    width: 100%;
}

.DashboardPanel:last-child .container-content a:last-child .Row,
.DashboardPanel:last-child .container-content div.link:last-child {
    margin-bottom: 0px;
}


.DashboardPanel .container-content a:last-child .Row {
    margin-bottom: 0px;
}

.DashboardPanel:not(.containerized) .container-content a:last-child,
.DashboardPanel:not(.containerized) .container-content .link:last-child {
    margin-bottom: var(--spacing-4x);
}


@media only screen and (min-width: 541px) {

    .avatardropdown-inner-content .divider,
    .navdropdown-inner-content .divider  {
        margin-left: calc(-1 * var(--spacing-6x));
        width: calc(100% + var(--spacing-12x));
    }
    
    
}