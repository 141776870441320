.StepDescription.upload-moment-preview-container textarea {
    padding: var(--spacing-3x) var(--spacing-4x);
    border: 2px solid var(--grey3-color);
    box-sizing: border-box;
    outline: none;
    overflow: auto;
    font-family: var(--body-font-family);
    font-weight: var(--body-font-weight);
    font-size: var(--body-font-size);
    line-height: var(--spacing-6x);
    margin: var(--spacing-4x);
    min-height: 186px;
    border-radius: var(--spacing-2x);
    resize: none;
    width: calc(100% - var(--spacing-8x));
}

.StepDescription.upload-moment-preview-container textarea::placeholder {
    color: var(--grey3-color);
}

.StepDescription.upload-moment-preview-container .tags {
    padding: 0 var(--spacing-4x) 0 var(--spacing-4x);
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    overflow-y: auto;
}

.StepDescription.upload-moment-preview-container .selected-tag {
    cursor: default;
}


.StepDescription.upload-moment-preview-container .tags-label {
    font-size: var(--chip-font-size);
}