.ShadowLoginForm {
    position: fixed;
    bottom: var(--spacing-8x);
    width: 340px;
}

.ShadowLoginForm .loading-container {
    height: 200px;
    width: 100%;
}


.ShadowLoginForm .Input.code {
    width: 210px;
    max-width: 210px;
}

.ShadowLoginForm .Input.code input {
    padding-left: var(--spacing-5x);
    padding-right: var(--spacing-5x);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--heading4-font-size);
    line-height: var(--spacing-6x);
}

.ShadowLoginForm .sign-in-label {
    display: inline-block;
}

.ShadowLoginForm .user-avatar {
    display: inline-block;
}

/* v2 Tablet and Larger (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .ShadowLoginForm {
        position: relative;
        bottom: var(--spacing-4x);
    }
}