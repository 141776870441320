.NotificationsAnonymous {
    margin-top: var(--spacing-4x);
    margin-left: var(--spacing-4x);
    margin-right: var(--spacing-4x);
    justify-content: center;
    align-items: center;
}

.NotificationsAnonymous .notification-sign-in-button-wrapper {
    width: 100%;
}

.NotificationsAnonymous .notification-sign-in-button {
    width: calc(100% - 32px);
    height: var(--spacing-14x);
    position: absolute;
    bottom: var(--spacing-4x);
}

.NotificationsAnonymous .notification-sign-in-button .button-title {
    font-size: var(--body-font-size);
    line-height: var(--spacing-6x);
}

@media only screen and (min-width: 1024px) {
    .NotificationsAnonymous {
        margin: var(--spacing-8x) var(--spacing-8x) var(--spacing-4x);
        height: unset;
        flex-direction: row;
        justify-content: flex-start;
    }

    .NotificationsAnonymous .notification-sign-in-button {
        width: unset;
        position: unset;
        margin: unset;
    }
}