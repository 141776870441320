.RadioContainer {
  box-sizing: border-box;
}

.Radio {
  color: var(--trace-regular-color);
  cursor: pointer;
  font-size: 1.5rem;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.4em;
  margin-bottom: 0.5em;
}

.Radio .radio__input {
  display: flex;
}

.Radio .radio__label {
  color: var(--black-color);
  font-size: var(--body-font-size);
  line-height: 1.5rem;
}

.Radio .radio__input input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Radio .radio__control {
  border-radius: 50%;
  border: 0.1em solid var(--trace-regular-color);
  display: grid;
  place-items: center;
  height: 1em;
  width: 1em;
}

.Radio input + .radio__control::before {
  content: "";
  width: 0.5em;
  height: 0.5em;
  box-shadow: inset 0.5em 0.5em currentColor;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0);
}

.Radio input:checked + .radio__control::before {
  transform: scale(1);
  transition: transform 200ms ease-in-out;
}
