.moments-grid-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.moments-grid-content .item-container {
    flex-basis: 30%;
    padding-bottom: var(--spacing-4x);
    padding-left: var(--spacing-4x);
    padding-right: var(--spacing-4x);
}

.moments-grid-content .arrow {
    text-align: center;
    cursor: pointer;
    color: var(--accent-color);
    margin-top: var(--spacing-4x);
}

.moments-grid-content .arrow:hover {
    color: var(--accent-color-dark);
}

@media only screen and (max-width: 414px) {
    .moments-grid-content .item-container {
        flex-basis: 90%;
    }
}