.MyMomentModal {
    align-items: center;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
}

.MyMomentModal .left-arrow,
.MyMomentModal .right-arrow,
.MyMomentModal .moment-preview-container {
    z-index: 1002;
}

.MyMomentModal .left-arrow {
    display: none;
    align-self: center;
    justify-content: flex-end;
    margin-right: var(--spacing-4x);
    z-index: 1010;
    position: absolute;
    left: calc(15vw / 2 - 36px - 44px);
}

.MyMomentModal .right-arrow {
    display: none;
    align-self: center;
    z-index: 1010;
    position: absolute;
    right: calc(15vw / 2 - 36px - 44px);
}

@media screen and (min-width: 541px) {
    .MyMomentModal  .left-arrow,
    .MyMomentModal  .right-arrow {
        display: flex;
    }
}