@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://api.go.traceup.com/trace-icon-font-v25/css/fontello.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css');
@import "variables.css";

body {
    background  : var(--white1-color);
    font-family : 'Open Sans', sans-serif;
    font-size   : 16px;
    font-weight : 500;
    color       : #444444;
    line-height : 22px;
}

body.locked {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

/*** Typography ***/
a {
    color           : var(--accent-color);
    text-decoration : none;
}

a:active, a:hover {
    color   : var(--accent-color-dark);
    outline : 0;
}

* {
    box-sizing : border-box;
    margin     : 0;
}

h1,
h2,
h3,
h4,
h5 {
    padding: 0;
    margin: 0;
    line-height: 1.2;
    font-family : var(--heading-font-family);
    font-weight: var(--bold-font-weight);
    color: var(--black-color);
}

h1 {
    font-size: 38px;
    line-height: 48px;
    letter-spacing: -0.02em;
}
h2 {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
}
h3 {
    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.02em;
}
h4 {
    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.02em;
}
h5 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: var(--body-font-family);
}

/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px) and (min-height: 541px) {
    h1.title {
        font-size: 50px;
        line-height: 64px;
    }
    h2.title {
        font-size: 38px;
        line-height: 48px;
    }
    h3.title {
        font-size: 28px;
        line-height: 36px;
    }
}

@media only screen and (min-width: 1024px) {
    h5.title {
        font-size: 21px;
        line-height: 24px;
        letter-spacing: -0.02em;
    }
}

ul.List {
    list-style: disc;
    margin-left: var(--spacing-4x);
}
ul.List li {
    list-style: disc;
    margin-left: var(--spacing-4x);
    margin-bottom: var(--spacing);
}

.heading-font {
    font-family: var(--heading-font-family);
}

.body-font {
    font-family: var(--body-font-family);
}

.small-body-font {
    font-family: var(--body-font-family);
    font-weight: var(--body-font-weight);
    font-size: var(--small-font-size);
    line-height: var(--spacing-5x);
}

.text-bold {
    font-weight: var(--bold-font-weight);
}

.text-semi-bold {
    font-weight: 600;
}

.text-regular {
    font-weight: var(--body-font-weight);
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-small {
    font-size: 75%;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-underline {
    text-decoration: underline;
}

.touchable {
    cursor: pointer;
}

p {
    font-size: var(--body-font-size);
    line-height: 24px;
}

input, button, select {
    font-family : var(--body-font-family);
    font-size   : var(--body-font-size);
    font-weight : 500;
    color       : #444444;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    box-shadow: none;
}

textarea {
    font-family : var(--heading-font-family);
}

pre {
    border: 1px solid var(--grey3-color);
    background: var(--white1-color);
    padding: var(--spacing-4x);
    overflow: auto;
}

code {
    display: block;
    line-height: 1.6;
}


.text-note-color {
    color: var(--other-note-color);
}
.text-note-shade-color {
    color: var(--trace-shade-color);
}
.text-trace-regular-color,
.text-trace-color {
    color: var(--trace-regular-color);
}
.text-trace-tint1-color {
    color: var(--trace-tint1-color);
}
.text-trace-tint2-color {
    color: var(--trace-tint2-color);
}
.text-rust-shade-color {
    color: var(--rust-shade-color);
}
.text-rust-color {
    color: var(--rust-regular-color);
}
.text-rust-tint1-color {
    color: var(--rust-tint1-color);
}
.text-rust-tint2-color {
    color: var(--rust-tint2-color);
}
.text-sky-shade-color {
    color: var(--sky-shade-color);
}
.text-sky-color {
    color: var(--sky-regular-color);
}
.text-sky-tint1-color {
    color: var(--sky-tint1-color);
}
.text-sky-tint2-color {
    color: var(--sky-tint2-color);
}
.text-error-shade-color {
    color: var(--error-shade-color);
}
.text-error-color {
    color: var(--error-regular-color);
}
.text-error-tint1-color {
    color: var(--error-tint1-color);
}
.text-error-tint2-color {
    color: var(--error-tint2-color);
}
.text-warning-shade-color {
    color: var(--warning-shade-color);
}
.text-warning-color {
    color: var(--warning-regular-color);
}
.text-warning-tint1-color {
    color: var(--warning-tint1-color);
}
.text-warning-tint2-color {
    color: var(--warning-tint2-color);
}
.text-confirmed-shade-color {
    color: var(--confirmed-shade-color);
}
.text-confirmed-color {
    color: var(--confirmed-regular-color);
}
.text-confirmed-tint1-color {
    color: var(--confirmed-tint1-color);
}
.text-confirmed-tint2-color {
    color: var(--confirmed-tint2-color);
}
.text-black-color {
    color: var(--black-color);
}
.text-grey1-color {
    color: var(--grey1-color);
}
.text-grey2-color {
    color: var(--grey2-color);
}
.text-grey3-color {
    color: var(--grey3-color);
}
.text-grey4-color {
    color: var(--grey4-color);
}
.text-white1-color {
    color: var(--white1-color);
}
.text-white2-color {
    color: var(--white2-color);
}
/* New Brand Colors */
.text-teal-50-color {
    color: var(--teal-50-color);
}
.text-teal-40-color {
    color: var(--teal-40-color);
}
.text-teal-30-color {
    color: var(--teal-30-color);
}
.text-teal-30-hover-color {
    color: var(--teal-30-hover-color);
}
.text-teal-30-pressed-color {
    color: var(--teal-30-pressed-color);
}
.text-teal-20-color {
    color: var(--teal-20-color);
}
.text-teal-10-color {
    color: var(--teal-10-color);
}
.text-red-50-color {
    color: var(--red-50-color);
}
.text-red-40-color {
    color: var(--red-40-color);
}
.text-red-40-hover-color {
    color: var(--red-40-hover-color);
}
.text-red-40-pressed-color {
    color: var(--red-40-pressed-color);
}
.text-red-30-color {
    color: var(--red-30-color);
}
.text-red-20-color {
    color: var(--red-20-color);
}
.text-red-10-color {
    color: var(--red-10-color);
}
.text-yellow-50-color {
    color: var(--yellow-50-color);
}
.text-yellow-40-color {
    color: var(--yellow-40-color);
}
.text-yellow-40-hover-color {
    color: var(--yellow-40-hover-color);
}
.text-yellow-40-pressed-color {
    color: var(--yellow-40-pressed-color);
}
.text-yellow-30-color {
    color: var(--yellow-30-color);
}
.text-yellow-20-color {
    color: var(--yellow-20-color);
}
.text-yellow-10-color {
    color: var(--yellow-10-color);
}
.text-green-tint-color {
    color: var(--green-tint-color);
}
.text-green-tint-hover-color {
    color: var(--green-tint-hover-color);
}
.text-green-tint-pressed-color {
    color: var(--green-tint-pressed-color);
}
.text-blue-tint-color {
    color: var(--blue-tint-color);
}
.text-blue-tint-hover-color {
    color: var(--blue-tint-hover-color);
}
.text-blue-tint-pressed-color {
    color: var(--blue-tint-pressed-color);
}
.text-charcoal-50-color {
    color: var(--charcoal-50-color);
}
.text-charcoal-40-color {
    color: var(--charcoal-40-color);
}
.text-charcoal-30-color {
    color: var(--charcoal-30-color);
}
.text-charcoal-20-color {
    color: var(--charcoal-20-color);
}
.text-charcoal-10-color {
    color: var(--charcoal-10-color);
}
.text-charcoal-10-hover-color {
    color: var(--charcoal-10-hover-color);
}
.text-charcoal-10-pressed-color {
    color: var(--charcoal-10-pressed-color);
}
.text-charcoal-1-color {
    color: var(--charcoal-1-color);
}
.text-charcoal-1-hover-color {
    color: var(--charcoal-1-hover-color);
}
.text-charcoal-1-pressed-color {
    color: var(--charcoal-1-pressed-color);
}
.text-charcoal-50-transparent-75-color {
    color: var(--charcoal-50-transparent-75-color);
}
.text-charcoal-50-transparent-50-color {
    color: var(--charcoal-50-transparent-50-color);
}
.text-charcoal-50-transparent-25-color {
    color: var(--charcoal-50-transparent-25-color);
}
.text-sand-color {
    color: var(--sand-color);
}
.text-white-color {
    color: var(--white-color);
}
.text-white-hover-color {
    color: var(--white-hover-color);
}
.text-white-pressed-color {
    color: var(--white-pressed-color);
}

.bg-transparent-color {
    background-color: var(--transparent-color);
}

.bg-note-color {
    background-color: var(--other-note-color);
}
.bg-note-shade-color {
    background-color: var(--trace-shade-color);
}

.bg-trace-color,
.bg-trace-regular-color {
    background-color: var(--trace-regular-color);
}
.bg-trace-tint1-color {
    background-color: var(--trace-tint1-color);
}
.bg-trace-tint2-color {
    background-color: var(--trace-tint2-color);
}
.bg-rust-shade-color {
    background-color: var(--rust-shade-color);
}
.bg-rust-color {
    background-color: var(--rust-regular-color);
}
.bg-rust-tint1-color {
    background-color: var(--rust-tint1-color);
}
.bg-rust-tint2-color {
    background-color: var(--rust-tint2-color);
}
.bg-sky-shade-color {
    background-color: var(--sky-shade-color);
}
.bg-sky-color {
    background-color: var(--sky-regular-color);
}
.bg-sky-tint1-color {
    background-color: var(--sky-tint1-color);
}
.bg-sky-tint2-color {
    background-color: var(--sky-tint2-color);
}
.bg-error-shade-color {
    background-color: var(--error-shade-color);
}
.bg-error-color {
    background-color: var(--error-regular-color);
}
.bg-error-tint1-color {
    background-color: var(--error-tint1-color);
}
.bg-error-tint2-color {
    background-color: var(--error-tint2-color);
}
.bg-warning-shade-color {
    background-color: var(--warning-shade-color);
}
.bg-warning-color {
    background-color: var(--warning-regular-color);
}
.bg-warning-tint1-color {
    background-color: var(--warning-tint1-color);
}
.bg-warning-tint2-color {
    background-color: var(--warning-tint2-color);
}
.bg-confirmed-shade-color {
    background-color: var(--confirmed-shade-color);
}
.bg-confirmed-color {
    background-color: var(--confirmed-regular-color);
}
.bg-confirmed-tint1-color {
    background-color: var(--confirmed-tint1-color);
}
.bg-confirmed-tint2-color {
    background-color: var(--confirmed-tint2-color);
}
.bg-black-color {
    background-color: var(--black-color);
}
.bg-grey1-color {
    background-color: var(--grey1-color);
}
.bg-grey2-color {
    background-color: var(--grey2-color);
}
.bg-grey3-color {
    background-color: var(--grey3-color);
}
.bg-grey4-color {
    background-color: var(--grey4-color);
}
.bg-grey-transparent-color {
    background-color: var(--grey-transparent-color);
}
.bg-white1-color {
    background-color: var(--white1-color);
}
.bg-white2-color {
    background-color: var(--white2-color);
}

/* New brand colors */
.bg-teal-50-color {
    background-color: var(--teal-50-color);
}
.bg-teal-40-color {
    background-color: var(--teal-40-color);
}
.bg-teal-30-color {
    background-color: var(--teal-30-color);
}
.bg-teal-30-hover-color {
    background-color: var(--teal-30-hover-color);
}
.bg-teal-30-pressed-color {
    background-color: var(--teal-30-pressed-color);
}
.bg-teal-20-color {
    background-color: var(--teal-20-color);
}
.bg-teal-10-color {
    background-color: var(--teal-10-color);
}
.bg-red-50-color {
    background-color: var(--red-50-color);
}
.bg-red-40-color {
    background-color: var(--red-40-color);
}
.bg-red-40-hover-color {
    background-color: var(--red-40-hover-color);
}
.bg-red-40-pressed-color {
    background-color: var(--red-40-pressed-color);
}
.bg-red-30-color {
    background-color: var(--red-30-color);
}
.bg-red-20-color {
    background-color: var(--red-20-color);
}
.bg-red-10-color {
    background-color: var(--red-10-color);
}
.bg-yellow-50-color {
    background-color: var(--yellow-50-color);
}
.bg-yellow-40-color {
    background-color: var(--yellow-40-color);
}
.bg-yellow-40-hover-color {
    background-color: var(--yellow-40-hover-color);
}
.bg-yellow-40-pressed-color {
    background-color: var(--yellow-40-pressed-color);
}
.bg-yellow-30-color {
    background-color: var(--yellow-30-color);
}
.bg-yellow-20-color {
    background-color: var(--yellow-20-color);
}
.bg-yellow-10-color {
    background-color: var(--yellow-10-color);
}
.bg-green-tint-color {
    background-color: var(--green-tint-color);
}
.bg-green-tint-hover-color {
    background-color: var(--green-tint-hover-color);
}
.bg-green-tint-pressed-color {
    background-color: var(--green-tint-pressed-color);
}
.bg-blue-tint-color {
    background-color: var(--blue-tint-color);
}
.bg-blue-tint-hover-color {
    background-color: var(--blue-tint-hover-color);
}
.bg-blue-tint-pressed-color {
    background-color: var(--blue-tint-pressed-color);
}
.bg-charcoal-50-color {
    background-color: var(--charcoal-50-color);
}
.bg-charcoal-40-color {
    background-color: var(--charcoal-40-color);
}
.bg-charcoal-30-color {
    background-color: var(--charcoal-30-color);
}
.bg-charcoal-20-color {
    background-color: var(--charcoal-20-color);
}
.bg-charcoal-10-color {
    background-color: var(--charcoal-10-color);
}
.bg-charcoal-10-hover-color {
    background-color: var(--charcoal-10-hover-color);
}
.bg-charcoal-10-pressed-color {
    background-color: var(--charcoal-10-pressed-color);
}
.bg-charcoal-1-color {
    background-color: var(--charcoal-1-color);
}
.bg-charcoal-1-hover-color {
    background-color: var(--charcoal-1-hover-color);
}
.bg-charcoal-1-pressed-color {
    background-color: var(--charcoal-1-pressed-color);
}
.bg-charcoal-50-transparent-75-color {
    background-color: var(--charcoal-50-transparent-75-color);
}
.bg-charcoal-50-transparent-50-color {
    background-color: var(--charcoal-50-transparent-50-color);
}
.bg-charcoal-50-transparent-25-color {
    background-color: var(--charcoal-50-transparent-25-color);
}
.bg-sand-color {
    background-color: var(--sand-color);
}
.bg-white-color {
    background-color: var(--white-color);
}
.bg-white-hover-color {
    background-color: var(--white-hover-color);
}
.bg-white-pressed-color {
    background-color: var(--white-pressed-color);
}
.bg-white-transparent-90-color {
    background-color: var(--white-transparent-90-color);
}
.bg-white-transparent-75-color {
    background-color: var(--white-transparent-75-color);
}
.bg-white-transparent-50-color {
    background-color: var(--white-transparent-50-color);
}
.bg-white-transparent-25-color {
    background-color: var(--white-transparent-25-color);
}

.border-note-color {
    border-color: var(--other-note-color);
}
.border-note-shade-color {
    border-color: var(--trace-shade-color);
}
.border-trace-regular-color,
.border-trace-color {
    border-color: var(--trace-regular-color);
}
.border-trace-tint1-color {
    border-color: var(--trace-tint1-color);
}
.border-trace-tint2-color {
    border-color: var(--trace-tint2-color);
}
.border-rust-shade-color {
    border-color: var(--rust-shade-color);
}
.border-rust-color {
    border-color: var(--rust-regular-color);
}
.border-rust-tint1-color {
    border-color: var(--rust-tint1-color);
}
.border-rust-tint2-color {
    border-color: var(--rust-tint2-color);
}
.border-sky-shade-color {
    border-color: var(--sky-shade-color);
}
.border-sky-color {
    border-color: var(--sky-regular-color);
}
.border-sky-tint1-color {
    border-color: var(--sky-tint1-color);
}
.border-sky-tint2-color {
    border-color: var(--sky-tint2-color);
}
.border-error-shade-color {
    border-color: var(--error-shade-color);
}
.border-error-color,
.border-error-regular-color {
    border-color: var(--error-regular-color);
}
.border-error-tint1-color {
    border-color: var(--error-tint1-color);
}
.border-error-tint2-color {
    border-color: var(--error-tint2-color);
}
.border-warning-shade-color {
    border-color: var(--warning-shade-color);
}
.border-warning-color {
    border-color: var(--warning-regular-color);
}
.border-warning-tint1-color {
    border-color: var(--warning-tint1-color);
}
.border-warning-tint2-color {
    border-color: var(--warning-tint2-color);
}
.border-confirmed-shade-color {
    border-color: var(--confirmed-shade-color);
}
.border-confirmed-color {
    border-color: var(--confirmed-regular-color);
}
.border-confirmed-tint1-color {
    border-color: var(--confirmed-tint1-color);
}
.border-confirmed-tint2-color {
    border-color: var(--confirmed-tint2-color);
}
.border-black-color {
    border-color: var(--black-color);
}
.border-grey1-color {
    border-color: var(--grey1-color);
}
.border-grey2-color {
    border-color: var(--grey2-color);
}
.border-grey3-color {
    border-color: var(--grey3-color);
}
.border-grey4-color {
    border-color: var(--grey4-color);
}
.border-white1-color {
    border-color: var(--white1-color);
}
.border-white2-color {
    border-color: var(--white2-color);
}
.border-none {
    border-color: transparent;
}


/*** End Typography ***/



/*** Shadows & borders ***/

.shadow-none {
    box-shadow: none;
}

.shadow-xs {
    box-shadow: var(--shadow-xs);
}

.shadow-sm {
    box-shadow: var(--shadow-sm);
}

.shadow-md {
    box-shadow: var(--shadow-md);
}

.shadow-lg {
    box-shadow: var(--shadow-lg);
}

.border-thin {
    border: 1px solid var(--grey4-color);
}
.border-thin-top {
    border-top: 1px solid var(--grey4-color);
}
.border-thin-bottom {
    border-bottom: 1px solid var(--grey4-color);
}
.border-thin-left {
    border-left: 1px solid var(--grey4-color);
}
.border-thin-right {
    border-right: 1px solid var(--grey4-color);
}

.border-regular {
    border: 2px solid var(--grey4-color);
}
.border-regular-top {
    border-top: 2px solid var(--grey4-color);
}
.border-regular-bottom {
    border-bottom: 2px solid var(--grey4-color);
}
.border-regular-left {
    border-left: 2px solid var(--grey4-color);
}
.border-regular-right {
    border-right: 2px solid var(--grey4-color);
}

.border-warning {
    border: 2px solid var(--grey4-color);
}
.border-warning-top {
    border-top: 2px solid var(--grey4-color);
}
.border-warning-bottom {
    border-bottom: 2px solid var(--grey4-color);
}
.border-warning-left {
    border-left: 2px solid var(--grey4-color);
}
.border-warning-right {
    border-right: 2px solid var(--grey4-color);
}

/*** End Shadows ***/



/*** Layout, Paddings and Margins ***/

.Box {
    width: 50px;
    height: 50px;
}

.Card {
    background-color: var(--white2-color);
    padding: var(--spacing-4x);
    border-radius: 8px;
    box-shadow: 0 2px 0 var(--grey4-color);
}

.Column.start,
.Row.start {
    align-items: flex-start;
}

.wrap {
    flex-wrap: wrap;
}

.Row {
    display: flex;
    flex-wrap: wrap;
}

.Column {
    display: flex;
    flex-direction: column;
}

.Column.center {
    align-items: center;
}

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .Column-XS.center {
        align-items: center;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .Column-SM.center {
        align-items: center;
    }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .Column-MD.center {
        align-items: center;
    }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .Column-LG.center {
        align-items: center;
    }
}

.Row.center {
    justify-content: center;
}

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .Row-XS.center {
        justify-content: center;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .Row-SM.center {
        justify-content: center;
    }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .Row-MD.center {
        justify-content: center;
    }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .Row-LG.center {
        justify-content: center;
    }
}

.Column.start,
.Row.start {
    align-items: flex-start;
}

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .Column-XS.start,
    .Row-XS.start {
        align-items: flex-start;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .Column-SM.start,
    .Row-SM.center {
        align-items: flex-start;
    }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .Column-MD.start,
    .Row-MD.center {
        align-items: flex-start;
    }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .Column-LG.start,
    .Row-LG.center {
        align-items: flex-start;
    }
}

/* v2 Mobile Portrait Only (XS) */
@media only screen and (max-width: 540px) {
    .Row-XS {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .Column-XS {
        display: flex;
        flex-direction: column;
    }
}

/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {
    .Row-SM {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .Column-SM {
        display: flex;
        flex-direction: column;
    }
}

/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .Row-MD {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .Column-MD {
        display: flex;
        flex-direction: column;
    }
}

/* v2 Desktop (LG+) */
@media only screen and (min-width: 1024px) {
    .Row-LG {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .Column-LG {
        display: flex;
        flex-direction: column;
    }
}



/*** Padding ***/

.span-1 { flex: 1; }
.span-2 { flex: 2; }
.span-3 { flex: 3; }
.span-4 { flex: 4; }
.span-5 { flex: 5; }
.span-6 { flex: 6; }
.span-7 { flex: 7; }
.span-8 { flex: 8; }
.span-9 { flex: 9; }
.span-10 { flex: 10; }
.span-11 { flex: 11; }
.span-12 { flex: 12; }


/* v2 Mobile Portrait Only (XS) */
@media only screen and (max-width: 540px) {
    .span-1-xs { flex: 1; }
    .span-2-xs { flex: 2; }
    .span-3-xs { flex: 3; }
    .span-4-xs { flex: 4; }
    .span-5-xs { flex: 5; }
    .span-6-xs { flex: 6; }
    .span-7-xs { flex: 7; }
    .span-8-xs { flex: 8; }
    .span-9-xs { flex: 9; }
    .span-10-xs { flex: 10; }
    .span-11-xs { flex: 11; }
    .span-12-xs { flex: 12; }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .span-1-sm { flex: 1; }
    .span-2-sm { flex: 2; }
    .span-3-sm { flex: 3; }
    .span-4-sm { flex: 4; }
    .span-5-sm { flex: 5; }
    .span-6-sm { flex: 6; }
    .span-7-sm { flex: 7; }
    .span-8-sm { flex: 8; }
    .span-9-sm { flex: 9; }
    .span-10-sm { flex: 10; }
    .span-11-sm { flex: 11; }
    .span-12-sm { flex: 12; }
}

/* v2 Tablet Only (MD) */
@media only screen and (min-width: 541px) and (max-width: 1023px) and (min-height: 541px) {
    .span-1-md { flex: 1; }
    .span-2-md { flex: 2; }
    .span-3-md { flex: 3; }
    .span-4-md { flex: 4; }
    .span-5-md { flex: 5; }
    .span-6-md { flex: 6; }
    .span-7-md { flex: 7; }
    .span-8-md { flex: 8; }
    .span-9-md { flex: 9; }
    .span-10-md { flex: 10; }
    .span-11-md { flex: 11; }
    .span-12-md { flex: 12; }
}

/* v2 Desktop (LG+) */
@media only screen and (min-width: 1024px) {
    .span-1-lg { flex: 1; }
    .span-2-lg { flex: 2; }
    .span-3-lg { flex: 3; }
    .span-4-lg { flex: 4; }
    .span-5-lg { flex: 5; }
    .span-6-lg { flex: 6; }
    .span-7-lg { flex: 7; }
    .span-8-lg { flex: 8; }
    .span-9-lg { flex: 9; }
    .span-10-lg { flex: 10; }
    .span-11-lg { flex: 11; }
    .span-12-lg { flex: 12; }
}



/*** End Padding ***/

.pad { padding: var(--spacing); }
.pad-0 { padding: 0; }
.pad-1 { padding: var(--spacing); }
.pad-2 { padding: var(--spacing-2x); }
.pad-3 { padding: var(--spacing-3x); }
.pad-4 { padding: var(--spacing-4x); }
.pad-5 { padding: var(--spacing-5x); }
.pad-6 { padding: var(--spacing-6x); }
.pad-7 { padding: var(--spacing-7x); }
.pad-8 { padding: var(--spacing-8x); }
.pad-9 { padding: var(--spacing-9x); }
.pad-10 { padding: var(--spacing-10x); }
.pad-11 { padding: var(--spacing-11x); }
.pad-12 { padding: var(--spacing-12x); }
.pad-13 { padding: var(--spacing-13x); }
.pad-14 { padding: var(--spacing-14x); }
.pad-15 { padding: var(--spacing-15x); }
.pad-16 { padding: var(--spacing-16x); }
.pad-17 { padding: var(--spacing-17x); }
.pad-18 { padding: var(--spacing-18x); }
.pad-19 { padding: var(--spacing-19x); }
.pad-20 { padding: var(--spacing-20x); }

.pt { padding-top: var(--spacing); }
.pt-0 { padding-top: 0; }
.pt-1 { padding-top: var(--spacing); }
.pt-2 { padding-top: var(--spacing-2x); }
.pt-3 { padding-top: var(--spacing-3x); }
.pt-4 { padding-top: var(--spacing-4x); }
.pt-5 { padding-top: var(--spacing-5x); }
.pt-6 { padding-top: var(--spacing-6x); }
.pt-7 { padding-top: var(--spacing-7x); }
.pt-8 { padding-top: var(--spacing-8x); }
.pt-9 { padding-top: var(--spacing-9x); }
.pt-10 { padding-top: var(--spacing-10x); }
.pt-11 { padding-top: var(--spacing-11x); }
.pt-12 { padding-top: var(--spacing-12x); }
.pt-13 { padding-top: var(--spacing-13x); }
.pt-14 { padding-top: var(--spacing-14x); }
.pt-15 { padding-top: var(--spacing-15x); }
.pt-16 { padding-top: var(--spacing-16x); }
.pt-17 { padding-top: var(--spacing-17x); }
.pt-18 { padding-top: var(--spacing-18x); }
.pt-19 { padding-top: var(--spacing-19x); }
.pt-20 { padding-top: var(--spacing-20x); }

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .pt-xs { padding-top: var(--spacing); }
    .pt-0-xs { padding-top: 0; }
    .pt-1-xs { padding-top: var(--spacing); }
    .pt-2-xs { padding-top: var(--spacing-2x); }
    .pt-3-xs { padding-top: var(--spacing-3x); }
    .pt-4-xs { padding-top: var(--spacing-4x); }
    .pt-5-xs { padding-top: var(--spacing-5x); }
    .pt-6-xs { padding-top: var(--spacing-6x); }
    .pt-7-xs { padding-top: var(--spacing-7x); }
    .pt-8-xs { padding-top: var(--spacing-8x); }
    .pt-9-xs { padding-top: var(--spacing-9x); }
    .pt-10-xs { padding-top: var(--spacing-10x); }
    .pt-11-xs { padding-top: var(--spacing-11x); }
    .pt-12-xs { padding-top: var(--spacing-12x); }
    .pt-13-xs { padding-top: var(--spacing-13x); }
    .pt-14-xs { padding-top: var(--spacing-14x); }
    .pt-15-xs { padding-top: var(--spacing-15x); }
    .pt-16-xs { padding-top: var(--spacing-16x); }
    .pt-17-xs { padding-top: var(--spacing-17x); }
    .pt-18-xs { padding-top: var(--spacing-18x); }
    .pt-19-xs { padding-top: var(--spacing-19x); }
    .pt-20-xs { padding-top: var(--spacing-20x); }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .pt-sm { padding-top: var(--spacing); }
    .pt-0-sm { padding-top: 0; }
    .pt-1-sm { padding-top: var(--spacing); }
    .pt-2-sm { padding-top: var(--spacing-2x); }
    .pt-3-sm { padding-top: var(--spacing-3x); }
    .pt-4-sm { padding-top: var(--spacing-4x); }
    .pt-5-sm { padding-top: var(--spacing-5x); }
    .pt-6-sm { padding-top: var(--spacing-6x); }
    .pt-7-sm { padding-top: var(--spacing-7x); }
    .pt-8-sm { padding-top: var(--spacing-8x); }
    .pt-9-sm { padding-top: var(--spacing-9x); }
    .pt-10-sm { padding-top: var(--spacing-10x); }
    .pt-11-sm { padding-top: var(--spacing-11x); }
    .pt-12-sm { padding-top: var(--spacing-12x); }
    .pt-13-sm { padding-top: var(--spacing-13x); }
    .pt-14-sm { padding-top: var(--spacing-14x); }
    .pt-15-sm { padding-top: var(--spacing-15x); }
    .pt-16-sm { padding-top: var(--spacing-16x); }
    .pt-17-sm { padding-top: var(--spacing-17x); }
    .pt-18-sm { padding-top: var(--spacing-18x); }
    .pt-19-sm { padding-top: var(--spacing-19x); }
    .pt-20-sm { padding-top: var(--spacing-20x); }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .pt-md { padding-top: var(--spacing); }
    .pt-0-md { padding-top: 0; }
    .pt-1-md { padding-top: var(--spacing); }
    .pt-2-md { padding-top: var(--spacing-2x); }
    .pt-3-md { padding-top: var(--spacing-3x); }
    .pt-4-md { padding-top: var(--spacing-4x); }
    .pt-5-md { padding-top: var(--spacing-5x); }
    .pt-6-md { padding-top: var(--spacing-6x); }
    .pt-7-md { padding-top: var(--spacing-7x); }
    .pt-8-md { padding-top: var(--spacing-8x); }
    .pt-9-md { padding-top: var(--spacing-9x); }
    .pt-10-md { padding-top: var(--spacing-10x); }
    .pt-11-md { padding-top: var(--spacing-11x); }
    .pt-12-md { padding-top: var(--spacing-12x); }
    .pt-13-md { padding-top: var(--spacing-13x); }
    .pt-14-md { padding-top: var(--spacing-14x); }
    .pt-15-md { padding-top: var(--spacing-15x); }
    .pt-16-md { padding-top: var(--spacing-16x); }
    .pt-17-md { padding-top: var(--spacing-17x); }
    .pt-18-md { padding-top: var(--spacing-18x); }
    .pt-19-md { padding-top: var(--spacing-19x); }
    .pt-20-md { padding-top: var(--spacing-20x); }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .pt-lg { padding-top: var(--spacing); }
    .pt-0-lg { padding-top: 0; }
    .pt-1-lg { padding-top: var(--spacing); }
    .pt-2-lg { padding-top: var(--spacing-2x); }
    .pt-3-lg { padding-top: var(--spacing-3x); }
    .pt-4-lg { padding-top: var(--spacing-4x); }
    .pt-5-lg { padding-top: var(--spacing-5x); }
    .pt-6-lg { padding-top: var(--spacing-6x); }
    .pt-7-lg { padding-top: var(--spacing-7x); }
    .pt-8-lg { padding-top: var(--spacing-8x); }
    .pt-9-lg { padding-top: var(--spacing-9x); }
    .pt-10-lg { padding-top: var(--spacing-10x); }
    .pt-11-lg { padding-top: var(--spacing-11x); }
    .pt-12-lg { padding-top: var(--spacing-12x); }
    .pt-13-lg { padding-top: var(--spacing-13x); }
    .pt-14-lg { padding-top: var(--spacing-14x); }
    .pt-15-lg { padding-top: var(--spacing-15x); }
    .pt-16-lg { padding-top: var(--spacing-16x); }
    .pt-17-lg { padding-top: var(--spacing-17x); }
    .pt-18-lg { padding-top: var(--spacing-18x); }
    .pt-19-lg { padding-top: var(--spacing-19x); }
    .pt-20-lg { padding-top: var(--spacing-20x); }
}

.pb { padding-bottom: var(--spacing); }
.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: var(--spacing); }
.pb-2 { padding-bottom: var(--spacing-2x); }
.pb-3 { padding-bottom: var(--spacing-3x); }
.pb-4 { padding-bottom: var(--spacing-4x); }
.pb-5 { padding-bottom: var(--spacing-5x); }
.pb-6 { padding-bottom: var(--spacing-6x); }
.pb-7 { padding-bottom: var(--spacing-7x); }
.pb-8 { padding-bottom: var(--spacing-8x); }
.pb-9 { padding-bottom: var(--spacing-9x); }
.pb-10 { padding-bottom: var(--spacing-10x); }
.pb-11 { padding-bottom: var(--spacing-11x); }
.pb-12 { padding-bottom: var(--spacing-12x); }
.pb-13 { padding-bottom: var(--spacing-13x); }
.pb-14 { padding-bottom: var(--spacing-14x); }
.pb-15 { padding-bottom: var(--spacing-15x); }
.pb-16 { padding-bottom: var(--spacing-16x); }
.pb-17 { padding-bottom: var(--spacing-17x); }
.pb-18 { padding-bottom: var(--spacing-18x); }
.pb-19 { padding-bottom: var(--spacing-19x); }
.pb-20 { padding-bottom: var(--spacing-20x); }

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .pb-xs { padding-bottom: var(--spacing); }
    .pb-0-xs { padding-bottom: 0; }
    .pb-1-xs { padding-bottom: var(--spacing); }
    .pb-2-xs { padding-bottom: var(--spacing-2x); }
    .pb-3-xs { padding-bottom: var(--spacing-3x); }
    .pb-4-xs { padding-bottom: var(--spacing-4x); }
    .pb-5-xs { padding-bottom: var(--spacing-5x); }
    .pb-6-xs { padding-bottom: var(--spacing-6x); }
    .pb-7-xs { padding-bottom: var(--spacing-7x); }
    .pb-8-xs { padding-bottom: var(--spacing-8x); }
    .pb-9-xs { padding-bottom: var(--spacing-9x); }
    .pb-10-xs { padding-bottom: var(--spacing-10x); }
    .pb-11-xs { padding-bottom: var(--spacing-11x); }
    .pb-12-xs { padding-bottom: var(--spacing-12x); }
    .pb-13-xs { padding-bottom: var(--spacing-13x); }
    .pb-14-xs { padding-bottom: var(--spacing-14x); }
    .pb-15-xs { padding-bottom: var(--spacing-15x); }
    .pb-16-xs { padding-bottom: var(--spacing-16x); }
    .pb-17-xs { padding-bottom: var(--spacing-17x); }
    .pb-18-xs { padding-bottom: var(--spacing-18x); }
    .pb-19-xs { padding-bottom: var(--spacing-19x); }
    .pb-20-xs { padding-bottom: var(--spacing-20x); }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .pb-sm { padding-bottom: var(--spacing); }
    .pb-0-sm { padding-bottom: 0; }
    .pb-1-sm { padding-bottom: var(--spacing); }
    .pb-2-sm { padding-bottom: var(--spacing-2x); }
    .pb-3-sm { padding-bottom: var(--spacing-3x); }
    .pb-4-sm { padding-bottom: var(--spacing-4x); }
    .pb-5-sm { padding-bottom: var(--spacing-5x); }
    .pb-6-sm { padding-bottom: var(--spacing-6x); }
    .pb-7-sm { padding-bottom: var(--spacing-7x); }
    .pb-8-sm { padding-bottom: var(--spacing-8x); }
    .pb-9-sm { padding-bottom: var(--spacing-9x); }
    .pb-10-sm { padding-bottom: var(--spacing-10x); }
    .pb-11-sm { padding-bottom: var(--spacing-11x); }
    .pb-12-sm { padding-bottom: var(--spacing-12x); }
    .pb-13-sm { padding-bottom: var(--spacing-13x); }
    .pb-14-sm { padding-bottom: var(--spacing-14x); }
    .pb-15-sm { padding-bottom: var(--spacing-15x); }
    .pb-16-sm { padding-bottom: var(--spacing-16x); }
    .pb-17-sm { padding-bottom: var(--spacing-17x); }
    .pb-18-sm { padding-bottom: var(--spacing-18x); }
    .pb-19-sm { padding-bottom: var(--spacing-19x); }
    .pb-20-sm { padding-bottom: var(--spacing-20x); }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .pb-md { padding-bottom: var(--spacing); }
    .pb-0-md { padding-bottom: 0; }
    .pb-1-md { padding-bottom: var(--spacing); }
    .pb-2-md { padding-bottom: var(--spacing-2x); }
    .pb-3-md { padding-bottom: var(--spacing-3x); }
    .pb-4-md { padding-bottom: var(--spacing-4x); }
    .pb-5-md { padding-bottom: var(--spacing-5x); }
    .pb-6-md { padding-bottom: var(--spacing-6x); }
    .pb-7-md { padding-bottom: var(--spacing-7x); }
    .pb-8-md { padding-bottom: var(--spacing-8x); }
    .pb-9-md { padding-bottom: var(--spacing-9x); }
    .pb-10-md { padding-bottom: var(--spacing-10x); }
    .pb-11-md { padding-bottom: var(--spacing-11x); }
    .pb-12-md { padding-bottom: var(--spacing-12x); }
    .pb-13-md { padding-bottom: var(--spacing-13x); }
    .pb-14-md { padding-bottom: var(--spacing-14x); }
    .pb-15-md { padding-bottom: var(--spacing-15x); }
    .pb-16-md { padding-bottom: var(--spacing-16x); }
    .pb-17-md { padding-bottom: var(--spacing-17x); }
    .pb-18-md { padding-bottom: var(--spacing-18x); }
    .pb-19-md { padding-bottom: var(--spacing-19x); }
    .pb-20-md { padding-bottom: var(--spacing-20x); }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .pb-lg { padding-bottom: var(--spacing); }
    .pb-0-lg { padding-bottom: 0; }
    .pb-1-lg { padding-bottom: var(--spacing); }
    .pb-2-lg { padding-bottom: var(--spacing-2x); }
    .pb-3-lg { padding-bottom: var(--spacing-3x); }
    .pb-4-lg { padding-bottom: var(--spacing-4x); }
    .pb-5-lg { padding-bottom: var(--spacing-5x); }
    .pb-6-lg { padding-bottom: var(--spacing-6x); }
    .pb-7-lg { padding-bottom: var(--spacing-7x); }
    .pb-8-lg { padding-bottom: var(--spacing-8x); }
    .pb-9-lg { padding-bottom: var(--spacing-9x); }
    .pb-10-lg { padding-bottom: var(--spacing-10x); }
    .pb-11-lg { padding-bottom: var(--spacing-11x); }
    .pb-12-lg { padding-bottom: var(--spacing-12x); }
    .pb-13-lg { padding-bottom: var(--spacing-13x); }
    .pb-14-lg { padding-bottom: var(--spacing-14x); }
    .pb-15-lg { padding-bottom: var(--spacing-15x); }
    .pb-16-lg { padding-bottom: var(--spacing-16x); }
    .pb-17-lg { padding-bottom: var(--spacing-17x); }
    .pb-18-lg { padding-bottom: var(--spacing-18x); }
    .pb-19-lg { padding-bottom: var(--spacing-19x); }
    .pb-20-lg { padding-bottom: var(--spacing-20x); }
}

.pl { padding-left: var(--spacing); }
.pl-0 { padding-left: 0; }
.pl-1 { padding-left: var(--spacing); }
.pl-2 { padding-left: var(--spacing-2x); }
.pl-3 { padding-left: var(--spacing-3x); }
.pl-4 { padding-left: var(--spacing-4x); }
.pl-5 { padding-left: var(--spacing-5x); }
.pl-6 { padding-left: var(--spacing-6x); }
.pl-7 { padding-left: var(--spacing-7x); }
.pl-8 { padding-left: var(--spacing-8x); }
.pl-9 { padding-left: var(--spacing-9x); }
.pl-10 { padding-left: var(--spacing-10x); }
.pl-11 { padding-left: var(--spacing-11x); }
.pl-12 { padding-left: var(--spacing-12x); }
.pl-13 { padding-left: var(--spacing-13x); }
.pl-14 { padding-left: var(--spacing-14x); }
.pl-15 { padding-left: var(--spacing-15x); }
.pl-16 { padding-left: var(--spacing-16x); }
.pl-17 { padding-left: var(--spacing-17x); }
.pl-18 { padding-left: var(--spacing-18x); }
.pl-19 { padding-left: var(--spacing-19x); }
.pl-20 { padding-left: var(--spacing-20x); }

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .pl-xs { padding-left: var(--spacing); }
    .pl-0-xs { padding-left: 0; }
    .pl-1-xs { padding-left: var(--spacing); }
    .pl-2-xs { padding-left: var(--spacing-2x); }
    .pl-3-xs { padding-left: var(--spacing-3x); }
    .pl-4-xs { padding-left: var(--spacing-4x); }
    .pl-5-xs { padding-left: var(--spacing-5x); }
    .pl-6-xs { padding-left: var(--spacing-6x); }
    .pl-7-xs { padding-left: var(--spacing-7x); }
    .pl-8-xs { padding-left: var(--spacing-8x); }
    .pl-9-xs { padding-left: var(--spacing-9x); }
    .pl-10-xs { padding-left: var(--spacing-10x); }
    .pl-11-xs { padding-left: var(--spacing-11x); }
    .pl-12-xs { padding-left: var(--spacing-12x); }
    .pl-13-xs { padding-left: var(--spacing-13x); }
    .pl-14-xs { padding-left: var(--spacing-14x); }
    .pl-15-xs { padding-left: var(--spacing-15x); }
    .pl-16-xs { padding-left: var(--spacing-16x); }
    .pl-17-xs { padding-left: var(--spacing-17x); }
    .pl-18-xs { padding-left: var(--spacing-18x); }
    .pl-19-xs { padding-left: var(--spacing-19x); }
    .pl-20-xs { padding-left: var(--spacing-20x); }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .pl-sm { padding-left: var(--spacing); }
    .pl-0-sm { padding-left: 0; }
    .pl-1-sm { padding-left: var(--spacing); }
    .pl-2-sm { padding-left: var(--spacing-2x); }
    .pl-3-sm { padding-left: var(--spacing-3x); }
    .pl-4-sm { padding-left: var(--spacing-4x); }
    .pl-5-sm { padding-left: var(--spacing-5x); }
    .pl-6-sm { padding-left: var(--spacing-6x); }
    .pl-7-sm { padding-left: var(--spacing-7x); }
    .pl-8-sm { padding-left: var(--spacing-8x); }
    .pl-9-sm { padding-left: var(--spacing-9x); }
    .pl-10-sm { padding-left: var(--spacing-10x); }
    .pl-11-sm { padding-left: var(--spacing-11x); }
    .pl-12-sm { padding-left: var(--spacing-12x); }
    .pl-13-sm { padding-left: var(--spacing-13x); }
    .pl-14-sm { padding-left: var(--spacing-14x); }
    .pl-15-sm { padding-left: var(--spacing-15x); }
    .pl-16-sm { padding-left: var(--spacing-16x); }
    .pl-17-sm { padding-left: var(--spacing-17x); }
    .pl-18-sm { padding-left: var(--spacing-18x); }
    .pl-19-sm { padding-left: var(--spacing-19x); }
    .pl-20-sm { padding-left: var(--spacing-20x); }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .pl-md { padding-left: var(--spacing); }
    .pl-0-md { padding-left: 0; }
    .pl-1-md { padding-left: var(--spacing); }
    .pl-2-md { padding-left: var(--spacing-2x); }
    .pl-3-md { padding-left: var(--spacing-3x); }
    .pl-4-md { padding-left: var(--spacing-4x); }
    .pl-5-md { padding-left: var(--spacing-5x); }
    .pl-6-md { padding-left: var(--spacing-6x); }
    .pl-7-md { padding-left: var(--spacing-7x); }
    .pl-8-md { padding-left: var(--spacing-8x); }
    .pl-9-md { padding-left: var(--spacing-9x); }
    .pl-10-md { padding-left: var(--spacing-10x); }
    .pl-11-md { padding-left: var(--spacing-11x); }
    .pl-12-md { padding-left: var(--spacing-12x); }
    .pl-13-md { padding-left: var(--spacing-13x); }
    .pl-14-md { padding-left: var(--spacing-14x); }
    .pl-15-md { padding-left: var(--spacing-15x); }
    .pl-16-md { padding-left: var(--spacing-16x); }
    .pl-17-md { padding-left: var(--spacing-17x); }
    .pl-18-md { padding-left: var(--spacing-18x); }
    .pl-19-md { padding-left: var(--spacing-19x); }
    .pl-20-md { padding-left: var(--spacing-20x); }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .pl-lg { padding-left: var(--spacing); }
    .pl-0-lg { padding-left: 0; }
    .pl-1-lg { padding-left: var(--spacing); }
    .pl-2-lg { padding-left: var(--spacing-2x); }
    .pl-3-lg { padding-left: var(--spacing-3x); }
    .pl-4-lg { padding-left: var(--spacing-4x); }
    .pl-5-lg { padding-left: var(--spacing-5x); }
    .pl-6-lg { padding-left: var(--spacing-6x); }
    .pl-7-lg { padding-left: var(--spacing-7x); }
    .pl-8-lg { padding-left: var(--spacing-8x); }
    .pl-9-lg { padding-left: var(--spacing-9x); }
    .pl-10-lg { padding-left: var(--spacing-10x); }
    .pl-11-lg { padding-left: var(--spacing-11x); }
    .pl-12-lg { padding-left: var(--spacing-12x); }
    .pl-13-lg { padding-left: var(--spacing-13x); }
    .pl-14-lg { padding-left: var(--spacing-14x); }
    .pl-15-lg { padding-left: var(--spacing-15x); }
    .pl-16-lg { padding-left: var(--spacing-16x); }
    .pl-17-lg { padding-left: var(--spacing-17x); }
    .pl-18-lg { padding-left: var(--spacing-18x); }
    .pl-19-lg { padding-left: var(--spacing-19x); }
    .pl-20-lg { padding-left: var(--spacing-20x); }
}

.pr { padding-right: var(--spacing); }
.pr-0 { padding-right: 0; }
.pr-1 { padding-right: var(--spacing); }
.pr-2 { padding-right: var(--spacing-2x); }
.pr-3 { padding-right: var(--spacing-3x); }
.pr-4 { padding-right: var(--spacing-4x); }
.pr-5 { padding-right: var(--spacing-5x); }
.pr-6 { padding-right: var(--spacing-6x); }
.pr-7 { padding-right: var(--spacing-7x); }
.pr-8 { padding-right: var(--spacing-8x); }
.pr-9 { padding-right: var(--spacing-9x); }
.pr-10 { padding-right: var(--spacing-10x); }
.pr-11 { padding-right: var(--spacing-11x); }
.pr-12 { padding-right: var(--spacing-12x); }
.pr-13 { padding-right: var(--spacing-13x); }
.pr-14 { padding-right: var(--spacing-14x); }
.pr-15 { padding-right: var(--spacing-15x); }
.pr-16 { padding-right: var(--spacing-16x); }
.pr-17 { padding-right: var(--spacing-17x); }
.pr-18 { padding-right: var(--spacing-18x); }
.pr-19 { padding-right: var(--spacing-19x); }
.pr-20 { padding-right: var(--spacing-20x); }

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .pr-xs { padding-right: var(--spacing); }
    .pr-0-xs { padding-right: 0; }
    .pr-1-xs { padding-right: var(--spacing); }
    .pr-2-xs { padding-right: var(--spacing-2x); }
    .pr-3-xs { padding-right: var(--spacing-3x); }
    .pr-4-xs { padding-right: var(--spacing-4x); }
    .pr-5-xs { padding-right: var(--spacing-5x); }
    .pr-6-xs { padding-right: var(--spacing-6x); }
    .pr-7-xs { padding-right: var(--spacing-7x); }
    .pr-8-xs { padding-right: var(--spacing-8x); }
    .pr-9-xs { padding-right: var(--spacing-9x); }
    .pr-10-xs { padding-right: var(--spacing-10x); }
    .pr-11-xs { padding-right: var(--spacing-11x); }
    .pr-12-xs { padding-right: var(--spacing-12x); }
    .pr-13-xs { padding-right: var(--spacing-13x); }
    .pr-14-xs { padding-right: var(--spacing-14x); }
    .pr-15-xs { padding-right: var(--spacing-15x); }
    .pr-16-xs { padding-right: var(--spacing-16x); }
    .pr-17-xs { padding-right: var(--spacing-17x); }
    .pr-18-xs { padding-right: var(--spacing-18x); }
    .pr-19-xs { padding-right: var(--spacing-19x); }
    .pr-20-xs { padding-right: var(--spacing-20x); }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .pr-sm { padding-right: var(--spacing); }
    .pr-0-sm { padding-right: 0; }
    .pr-1-sm { padding-right: var(--spacing); }
    .pr-2-sm { padding-right: var(--spacing-2x); }
    .pr-3-sm { padding-right: var(--spacing-3x); }
    .pr-4-sm { padding-right: var(--spacing-4x); }
    .pr-5-sm { padding-right: var(--spacing-5x); }
    .pr-6-sm { padding-right: var(--spacing-6x); }
    .pr-7-sm { padding-right: var(--spacing-7x); }
    .pr-8-sm { padding-right: var(--spacing-8x); }
    .pr-9-sm { padding-right: var(--spacing-9x); }
    .pr-10-sm { padding-right: var(--spacing-10x); }
    .pr-11-sm { padding-right: var(--spacing-11x); }
    .pr-12-sm { padding-right: var(--spacing-12x); }
    .pr-13-sm { padding-right: var(--spacing-13x); }
    .pr-14-sm { padding-right: var(--spacing-14x); }
    .pr-15-sm { padding-right: var(--spacing-15x); }
    .pr-16-sm { padding-right: var(--spacing-16x); }
    .pr-17-sm { padding-right: var(--spacing-17x); }
    .pr-18-sm { padding-right: var(--spacing-18x); }
    .pr-19-sm { padding-right: var(--spacing-19x); }
    .pr-20-sm { padding-right: var(--spacing-20x); }
}

/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .pr-md { padding-right: var(--spacing); }
    .pr-0-md { padding-right: 0; }
    .pr-1-md { padding-right: var(--spacing); }
    .pr-2-md { padding-right: var(--spacing-2x); }
    .pr-3-md { padding-right: var(--spacing-3x); }
    .pr-4-md { padding-right: var(--spacing-4x); }
    .pr-5-md { padding-right: var(--spacing-5x); }
    .pr-6-md { padding-right: var(--spacing-6x); }
    .pr-7-md { padding-right: var(--spacing-7x); }
    .pr-8-md { padding-right: var(--spacing-8x); }
    .pr-9-md { padding-right: var(--spacing-9x); }
    .pr-10-md { padding-right: var(--spacing-10x); }
    .pr-11-md { padding-right: var(--spacing-11x); }
    .pr-12-md { padding-right: var(--spacing-12x); }
    .pr-13-md { padding-right: var(--spacing-13x); }
    .pr-14-md { padding-right: var(--spacing-14x); }
    .pr-15-md { padding-right: var(--spacing-15x); }
    .pr-16-md { padding-right: var(--spacing-16x); }
    .pr-17-md { padding-right: var(--spacing-17x); }
    .pr-18-md { padding-right: var(--spacing-18x); }
    .pr-19-md { padding-right: var(--spacing-19x); }
    .pr-20-md { padding-right: var(--spacing-20x); }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .pr-lg { padding-right: var(--spacing); }
    .pr-0-lg { padding-right: 0; }
    .pr-1-lg { padding-right: var(--spacing); }
    .pr-2-lg { padding-right: var(--spacing-2x); }
    .pr-3-lg { padding-right: var(--spacing-3x); }
    .pr-4-lg { padding-right: var(--spacing-4x); }
    .pr-5-lg { padding-right: var(--spacing-5x); }
    .pr-6-lg { padding-right: var(--spacing-6x); }
    .pr-7-lg { padding-right: var(--spacing-7x); }
    .pr-8-lg { padding-right: var(--spacing-8x); }
    .pr-9-lg { padding-right: var(--spacing-9x); }
    .pr-10-lg { padding-right: var(--spacing-10x); }
    .pr-11-lg { padding-right: var(--spacing-11x); }
    .pr-12-lg { padding-right: var(--spacing-12x); }
    .pr-13-lg { padding-right: var(--spacing-13x); }
    .pr-14-lg { padding-right: var(--spacing-14x); }
    .pr-15-lg { padding-right: var(--spacing-15x); }
    .pr-16-lg { padding-right: var(--spacing-16x); }
    .pr-17-lg { padding-right: var(--spacing-17x); }
    .pr-18-lg { padding-right: var(--spacing-18x); }
    .pr-19-lg { padding-right: var(--spacing-19x); }
    .pr-20-lg { padding-right: var(--spacing-20x); }
}

.mar { margin: var(--spacing); }
.mar-0 { margin: 0; }
.mar-1 { margin: var(--spacing); }
.mar-2 { margin: var(--spacing-2x); }
.mar-3 { margin: var(--spacing-3x); }
.mar-4 { margin: var(--spacing-4x); }
.mar-5 { margin: var(--spacing-5x); }
.mar-6 { margin: var(--spacing-6x); }
.mar-7 { margin: var(--spacing-7x); }
.mar-8 { margin: var(--spacing-8x); }
.mar-9 { margin: var(--spacing-9x); }
.mar-10 { margin: var(--spacing-10x); }
.mar-11 { margin: var(--spacing-11x); }
.mar-12 { margin: var(--spacing-12x); }
.mar-13 { margin: var(--spacing-13x); }
.mar-14 { margin: var(--spacing-14x); }
.mar-15 { margin: var(--spacing-15x); }
.mar-16 { margin: var(--spacing-16x); }
.mar-17 { margin: var(--spacing-17x); }
.mar-18 { margin: var(--spacing-18x); }
.mar-19 { margin: var(--spacing-19x); }
.mar-20 { margin: var(--spacing-20x); }

.mt { margin-top: var(--spacing); }
.mt-0 { margin-top: 0; }
.mt-1 { margin-top: var(--spacing); }
.mt-2 { margin-top: var(--spacing-2x); }
.mt-3 { margin-top: var(--spacing-3x); }
.mt-4 { margin-top: var(--spacing-4x); }
.mt-5 { margin-top: var(--spacing-5x); }
.mt-6 { margin-top: var(--spacing-6x); }
.mt-7 { margin-top: var(--spacing-7x); }
.mt-8 { margin-top: var(--spacing-8x); }
.mt-9 { margin-top: var(--spacing-9x); }
.mt-10 { margin-top: var(--spacing-10x); }
.mt-11 { margin-top: var(--spacing-11x); }
.mt-12 { margin-top: var(--spacing-12x); }
.mt-13 { margin-top: var(--spacing-13x); }
.mt-14 { margin-top: var(--spacing-14x); }
.mt-15 { margin-top: var(--spacing-15x); }
.mt-16 { margin-top: var(--spacing-16x); }
.mt-17 { margin-top: var(--spacing-17x); }
.mt-18 { margin-top: var(--spacing-18x); }
.mt-19 { margin-top: var(--spacing-19x); }
.mt-20 { margin-top: var(--spacing-20x); }

.mb { margin-bottom: var(--spacing); }
.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: var(--spacing); }
.mb-2 { margin-bottom: var(--spacing-2x); }
.mb-3 { margin-bottom: var(--spacing-3x); }
.mb-4 { margin-bottom: var(--spacing-4x); }
.mb-5 { margin-bottom: var(--spacing-5x); }
.mb-6 { margin-bottom: var(--spacing-6x); }
.mb-7 { margin-bottom: var(--spacing-7x); }
.mb-8 { margin-bottom: var(--spacing-8x); }
.mb-9 { margin-bottom: var(--spacing-9x); }
.mb-10 { margin-bottom: var(--spacing-10x); }
.mb-11 { margin-bottom: var(--spacing-11x); }
.mb-12 { margin-bottom: var(--spacing-12x); }
.mb-13 { margin-bottom: var(--spacing-13x); }
.mb-14 { margin-bottom: var(--spacing-14x); }
.mb-15 { margin-bottom: var(--spacing-15x); }
.mb-16 { margin-bottom: var(--spacing-16x); }
.mb-17 { margin-bottom: var(--spacing-17x); }
.mb-18 { margin-bottom: var(--spacing-18x); }
.mb-19 { margin-bottom: var(--spacing-19x); }
.mb-20 { margin-bottom: var(--spacing-20x); }

.ml { margin-left: var(--spacing); }
.ml-0 { margin-left: 0; }
.ml-1 { margin-left: var(--spacing); }
.ml-2 { margin-left: var(--spacing-2x); }
.ml-3 { margin-left: var(--spacing-3x); }
.ml-4 { margin-left: var(--spacing-4x); }
.ml-5 { margin-left: var(--spacing-5x); }
.ml-6 { margin-left: var(--spacing-6x); }
.ml-7 { margin-left: var(--spacing-7x); }
.ml-8 { margin-left: var(--spacing-8x); }
.ml-9 { margin-left: var(--spacing-9x); }
.ml-10 { margin-left: var(--spacing-10x); }
.ml-11 { margin-left: var(--spacing-11x); }
.ml-12 { margin-left: var(--spacing-12x); }
.ml-13 { margin-left: var(--spacing-13x); }
.ml-14 { margin-left: var(--spacing-14x); }
.ml-15 { margin-left: var(--spacing-15x); }
.ml-16 { margin-left: var(--spacing-16x); }
.ml-17 { margin-left: var(--spacing-17x); }
.ml-18 { margin-left: var(--spacing-18x); }
.ml-19 { margin-left: var(--spacing-19x); }
.ml-20 { margin-left: var(--spacing-20x); }

.mr { margin-right: var(--spacing); }
.mr-0 { margin-right: 0; }
.mr-1 { margin-right: var(--spacing); }
.mr-2 { margin-right: var(--spacing-2x); }
.mr-3 { margin-right: var(--spacing-3x); }
.mr-4 { margin-right: var(--spacing-4x); }
.mr-5 { margin-right: var(--spacing-5x); }
.mr-6 { margin-right: var(--spacing-6x); }
.mr-7 { margin-right: var(--spacing-7x); }
.mr-8 { margin-right: var(--spacing-8x); }
.mr-9 { margin-right: var(--spacing-9x); }
.mr-10 { margin-right: var(--spacing-10x); }
.mr-11 { margin-right: var(--spacing-11x); }
.mr-12 { margin-right: var(--spacing-12x); }
.mr-13 { margin-right: var(--spacing-13x); }
.mr-14 { margin-right: var(--spacing-14x); }
.mr-15 { margin-right: var(--spacing-15x); }
.mr-16 { margin-right: var(--spacing-16x); }
.mr-17 { margin-right: var(--spacing-17x); }
.mr-18 { margin-right: var(--spacing-18x); }
.mr-19 { margin-right: var(--spacing-19x); }
.mr-20 { margin-right: var(--spacing-20x); }

/*** End Padding and Margins ***/


/*** Top Bar ***/

.TopBar {
    background: var(--white2-color);
    border-bottom: 2px solid var(--grey4-color);
}

.TopBar h4 {
    margin-block-start: 0;
    margin-block-end: 0;
}

.TopBarSticky {
    top: 0;
    position: sticky;
}

.TopBar .container {
    padding: 20px;
    display: flex;
}

.TopBar .Button.icn {
    line-height: 24px;
    height: 24px;
    margin-bottom: 0;
    color: var(--grey3-color);
}
.TopBar .Button.icn:hover {
    box-shadow: none;
}
.TopBar .Button.icn i.icon-menu {
    border: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 0;
    border-radius: 12px;
}
.TopBar .Button.icn:hover i.icon-menu {
    color: var(--grey3-color);
    background: transparent;
    box-shadow: none;
}
.TopBar .Button.icn i.icon-menu::before {
    color: var(--grey3-color);
}
.TopBar .Button.icn:hover i::before {
    color: var(--grey3-color);
}

/* v2 Mobile Portrait Only (XS) */
@media only screen and (max-width: 540px) {
    .TopBarSticky-xs {
        top: 0;
        position: sticky;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .TopBarSticky-sm {
        top: 0;
        position: sticky;
    }

}

/* v2 Tablet Only (MD) */
@media only screen and (min-width: 541px) and (max-width: 1023px) and (min-height: 541px) {
    .TopBarSticky-md {
        top: 0;
        position: sticky;
    }
}

/* v2 Desktop (LG+) */
@media only screen and (min-width: 1024px) {
    .TopBarSticky-lg {
        top: 0;
        position: sticky;
    }
}

/*** End Top Bar ***/



/*** Drawer ***/

.Drawer {
    position: fixed;
    left: -330px;
    top: 0;
    bottom: 0;
    width: 300px;
    background: var(--white2-color);
    z-index: 1;
    box-shadow: var(--shadow-lg);
    transition: left 0.3s;
}

.Drawer.open {
    left: 0;
}

.DrawerTitle {
    background: #fff;
    border-bottom: 1px solid #ccc;
}

.Drawer .Button.icn {
    line-height: 24px;
    height: 24px;
    margin-bottom: 0;
    color: var(--black-color);
}
.Drawer .Button.icn:hover {
    box-shadow: none;
}
.Drawer .Button.icn i[class^="icon"] {
    border: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 0;
    border-radius: 12px;
}
.Drawer .Button.icn:hover i[class^="icon"] {
    color: var(--grey1-color);
    background: transparent;
    box-shadow: none;
}
.Drawer .Button.icn i[class^="icon"]::before {
    margin: 2px 0 0 0;
    color: var(--black-color);
}
.Drawer .Button.icn:hover i::before {
    color: var(--grey1-color);
}

/*** Drawer ***/

/*** HamburgerMenu ***/

.HamburgerMenu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 50000;
    display: none;
}

.HamburgerMenu.open, .HamburgerMenu.closed {
    display: block;
}

.HamburgerMenu.open {
    pointer-events: auto;
}

.HamburgerMenu.closed {
    pointer-events: none;
}

.HamburgerMenu .Shade {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: -1;
    background: rgba(255, 255, 255, 0.01);
    transition: background 100ms;
}

.HamburgerMenu.open .Shade {
    animation-name: fadeIn;
    animation-duration: 150ms;
}

.HamburgerMenu.closed .Shade {
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 150ms;
}

.HamburgerMenu .HamburgerMenuInner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--white2-color);
    display: flex;
    flex-direction: column;
    border-radius: 0;
    transition: opacity 400ms;
    box-shadow: inset -2px 0 0 #F0F2F2;
    overflow-y: scroll;
    --webkit-overflow-scrolling: touch;
}

.HamburgerMenu.open .HamburgerMenuInner {
    animation-name: hamIn;
    animation-duration: 300ms;
    opacity: 1;
}

.HamburgerMenu.closed .HamburgerMenuInner {
    bottom: -100%;
    animation-name: hamOut;
    animation-duration: 300ms;
    opacity: 0;
}

.HamburgerMenu .Button.icn {
    line-height: 24px;
    height: 24px;
    color: var(--grey3-color);
    width: 24px;
}
.HamburgerMenu .Button.icn:hover {
    box-shadow: none;
}
.HamburgerMenu .Button.icn i[class^="icon"] {
    border: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin: 0;
    border-radius: 12px;
}
.HamburgerMenu .Button.icn:hover i[class^="icon"] {
    color: var(--grey3-color);
    background: transparent;
    box-shadow: none;
}
.HamburgerMenu .Button.icn i[class^="icon"]::before {
    margin: 2px 0 0 0;
    color: var(--grey3-color);
}
.HamburgerMenu .Button.icn:hover i::before {
    color: var(--grey3-color);
}

.more-line {
    width: var(--spacing-14x);
    height: 2px;
    background: #E6E4E1;
    border: none;
    float: left;
    margin: var(--spacing-4x) 0 var(--spacing-4x) 0;
}


/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {
    .HamburgerMenu .HamburgerMenuInner {
        width: 392px;
        padding-bottom: 60px;
    }
}
/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .HamburgerMenu .HamburgerMenuInner {
        padding-bottom: 0;
        overflow-y: auto;
        overflow-x: unset;
    }
}

@keyframes hamIn {
  from {
    transform: translate(-100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes hamOut {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-100%, 0);
  }
}

/*** HamburgerMenu end ***/


/*** Badge ***/

.Badge {
    position: relative;
    z-index: 0;
    height: 100%;
    width: 100%;
}

.BadgeInner {
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-radius: 100%;
}

.BadgeInner.top-right {
    top: 0;
    right: 0;
}
.BadgeInner.top-left {
    top: 0;
    left: 0;
}
.BadgeInner.bottom-left {
    bottom: 0;
    left: 0;
}
.BadgeInner.bottom-right {
    bottom: 0;
    right: 0;
}
.BadgeInner.inline {
    position: inherit;
}

/*** End Badge ***/



/*** Button ***/

.Button {
    display: flex;
    padding: 0;
    color: var(--trace-regular-color);
    background-color: transparent;
    outline: none;
    border: 2px solid var(--trace-tint2-color);
    border-radius: 32px;
    line-height: 1.25;
    box-shadow: none;
    transition: color 0.3s, background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
    cursor: pointer;
    font-family: var(--heading-font-family);
    font-weight: bold;

    --button-border-color-hover: var(--trace-regular-color);
    --button-border-color-active: var(--trace-shade-color);
    --button-border-color-focus: var(--trace-regular-color);

    --button-text-color-hover: var(--trace-shade2-color);
    --button-text-color-active: var(--trace-shade-color);
    --button-text-color-focus: var(--trace-regular-color);

    --button-background-color-hover: var(--trace-tint1-color);
    --button-background-color-active: var(--trace-shade2-color);
    --button-background-color-focus: transparent;

    --button-shadow-hover: none;
    --button-shadow-active: none;
    --button-shadow-focus: 0px 0px 10px #64C6F6;
}

.Button.small {
    font-size: 14px;
}

.Button.primary {
    color: var(--white1-color);
    background: var(--trace-regular-color);
    border-color: var(--trace-regular-color);

    --button-text-color-hover: var(--white2-color);
    --button-text-color-active: var(--white2-color);
    --button-text-color-focus: var(--white2-color);

    --button-border-color-hover: var(--trace-shade2-color);
    --button-border-color-active: var(--trace-shade-color);

    --button-background-color-hover: var(--trace-shade2-color);
    --button-background-color-active: var(--trace-shade-color);
    --button-background-color-focus: var(--trace-regular-color);
}

.Button.ghost {
    background-color: transparent;
    border-color: transparent;

    --button-text-color-hover: var(--trace-shade2-color);
    --button-text-color-focus: var(--trace-shade2-color);
    --button-text-color-active: var(--trace-shade-color);

    --button-border-color-focus: var(--grey4-color);
    --button-border-color-hover: var(--grey4-color);
    --button-border-color-active: var(--grey3-color);

    --button-background-color-hover: var(--grey4-color);
    --button-background-color-focus: var(--grey4-color);
    --button-background-color-active: var(--grey3-color);
}

.Button.disabled {
    background-color: transparent;
    border-color: var(--grey4-color);
    color: var(--grey3-color);

    --button-text-color-hover: var(--grey3-color);
    --button-text-color-active: var(--grey3-color);

    --button-border-color-hover: var(--grey4-color);
    --button-border-color-active: var(--grey4-color);

    --button-background-color-hover: transparent;
    --button-background-color-active: transparent;
}

.Button.disabled.primary {
    background-color: var(--grey4-color);
    border-color: var(--grey4-color);
    color: var(--grey3-color);

    --button-text-color-hover: var(--grey3-color);
    --button-text-color-active: var(--grey3-color);

    --button-border-color-hover: var(--grey4-color);
    --button-border-color-active: var(--grey4-color);

    --button-background-color-hover: var(--grey4-color);
    --button-background-color-active: var(--grey4-color);
}

.Button.disabled.ghost {
    background-color: transparent;
    border-color: transparent;
    color: var(--grey3-color);

    --button-text-color-hover: var(--grey3-color);
    --button-text-color-active: var(--grey3-color);

    --button-border-color-hover: transparent;
    --button-border-color-active: transparent;

    --button-background-color-hover: transparent;
    --button-background-color-active: transparent;
}


@media (pointer: fine) {
    .Button:focus {
        color: var(--button-text-color-focus);
        border-color: var(--button-border-color-focus);
        background-color: var(--button-background-color-focus);
        box-shadow: var(--button-shadow-focus);
    }
}


.Button:hover {
    color: var(--button-text-color-hover);
    border-color: var(--button-border-color-hover);
    background-color: var(--button-background-color-hover);
    box-shadow: var(--button-shadow-hover);
}

.Button:active {
    color: var(--button-text-color-active);
    border-color: var(--button-border-color-active);
    background-color: var(--button-background-color-active);
    box-shadow: var(--button-shadow-active);
}











.Button.icn {
    padding: 0;
    background-color: transparent;
    outline: none;
    border: none;
    line-height: 1.5;
    box-shadow: none;
    border-radius: 0;
}

.Button.icn span {
    margin-top: var(--spacing-2x);
}

.Button.icn i {
    line-height: 52px;
    width: 56px;
    height: 56px;
    border: 2px solid var(--trace-tint2-color);
    border-radius: 32px;
}

.Button.ghost.icn i {
    border: none;
}

.Button.icn i::before {
    position: relative;
    color: var(--trace-regular-color);
}

.Button.icn:hover {
    color: var(--trace-shade2-color);
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
}

.Button.icn:hover i {
    border-color: var(--trace-regular-color);
    background-color: var(--trace-tint2-color);
}

.Button.icn:hover i::before {
    color: var(--trace-shade2-color);
}

.Button.icn:active {
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
}

.Button.icn:active i {
    border-color: var(--trace-shade2-color);
    background-color: var(--trace-regular-color);
}

.Button.icn:active i::before {
    color: var(--trace-shade-color);
}

.button-title {
    font-family: var(--body-font-family);
    font-weight: var(--bold-font-weight);
    font-size: var(--body-font-size);
    letter-spacing: normal;
    line-height: var(--spacing-5x);
}

.small .button-title {
    font-size: 14px;
}

/*** End Button ***/


/*** Dropdown Menu ***/

.DropdownMenuShadeMobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(50, 51, 51, 0.5);
    z-index: 99;
}
.DropdownMenuShadeMobile.closed {
    display: none;
}

.DropdownMenuContentMobile {
    position: inherit;
}

.DropdownMenuOptionsMobile {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: left;
    z-index: 100000;
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    box-shadow: 0 var(--spacing) var(--spacing-6x) rgba(24, 84, 74, 0.2);
    border-radius: var(--spacing-4x) var(--spacing-4x) 0 0;
}

.NotificationsDropdown .DropdownMenuOptionsMobile {
    max-height: 100vh;
    overflow: auto;
}

.closed .DropdownMenuOptionsMobile {
    display: none;
}

.DropdownMenuContentMobile .DropdownItem:first-child {
    border-radius: var(--spacing-4x) var(--spacing-4x) 0 0;
    padding-top: var(--spacing-8x);
}

.DropdownMenuContentMobile .DropdownItem:last-child {
    padding-bottom: var(--spacing-16x);
}

.DropdownMenuContentMobile .DropdownItem:hover,
.DropdownMenuContentMobile .DropdownItem:active {
    background: var(--white1-color);
}

.DropdownMenuTargetMobile {
    border: none;
    padding: 0;
    outline: none;
}

.DropdownMenu {
    position: relative;
}

.DropdownMenuTarget {
    border: none;
    padding: 0;
    outline: none;
}

.closed .DropdownMenuOptions {
    display: none;
}

.DropdownMenuContent {
    border-radius: var(--spacing-4x) var(--spacing-4x) 0 0;
    width: 100vw;
    padding: var(--spacing-4x) var(--spacing-4x) var(--spacing-12x) var(--spacing-4x);
}
.DropdownMenuContentMobile .DropdownItem {
    background: var(--white2-color);
    outline: none;
    border: none;
    color: var(--trace-regular-color);
    padding: var(--spacing-4x) var(--spacing-8x) var(--spacing-4x) var(--spacing-8x);
    cursor: pointer;
}

.DropdownMenuContent .DropdownItem {
    background: var(--white2-color);
    outline: none;
    border: none;
    color: var(--trace-regular-color);
    padding: var(--spacing-4x);
    cursor: pointer;
}

.DropdownMenuContent .DropdownItem {
    border-radius: var(--spacing-4x);
    width: 100%;
}

.DropdownMenuContent .DropdownItem .Label {
    line-height: var(--spacing-5x);
}

.DropdownMenuContent .DropdownItem:hover {
    background: var(--grey4-color);
    color: var(--trace-shade2-color);
}

.DropdownMenuContent .DropdownItem:active {
    background: var(--grey3-color);
    color: var(--trace-shade2-color);
}

.DropdownMenuContent .szh-menu__item {
    padding: 0;
    border-radius: var(--spacing-4x);
}

.DropdownMenuContent .szh-menu__item--hover {
    background: var(--grey4-color);
    border-radius: var(--spacing-4x);
}

.szh-menu {
    border-radius: var(--spacing-4x);
    padding: var(--spacing-4x);
}

.NotificationsDropdown .szh-menu {
    max-height: 300px;
    overflow: auto;
}

.szh-menu.szh-menu--open {
    display: none;
}

/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px){

    .szh-menu.szh-menu--open.DropdownMenuContent {
        display: block;
    }

    .transparent-shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 1000;
    }

    .DropdownMenuContent {
        padding: var(--spacing-4x);
        border-radius: var(--spacing-4x);
        width: auto;
        flex-wrap: nowrap;
        z-index: 100000;
    }

    .DropdownMenuContent .DropdownItem {
        display: flex;
        border-radius: var(--spacing-4x);
        width: 100%;
        flex-wrap: nowrap;
    }

    .DropdownMenuContent .DropdownItem p {
        white-space: nowrap;
    }
}

/*** End Dropdown Menu ***/

/*** Avatar ***/

.Avatar {
    position: relative;
    background: var(--grey3-color);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    box-sizing: border-box;
    vertical-align: middle;
    text-align: center;
}

.Avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.Avatar .inner-avatar {
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    text-align: center;
    font-size: 15px;
    line-height: 2.3;
    color: var(--grey1-color);
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
    top: -1px;
    left: -1px;
}

.Avatar span.initials {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    border: 4px solid transparent;
    border-radius: 100%;
    font-family: var(--heading-font-family);
}

.Avatar .avatar-img {
    position: relative;
    top: -40px;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
    display: none;
}

.Avatar .Badge {
    position: absolute;
    top: 0;
}
.Avatar .Badge .top-right {
    right: -2px;
    top: -2px;
}

.Avatar .Badge .bottom-right {
    right: -2px;
    bottom: -2px;
}
.Avatar .Badge .bottom-left {
    left: -2px;
    bottom: -2px;
}

.Avatar .Badge .top-left {
    left: -2px;
    top: -2px;
}


/*** End Avatar ***/



/*** Chip ***/

.Chip {
    border-radius: 56px;
}

.Chip  .Badge {
    width: auto;
    height: 22px;
    position: static;
    float: left;
    display: flex;
    align-items: center;
    margin-right: 4px;
}

/*** End Chip ***/


/*** RoundButton ***/

.RoundButton {
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/*** End RoundButton ***/

/*** Video Controls ***/

.VideoControls {
    display: flex;
    padding-left: var(--spacing-4x);
    flex: 1;
}

.VideoControlButtonContainer,
.VideoControlButton {
    position: relative;
    margin: var(--spacing-4x);
    background: transparent;
    border-radius: 100%;
    cursor: pointer;
    box-sizing: content-box;
    align-items: center;
    display: flex;
    justify-content: center;
}

.VideoControlButton .video-control-title {
    background: var(--grey-transparent-hover-color);
    position: absolute;
    z-index: 310;
    bottom: calc(100% + 8px);
    font-weight: bold;
    border-radius: var(--spacing-16x);
    font-size: var(--chip-font-size);
    font-family: var(--heading-font-family);
    line-height: var(--spacing-5x);
    text-align: center;
    display: none;
    padding: var(--spacing-4x);
    color: var(--white2-color);
    white-space: nowrap;
}

.VideoControlButtonContainer .video-control-badge,
.VideoControlButton .video-control-badge {
    width: var(--spacing-6x);
    height: var(--spacing-6x);
    line-height: var(--spacing-6x);
    position: absolute;
    z-index: 2;
    top: 4.5px;
    left: 5px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}

.VideoControlButton:hover .video-control-title {
    display: none;
}

.VideoControlButtonContainer i,
.VideoControlButton i {
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
}


.VideoControlButtonContainer i:before,
.VideoControlButton i:before {
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    text-align: center;
    line-height: 36px;
}

.VideoControlDivider {
    background: rgba(255, 255, 255, 0.25);
    height: 56px;
    width: 2px;
    margin: 0 19px 0 15px;
}

/* v2 Mobile Portrait Only (XS) */
@media only screen and (max-width: 540px) {

    .VideoControls {
        background: var(--black-color);
        border-radius: 0;
        width: 100%;
        justify-content: center;
        padding: 0;
    }

    .VideoControlButtonContainer {
        position: relative;
        margin: var(--spacing-3x) 0;
        background: transparent;
        border-radius: 100%;
        cursor: pointer;
        box-sizing: content-box;
        flex-grow: 1;
        justify-content: center;
        height: var(--spacing-6x);
    }

    .VideoControlButton {
        position: relative;
        height: var(--spacing-6x);
        margin: var(--spacing-3x) 0;
        background: transparent;
        border-radius: 100%;
        cursor: pointer;
        box-sizing: content-box;
        flex-grow: 1;
        justify-content: center;
    }

    .VideoControlButton:hover {
        background: transparent;
    }
    .VideoControlDivider {
        height: 50px;
        width: 2px;
        margin: 0 12px 0 10px;
    }
    .VideoControlButton:hover .video-control-title {
        display: none;
    }

}

@media only screen and (min-width: 541px) {
    .VideoControlButtonContainer,
    .VideoControlButton {
        margin: var(--spacing-2x);
    }
}

@media only screen and (min-width: 1024px) {

    .VideoControlButtonContainer,
    .VideoControlButton {
        margin: var(--spacing);
        width: var(--spacing-14x);
        height: var(--spacing-14x);
    }

    .VideoControlButtonContainer {
        width: var(--spacing-16x);
    }
}

@media only screen and (min-width: 1200px) {
    .VideoControlButton:hover {
        background: var(--trace-regular-color);
    }

    .VideoControlButton:active {
        background: var(--trace-shade-color);
    }

    .VideoControlButton:hover .video-control-title {
        display: block;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .VideoControls {
        padding: var(--spacing-4x);
    }

    .VideoControlButton {
        background: transparent;
        margin: var(--spacing-2x);
    }
    .VideoControlButton:hover {
        background: transparent;
    }
    .VideoControlButton:hover .video-control-title {
        display: none;
    }
}

/*** End Video Controls ***/


/*** Moment Row ***/

.MomentRow {
    box-shadow: inset 0 -1px 0 var(--grey4-color);
    cursor: pointer;
    --dur1: 400ms;
    --dur2: 400ms;
    --durC: 200ms;
    --delay1: 0ms;
    --delay2: 0ms;
    --delayC: 0ms;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.MomentRow:nth-last-child(1) {
    border-bottom: 1px solid var(--grey4-color);
}

.MomentRow .moment-time {
    width: 70px;
}

.MomentRow .MomentRowContent {
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: height var(--durC), width var(--durC);
    transition-delay: var(--delayC);
}

.MomentRow .MomentRowContentGutter {
    width: 100%;
    transition: height var(--durC), width var(--durC);
    transition-delay: var(--delayC);
}

.MomentRow .MomentRowContentGutter.title {
    display: flex;
}

.MomentRow .MomentRowContentGutter.title .tags-preview{
    left: 174px;
}

.MomentRow.expanded .MomentRowContentGutter.title .tags-preview{
    left: 174px;
    opacity: 1;
}

.MomentRow .MomentRowContentGutter.title p {
    min-width: 20ch;
}

.MomentRow .MomentRowContent:after {
    content: " ";
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%);
    width: 20px;
    height: 73px;
    position: absolute;
    top: 0;
    right: 0;
}

.side-panel-content .MomentRow .MomentRowContent:after {
    content: "";
    background: inherit;
    width: 0;
    height: 0;
}

.MomentRow .avatars {
    width: 40px;
    height: 40px;
    float: left;
    transition: height var(--durC), width var(--durC);
    transition-delay: 0s;
}

.MomentRow .Avatar {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: top var(--dur1), left var(--dur1), opacity var(--dur1);
    transition-delay: var(--delay1);
    transition-timing-function: cubic-bezier(0.7, -0.4, 0.4, 1.4);
}
.MomentRow .single .Avatar {
    left: 32px;
}
.MomentRow .double .Avatar,
.MomentRow .multi .Avatar {
    left: 66px;
}

.MomentRow .Avatar:nth-child(1) {
    opacity: 1;
    left: 4px;
}
.MomentRow .Avatar:nth-child(2) {
    opacity: 1;
    left: 36px;
}
.MomentRow .Avatar:nth-child(3):last-child {
    opacity: 1;
    left: 68px;
}
.MomentRow .Avatar:nth-child(4) {
    opacity: 0;
}
.MomentRow .Avatar:nth-child(5) {
    opacity: 0;
}
.MomentRow .Avatar:nth-child(6) {
    opacity: 0;
}
.MomentRow .Avatar:nth-child(7) {
    opacity: 0;
}
.MomentRow .Avatar:nth-child(8) {
    opacity: 0;
}
.MomentRow .Avatar:nth-child(9) {
    opacity: 0;
}
.MomentRow .Avatar:nth-child(10) {
    opacity: 0;
}
.MomentRow .Avatar.avatar-group {
    opacity: 1;
}

.MomentRow .tags-preview {
    position: absolute;
    top: 0;
    left: 118px;
    width: 200%;
    overflow: hidden;
    opacity: 1;
    transition: top var(--dur2), left var(--dur2), opacity var(--dur2);
    transition-delay: 0s;
    padding: 2px 0;
}

.MomentRow .tags {
    max-height: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    padding-top: 0;
    transition: opacity var(--dur2), padding-top var(--dur2), top var(--dur2), left var(--dur2), max-height var(--dur2);
    transition-delay: 0s;
}

.MomentRow .tags-preview.only-tags {
    left: 4px;
}

.MomentRow .Chip {
    margin: 5px 8px 5px 0;
    float: left;
}




.MomentRow.expanded .MomentRowContentGutter {
    width: 100%;
}

.MomentRow.expanded .avatars {
    width: 100%;
    height: 48px;
}
.MomentRow.expanded .avatars.tall {
    width: 100%;
    height: 92px;
}
.MomentRow.expanded .avatars.none {
    width: 100%;
    height: 0;
}

.MomentRow.expanded .Avatar:nth-child(1) {
    opacity: 1;
    left: 4px;
    transform: rotate(0deg);
}
.MomentRow.expanded .Avatar:nth-child(2) {
    opacity: 1;
    left: 48px;
}
.MomentRow.expanded .Avatar:nth-child(3) {
    opacity: 1;
    left: calc(44px * 2 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(4) {
    opacity: 1;
    left: calc(44px * 3 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(5) {
    opacity: 1;
    left: calc(44px * 4 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(6) {
    opacity: 1;
    top: 44px;
    left: calc(44px * 0 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(7) {
    opacity: 1;
    top: 44px;
    left: calc(44px * 1 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(8) {
    opacity: 1;
    top: 44px;
    left: calc(44px * 2 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(9) {
    opacity: 1;
    top: 44px;
    left: calc(44px * 3 + 4px);
}
.MomentRow.expanded .Avatar:nth-child(10) {
    opacity: 1;
    top: 44px;
    left: calc(44px * 4 + 4px);
}
.MomentRow.expanded .Avatar.avatar-group {
    opacity: 0;
}


.MomentRow.expanded .tags {
    opacity: 1;
    padding-top: 4px;
    max-height: 500px;
    top: 0;
}
.MomentRow.expanded .tags.only-tags {
    padding-top: 0;
    margin-left: 4px;
}

.MomentRow.expanded .tags-preview {
    opacity: 0;
}

.MomentRow.expanded .tags-preview.only-tags {
    left: 4px;
}


.MomentRow .icon-trace-id-filled,
.MomentRow .icon-trace-id-empty {
    font-size: var(--spacing-6x);
}

.MomentRow .icon-trace-id-filled:hover,
.MomentRow .icon-trace-id-empty:hover {
    cursor: pointer;
    font-size: var(--spacing-8x);
}

.MomentRow img.star:active {
    cursor: pointer;
    transform: scale(1.1, 1.1);
}

/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {

    .MomentRow.expanded .tags-preview {
        opacity: 0;
    }

    .MomentRow.expanded .Avatar:nth-child(1) {
        opacity: 1;
    }
    .MomentRow.expanded .Avatar:nth-child(2) {
        opacity: 1;
    }
    .MomentRow.expanded .Avatar:nth-child(3) {
        opacity: 1;
    }
    .MomentRow.expanded .Avatar:nth-child(4) {
        opacity: 1;
    }
    .MomentRow.expanded .Avatar:nth-child(5) {
        opacity: 1;
    }
    .MomentRow.expanded .Avatar:nth-child(6) {
        opacity: 1;
        top: 0;
        left: calc(44px * 5 + 4px);
    }
    .MomentRow.expanded .Avatar:nth-child(7) {
        opacity: 1;
        top: 0;
        left: calc(44px * 6 + 4px);
    }
    .MomentRow.expanded .Avatar:nth-child(8) {
        opacity: 1;
        top: 0;
        left: calc(44px * 7 + 4px);
    }
    .MomentRow.expanded .Avatar:nth-child(9) {
        opacity: 1;
        top: 0;
        left: calc(44px * 8 + 4px);
    }
    .MomentRow.expanded .Avatar:nth-child(10) {
        opacity: 0;
        left: calc(44px * 9 + 4px);
    }
    .MomentRow.expanded .Avatar.avatar-group {
        opacity: 0;
    }
    .MomentRow.expanded .avatars {
        width: 100%;
        height: 44px;
    }
    .MomentRow.expanded .avatars.tall {
        height: 44px;
    }
    .MomentRow.expanded .avatars.none {
        height: 0;
    }
}

/*** End Moment Row ***/




/*** Moment Title ***/

.MomentTitle .avatars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.MomentTitle .avatars div:last-child {
    margin-right: var(--spacing-4x);
}

.MomentTitle .avatars > .avatar-group {
    display: none;
}

.MomentTitle .tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
}

.MomentTitle .tags > * {
    margin: var(--spacing-2x) var(--spacing-2x) var(--spacing-2x) 0;
}

.MomentTitle .RoundButton.add-tags-button {
    background: var(--grey-transparent-color);
}

.MomentTitle .RoundButton.add-tags-button:hover {
    background: var(--grey-transparent-hover-color);
}

.MomentTitle .Row {
    cursor: pointer;
}

/*** End Moment Title ***/



/*** Input ***/

.Input {
    padding-bottom: 16px;
}

.Input.error {
    padding-bottom: 0;
}

.Input .Row .text-small {
    line-height: 16px;
}

.Input .input-wrapper {
    position: relative;
	padding: 4px;
	border-radius:  12px;
	background-color: transparent;
	transition: 0.5s background-color;
	left: -4px;
    width: calc(100% + 8px);
}
.Input.focused .input-wrapper {
	background-color: var(--sky-tint1-color);
}

.Input input, .Input textarea {
    border: 2px solid var(--grey3-color);
    padding: var(--spacing-4x);
    outline: none;
    font-size: var(--body-font-size);
    line-height: var(--spacing-6x);
    width: 100%;
    border-radius: var(--spacing-2x);
    transition: 0.5s border;
    resize: none;
    display: block;
}

.LoginForm .Input input {
    height: var(--spacing-14x);
}

.Input textarea {
	min-height: 200px;
}

.Input input::placeholder {
    color: var(--grey3-color);
}

.Input input.icon-on-left, .Input textarea.icon-on-left {
    padding-left: var(--spacing-10x);
}

.Input input.icon-on-right, .Input textarea.icon-on-right {
    padding-right: var(--spacing-10x);
}

.Input input:focus, .Input textarea:focus {
    border-color: var(--trace-regular-color);
}

.Input.error input, .Input.error textarea {
    border-color: var(--error-regular-color);
}

.Input.code input {
    letter-spacing: 0.7em;
}

.Input .icon-left,
.Input .icon-right {
    position: absolute;
    top: 0;
    height: 100%;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
	color: var(--grey3-color);
}

.Input .icon-left i {
	margin-left: 8px;
}

.Input .icon-left {
    left: 0;
}

.Input .icon-right {
    right: 0;
}


/*** Code Inputs TERRIBLE TERRIBLE hack for the library in use, do not copy ***/

.react-code-input input[type="number"]::-webkit-outer-spin-button,
.react-code-input input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-code-input input[type="number"] {
    -moz-appearance: textfield;
    font-family : var(--body-font-family);
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    color: var(--black-color);
    border-radius: var(--spacing-2x);
    border: 2px solid var(--grey3-color);
    box-shadow: none;
    margin: var(--spacing);
    line-height: var(--spacing-6x);
    width: 42px;
    height: var(--spacing-14x);
    background-color: var(--white2-color);
    outline: none;
    text-align: center;
    box-sizing: border-box;
    caret-color: white;
}

.LoginForm .react-code-input {
    height: var(--spacing-17x);
}

.react-code-input input[type="number"] {
    width: var(--spacing-7x);
    height: var(--spacing-12x);
    box-sizing: content-box;
}

.error-code.react-code-input input[type="number"] {
    border: 2px solid var(--error-regular-color);
}

.react-code-input input[type="number"]:first-child {
    margin-left: 0 !important;
}
.react-code-input input[type="number"]:last-child {
    margin-right: 0 !important;
}

.react-code-input input[type="number"]:focus {
    border-color: var(--sky-tint1-color);
    border-width: var(--spacing);
    transition: 0.5s border-color;
}


/*** End Input ***/

/*** Preview card ***/

.preview-card {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: none;
    justify-content: center;
}

/*** Preview card end ***/

/*** Dialogs ***/

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 50000;
    display: none;
}

.Modal.open, .Modal.closed {
    display: block;
}

.Shade {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    background: rgba(50, 51, 51, 0.5);
    backdrop-filter: blur(4px);
    transition: background 150ms;
}

.open .Shade {
    animation-name: fadeIn;
    animation-duration: 150ms;
}

.closed .Shade {
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 50ms;
}

.ModalWindowInner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: var(--white2-color);
    display: flex;
    flex-direction: column;
    border-radius: 0;
    transition: opacity 150ms;
}

.ModalWindowInner.partial-screen-fill {
    border-radius: 24px;
    width: auto;
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    padding-bottom: 0;
    min-width: 344px;
    border-top-left-radius: var(--spacing-6x);
    border-top-right-radius: var(--spacing-6x);
    overflow: hidden;
}

.open .ModalWindowInner {
    transition: opacity 0ms;
    opacity: 1;
}

.closed .ModalWindowInner {
    opacity: 0;
}

.TitleSection {
    box-shadow: inset 0 -2px 0 var(--grey4-color);
    padding: var(--spacing-2x);
    display: flex;
    align-items: center;
    justify-content: center;
}

.TitleSection h4 {
    margin-top: var(--spacing-2x);
    margin-bottom: var(--spacing-2x);
}

/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .TitleSection h4 {
        font-size: var(--heading4-font-size);
        line-height: var(--spacing-6x);
        letter-spacing: -0.02em;
    }

    .TitleSection {
        border-radius: var(--spacing-6x) var(--spacing-6x) 0 0;
    }
}

.ContentSection {
    flex: 10;
    padding: var(--spacing-4x);
    overflow-x: hidden;
    overflow-y: auto;
}

/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .ContentSection {
        padding: var(--spacing-8x) var(--spacing-8x) var(--spacing-4x) var(--spacing-8x);
    }
}

.FooterSection {
    padding: var(--spacing-4x);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.FooterSection > * {
    margin-bottom: var(--spacing-4x);
}

.FooterSection > *:last-child {
    margin-bottom: 0;
}

/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {
    .FooterSection {
        padding: var(--spacing-4x) var(--spacing-4x) var(--spacing-4x) var(--spacing-4x);
        flex-direction: row;
        justify-content: center;
    }

    .FooterSection > * {
        margin-bottom: 0;
        margin-right: var(--spacing-4x);
    }

    .FooterSection > *:last-child {
        margin-bottom: 0;
        margin-right: 0;
    }
}

.BackButton,
.CloseButton {
    line-height: 18px;
    width: var(--spacing-6x);
    overflow: hidden;
    text-align: center;
    color: var(--grey3-color);
    position: absolute;
    right: var(--spacing-2x);
    cursor: pointer;
}

.RoundButton.BackButton:hover,
.RoundButton.CloseButton:hover {
    background: var(--grey4-color);
}

.RoundButton.BackButton:active,
.RoundButton.CloseButton:active {
    background: var(--grey4-pressed-color);
}

.BackButton i,
.CloseButton i {
    text-align: center;
}

.BackButton i::before,
.CloseButton i::before {
    line-height: 24px;
    width: 24px;
    text-align: center;
}

.BackButton {
    position: absolute;
    left: var(--spacing-2x);
    cursor: pointer;
}

/* v2 Tablet and Larger (MD+) */
@media only screen and (min-width: 541px) and (min-height: 541px) {

    .ModalWindowInner {
        border-radius: 24px;
        width: auto;
        top: 50%;
        left: 50%;
        bottom: auto;
        transform: translate(-50%, -50%);
        padding-bottom: 0;
        min-width: 500px;
        max-width: 80%;
    }

    .open .ModalWindowInner {
        opacity: 1;
        transition: opacity 0ms;
    } 

    .closed .ModalWindowInner {
        bottom: auto;
        opacity: 0;
    }

}

@keyframes slideInUpDesktop {
  from {
    transform: translate(-50%, 100%);
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes slideOutDownDesktop {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, 100vh);
  }
}


/*** End Dialogs ***/



/*** Step Controller ***/

.dot {
    width: 8px;
    height: 8px;
    background: var(--grey4-color);
    border-radius: 2px;
    overflow: hidden;
    text-indent: -99999px;
}

.dot.active {
    background: var(--grey2-color);
}

.step-progress .progress-bar-inner {
    position: absolute;
    height: 2px;
    background: var(--grey2-color);
    /* Height of title - height of progress bar */
    top: 54px;
    left: 0;
    z-index: 2;
}

.step-progress .progress-bar-outer {
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--grey4-color);
    /* Height of title - height of progress bar */
    top: 54px;
    left: 0;
    z-index: 1;
}

/*** End Step Controller ***/



/*** Icon Overrides ***/

.icon-trace:before {
    width: 4.5em;
}

.icon-close:before {
    margin: 0;
    letter-spacing: 0;
}

i[class^="icon"].clickable {
    cursor: pointer;
}

/*** End Icon Overrides ***/



/*** Session Module ***/

.LoginForm {
    width: 340px;
}

.LoginForm .Input.code {
    width: 210px;
    max-width: 210px;
}

.LoginForm .Input.code input {
    padding-left: var(--spacing-5x);
    padding-right: var(--spacing-5x);
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: var(--heading4-font-size);
    line-height: var(--spacing-6x);
}

/*** End Session Module ***/

/***** HIDE styles *****/
/* no display: * styles after this point*/

.hide { display: none; }
/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .hide-xs { display: none; }
}
/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .hide-sm { display: none; }
}
/* v2 Tablet Only (MD)*/
@media only screen and (min-width: 541px) and (min-height: 541px) and (max-width: 1023px) {
    .hide-md { display: none; }
}
/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .hide-lg { display: none; }
}
/***** end HIDE styles *****/