
.TRCInput {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.TRCInput.full {
  width: 100%;
}
.TRCInput input, .TRCInput select {
  width: 100%;
}

.TRCInput input {
  height: var(--spacing-14x);
  padding: var(--spacing-4x);
  border: 2px solid var(--grey3-color);
  max-width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  font-size: var(--body-font-size);
  line-height: var(--spacing-6x);
  background: var(--white2-color);
}

.TRCInput input::placeholder {
  color: var(--grey3-color);
}

.TRCInput input:-moz-placeholder {
  color: var(--grey3-color);
}

.TRCInput input::-webkit-input-placeholder {
  color: var(--grey3-color);
}

.TRCInput input:-ms-input-placeholder {
  color: var(--grey3-color);
}

.TRCInput input:focus {
  outline: none;
  border-color: var(--trace-regular-color);
}

.TRCInput input.full-width {
  width: calc(100% - 10px);
}

.TRCInput input[disabled] {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  background: transparent;
}

.TRCInput input[type="submit"][disabled] {
  cursor           : default;
  background-color : var(--grey3-color);
  color            : var(--white2-color);
  box-shadow       : none;
}

.TRCInput select {
  height: 60px;
  background-color: #fff;
  /** FIX: background: #fff url("../assets/img/components/select_arrow.svg") no-repeat; **/
  background-position: calc(100% - 15px) 24px;
  border: 2px solid #ccc;
  border-radius: 100px;
  min-width: 74px;
  padding: 0 30px 0 20px;
    
  line-height: 23px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.TRCInput select:-moz-placeholder {
  color: #ccc;
}

.TRCInput select:-webkit-input-placeholder {
  color: #ccc;
}

.TRCInput select:-ms-input-placeholder {
  color: #ccc;
}

.TRCInput select:focus {
  outline: none;
  border-color: #ccc;
}

.invalid input,
.invalid input:focus,
.TRCInput input.invalid, 
.TRCInput input:focus.invalid, 
.TRCInput select.invalid,
.TRCInput select:focus.invalid {
  border-color: #EC5366;
}

.TRCInput .error {
  float: left;
  width: 100%;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: inherit;
  line-height: 1.5;
  color: #EC5366;
  text-align: left;
}


