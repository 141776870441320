.UploadMomentFooter .upload-moment-controls-footer-inner {
    align-content: flex-end;
    background-color: var(--white2-color);
    bottom: 0;
    flex: 1;
    flex-wrap: nowrap;
    margin-top: var(--spacing-4x);
    margin-right: 0;
    width: 100%;
}

.UploadMomentFooter .upload-moment-controls-footer-inner .Button {
   flex-grow: 1;
}

.UploadMomentFooter .upload-moment-controls-footer-inner .upload-progress-container {
    margin-left: 0;
    margin-right: var(--spacing-4x);
    width: var(--spacing-10x);
 }

.UploadMomentFooter .upload-moment-controls-footer-inner .upload-progress-container.hide-button {
    margin-left: var(--spacing-4x);
    margin-right: var(--spacing-4x);
    width: var(--spacing-10x);
}

.UploadMomentFooter .upload-moment-controls-footer-inner-message {
    border-radius: var(--spacing-4x);
    border-width: 2px;
    border-style: solid;
    padding: var(--spacing-4x);
    margin-right: 0;
}

.UploadMomentFooter .upload-moment-controls-footer-inner-message.warning {
    border-color: var(--warning-color);
    background-color: var(--warning-tint2-color);
    margin-right: 0;
}

.UploadMomentFooter .upload-moment-controls-footer-inner-message.info {
    border-color: var(--sky-color);
    background-color: var(--sky-tint2-color);
    margin-right: 0;
}