.web-notification .web-notification-side-info .notification-button-celebration-disabled {
    font-size: var(--heading4-font-size);
    opacity: 0.50;
    cursor: default !important;
    width: 100%;
}

.web-notification .web-notification-side-info .notification-button-content-grow {
    font-size: var(--heading4-font-size);
    transition: ease-out 0.3s;
    width: 100%;
}

.web-notification .web-notification-side-info .notification-button-content-grow:hover {
    font-size: var(--heading3-font-size);
    transition: ease-out 0.3s;
}

.web-notification .web-notification-side-info .notification-button-content-grow:active {
    animation: wait-explode 2.5s linear 1;
}

@keyframes wait-explode {
    0% {
        font-size: 21px;
    }

    5% {
        font-size: 18px;
    }

    10% {
        font-size: 15px;
    }

    15% {
        font-size: 12px;
    }

    50% {
        font-size: 12px;
    }

    55% {
        font-size: 21px;
    }

    60% {
        font-size: 24px;
    }

    65% {
        font-size: 28px;
    }

    70% {
        font-size: 32px;
    }

    100% {
        font-size: 21px;
    }
}

.web-notification .web-notification-side-info .notification-save-button {
    width: 100%;
}


@media only screen and (min-width: 1024px) {
    .web-notification .web-notification-side-info .notification-save-button {
        width: 184px;
    }
}
