.NotificationsPanel .title {
    display: block;
    text-align: center;
    border-bottom: 2px solid var(--grey4-color);
}

.NotificationsPanel i.icon-cheveron_left {
    position: absolute;
    align-self: center;
    left: var(--spacing-4x);
    top: var(--spacing-4x);
}

.NotificationsPanel .group-label {
    border-top: 2px solid var(--grey4-color);
}

.NotificationsPanel .notifications-filter-chip {
    width: fit-content;
    cursor: pointer;
}

.NotificationsPanel .CloseButton {
    top: var(--spacing-2x);
}

.NotificationsPanel .FilterMenu {
    position: sticky;
    top: 0;
    background-color: var(--white2-color);
    z-index: 1000;
    border-bottom: 2px solid var(--grey4-color);
}

@media only screen and (min-width: 1024px) {
    .NotificationsPanel .title {
        display: none;
    }
}