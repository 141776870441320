.StackedAvatars .Avatar {
    z-index: 2;
}

.StackedAvatars .second-avatar {
    left: -8px;
}

.StackedAvatars .second-avatar.two-avatars-margin {
    margin-right: var(--spacing-2x);
    left: -24px;
}

.StackedAvatars .third-avatar {
    left: -40px;
    bottom: var(--spacing-4x);
    z-index: 1;
}