.Tabs {
  width: 100%;
}

.Tabs .tabs-row {
  width: 100%;
  justify-content: center;
}

.Tabs .Tab {
  max-width: 108px;
}