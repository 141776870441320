.upload-moment-preview .SpotlightOverlay,
.personalize-preview .SpotlightOverlay {
    cursor: default;
    height: 100%;
    position: absolute;
    left: 0;
    top: var(--spacing-14x);
    width: 100%;
    z-index: 500;
    background-image: url(../../assets/img/spotlight-overlay.svg);
    background-position: -2000px -2000px;
    background-size: 4000px 4000px;
    background-repeat: no-repeat;
}

.upload-moment-preview .SpotlightOverlay.draggable,
.personalize-preview .SpotlightOverlay.draggable {
    background-image: url(../../assets/img/edit-spotlight-overlay.svg);
    cursor: pointer;
}

.upload-moment-preview .SpotlightOverlay.draggable.grab,
.personalize-preview .SpotlightOverlay.draggable.grab {
    background-image: url(../../assets/img/edit-spotlight-overlay.svg);
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

@media only screen and (min-width: 1023px) {
    
    .upload-moment-preview .SpotlightOverlay,
    .personalize-preview .SpotlightOverlay {
        top: 0;
    }
}