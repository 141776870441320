.ProgressBar {
    position: relative;
    height: 8px;
}

.progress-bar-elements {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--spacing-4x);
}