
.dialog-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 5000;
    overflow: hidden;
    --top-hidden: 200vh;
    --top-show: 0;
}

.dialog-shade {
    width: 100%;
    height: 100%;
    background: #00000000;
    transition: background 0.5s ease-out;
}

.show .dialog-shade {
    background: #00000088;
}

.dialog {
    position: absolute;
    z-index: 2001;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: #000;
    background: #F9F9F9;
    box-shadow: rgba(0, 0, 0, 0.4) 0 10px 40px;

    display: flex;
    flex-direction: column;

    top: var(--top-hidden);
    transition: top 0.5s ease-out;
}

.show .dialog {
    top: var(--top-show);
}

.dialog .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: #fff;
    box-shadow: inset 0 -1px 0 0 #DDDDDD;
}

.dialog-title h2 {
    margin: 0;
    padding: 0;
    display: inline;
    font-weight: bold;
    line-height: 24px;
    text-transform: uppercase;
}

.dialog a.dialog-title-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0;
    padding: 0;
    color: #bbb;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    width: 30px;
    height: 30px;
    /** FIX: background-image: url(../assets/img/close-x.svg); **/
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
    text-indent: -99999px;
}

.dialog .dialog-content {
    padding: var(--spacing-4x);
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    flex: 10;
}

.dialog .dialog-item {
    width: 100px;
    padding: 95px 10px 5px 10px;
    margin-left: 27px;

    color: #000000;
    line-height: 1.25;
    text-align: center;
}

.dialog-content .dialog-item:first-child {
    margin-left: 0;
}

.dialog-content h3 {
    margin: 0 0 0.25em;
    padding: 0;
    font-weight: bold;
    line-height: 22px;
}

.dialog-content p {
    margin: 0 0 1em;
    padding: 0;
    font-weight: normal;
    line-height: 1.5;
}

.dialog .dialog-actions {
    padding: 16px 30px 76px;
    display: flex;
    flex-direction: column;
    background: #fff;
}

.dialog .dialog-actions .TRCButton {
    min-width: 200px;
    margin-bottom: 16px;
}


/* mobile only */
@media only screen and (max-width: 500px) {
    .sheet-dialog .dialog {
        max-height: 90vh;
        height: auto;
        top: auto;
        bottom: -200vh;
        transition: bottom 0.5s ease-out;
        padding-bottom: 90px;
    }
    .sheet-dialog.show .dialog {
        bottom: 0;
    }
}

/* Mobile Landscape */
@media only screen and (min-width: 417px) and (max-height: 500px) {
    .dialog .dialog-actions {
        padding: 16px 30px;
    }
}

/* Table Landscape + */
@media only screen and (min-width: 567px) and (min-height: 500px) {
    .dialog {
        width: 375px;
        left: calc(50% - 175px);
    }
    .sheet-dialog .dialog {
        max-height: 100vh;
    }
}


/* Tablet + */
@media only screen and (min-width: 768px) and (min-height: 700px) {
    .dialog {
        width: 400px;
        height: 700px;
        left: calc(50% - 200px);
    }
    .dialog-modal {
        --top-show: calc((100% - 700px)/2);
        --top-hidden: 200vh;
    }
    .sheet-dialog.show .dialog {
        height: 600px;
        padding-bottom: 0;
        bottom: calc((100% - 600px)/2);
    }
    .dialog .dialog-content {
        overflow-y: auto;
    }
}
