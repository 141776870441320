.Scoreboard .scoreboard-home-team {
    width: var(--spacing-5x);
    height: var(--spacing-8x);
    border-radius: var(--spacing-10x) 0 0 var(--spacing-10x);
    align-items: center;
    justify-content: center;
}

.Scoreboard .scoreboard-result {
    width: var(--spacing-11x);
    height: var(--spacing-8x);
    background: var(--grey1-color);
    justify-content: center;
    align-items: center;
}

.Scoreboard .scoreboard-result .score {
    font-size: var(--small-font-size);
    line-height: var(--spacing-5x);
    font-family: var(--body-font-family);
    font-weight: var(--bold-font-weight);
}

.Scoreboard .scoreboard-away-team {
    width: var(--spacing-5x);
    height: var(--spacing-8x);
    border-radius: 0 var(--spacing-10x) var(--spacing-10x) 0;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 541px) {
    .Scoreboard .scoreboard-home-team {
        width: var(--spacing-17x);
        height: var(--spacing-8x);
        padding: var(--spacing) var(--spacing) var(--spacing) var(--spacing-2x);
    }

    .Scoreboard .team-name {
        font-size: var(--heading5-font-size);
        line-height: var(--spacing-5x);
        font-family: var(--body-font-family);
        font-weight: var(--bold-font-weight);
    }

    .Scoreboard .scoreboard-result {
        width: var(--spacing-11x);
        height: var(--spacing-8x);
    }

    .Scoreboard .scoreboard-away-team {
        width: var(--spacing-17x);
        height: var(--spacing-8x);
        padding: var(--spacing) var(--spacing-2x) var(--spacing) var(--spacing);
    }
}

@media only screen and (min-width: 1024px) {
    .Scoreboard .scoreboard-home-team {
        width: 132px;
        height: var(--spacing-10x);
        padding: var(--spacing-2x) var(--spacing-4x) var(--spacing-2x) var(--spacing-6x);
    }

    .Scoreboard .team-name {
        font-size: var(--body-font-size);
        line-height: var(--spacing-6x);
    }

    .Scoreboard .scoreboard-result {
        width: var(--spacing-17x);
        height: var(--spacing-10x);
        padding: var(--spacing-2x) 0;
    }

    .Scoreboard .scoreboard-result .score {
        font-size: var(--body-font-size);
        line-height: var(--spacing-6x);
    }

    .Scoreboard .scoreboard-away-team {
        width: 132px;
        height: var(--spacing-10x);
        padding: var(--spacing-2x) var(--spacing-6x) var(--spacing-2x) var(--spacing-4x);
    }
}