.family-member {
    border: 2px solid var(--grey4-color);
    border-radius: var(--spacing-4x);
    cursor: pointer;
}

.family-member .family-member-row {
    position: relative;
}

.family-member .family-member-row i {
    position: absolute;
    right: var(--spacing-8x);
    top: calc(50% + var(--spacing-2x));
}

.family-member .family-member-row .badge {
    position: absolute;
    bottom: var(--spacing-2x);
    left: 50%;
}

.family-member .badge {
    color: var(--white2-color);
    border-width: 2px;
    border-radius: var(--spacing-6x);
    font-size: var(--small-font-size);
    font-weight: var(--heavy-font-weight);
}

@media only screen and (min-width: 1024px) {
    .family-member:hover {
        background: var(--grey4-color);
    }

    .family-member:active {
        background: var(--grey4-pressed-color);
    }
}