.game-summary-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: var(--spacing-4x) var(--spacing-6x) var(--spacing-4x) var(--spacing-4x);
}

.game-summary-row p,
.trace-root .game-summary-row p{
    margin-bottom: var(--spacing-2x);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey2-color);
    text-transform: uppercase;
}

.game-summary-row .home,
.game-summary-row .away {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: var(--spacing);
}

.game-summary-row .home {
    color: var(--black-color);
}

.game-summary-row .away {
    color: var(--grey3-color);
}

.game-summary-row .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: var(--spacing-2x);
}

.game-summary-row .home .team-name,
.game-summary-row .away .team-name {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.game-summary-row .score {
    min-width: 16px;
    text-align: center;
    padding-left: var(--spacing-3x);
}
