.personalize-controls-header {
    box-shadow: inset 0 -2px 0 var(--grey4-color);
    height: var(--spacing-14x);
    justify-content: center;
    width: 100%;
    z-index: 503;
}

.personalize-controls-header {
    background: var(--white2-color);
}

.personalize-controls-header.mobile {
    display: flex;
}

.personalize-controls-header.desktop {
    display: none;
}

/* Desktop */

@media only screen and (min-width: 1023px) {

    .personalize-controls-header {
        border-top-right-radius: var(--spacing-6x);
        z-index: auto;
    }

    .personalize-controls-header.mobile {
        display: none;
    }
    .personalize-controls-header.desktop {
        display: flex;
    }

}
