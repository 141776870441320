.video-lite {
    width: 100%;
    height: 100%;
}

.video-intersection-container {
    position: relative;
}

.video-cover.cursor {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
}

.video-cover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: var(--spacing-4x) var(--spacing-4x) 0 0;
}

.video-cover img.hidden {
    opacity: 0;
}

.video-cover video.video-lite.hidden {
    opacity: 0;
}

.video-cover .text-center {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: var(--spacing-3x);
}

.video-cover .moment-preview-progress-bar {
    position: absolute;
    bottom: 0;
}