.Notification {
    display: none;
    justify-content: center;
    width: 100%;
    position: fixed;
    z-index: 1000;
    bottom: var(--spacing-4x);
    -webkit-animation: slideOut 500ms forwards;
    -moz-animation: slideOut 500ms forwards;
    animation: slideOut 500ms forwards;
}

.Notification.open {
    display: flex;
    -webkit-animation: slideIn 500ms forwards;
    -moz-animation: slideIn 500ms forwards;
    animation: slideIn 500ms forwards;
}

.NotificationWindowInner {
    display: flex;
    justify-content: center;
    width: 320px;
    padding: var(--spacing-4x);
    background: var(--white2-color);
    box-shadow: var(--notification-shadow);
    border-radius: var(--spacing-6x);
}

@keyframes slideIn {
    0% {
        transform: translateY(400px);
    }
    100% {
        transform: translateY(0px);
    }
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateY(400px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes slideOut {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(400px);
    }
}

@-webkit-keyframes slideOut {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(400px);
    }
}
