.confirmation-modal .confirmation-image {
  display: flex;
  justify-content: center;
}

.confirmation-modal .confirmation-image img {
  width: 180px;
}

@media only screen and (max-width: 541px) {
  .confirmation-modal .FooterSection {
    flex-direction: column-reverse;
  }

  .confirmation-modal .FooterSection .go-to-trace-id-button {
    margin-bottom: var(--spacing-2x);
  }

  .confirmation-modal .FooterSection .go-back-button {
    margin: 0;
  }
}

@media only screen and (min-width: 541px) {
  .confirmation-modal .go-to-trace-id-button div,
  .confirmation-modal .go-back-button div {
    padding-right: var(--spacing-6x);
    padding-left: var(--spacing-6x)
  }
}

@media only screen and (min-width: 1024px) {
  .confirmation-modal .ModalWindowInner {
    width: 500px;
    min-width: unset;
  }
}