.notifications-bell-icon {
    position: relative;
    cursor: pointer;
}

.notifications-bell-icon .RoundButton.bell-icon:hover {
    background: var(--grey4-color);
}

.notifications-bell-icon i[class^="icon"]:before {
    margin: 0;
}

.notifications-bell-icon .bell-dot {
    /* Values per design to be able to fit into bell icon */
    width: 9px;
    height: 9px;
    left: 23px;
    top: -17px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
