.AutoAddMomentToTraceiD {
    display: flex;
    flex-direction: column;
}

.AutoAddMomentToTraceiD .titles {
    position: absolute;
}

.AutoAddMomentToTraceiD .down-arrow {
    height: var(--spacing-14x);
    margin-bottom: var(--spacing-8x);
    margin-top: var(--spacing-8x);
}

.AutoAddMomentToTraceiD .first-container {
    min-height: 230px;
    max-height: 230px;
    position: relative;
    max-width: 100vw;
}

.AutoAddMomentToTraceiD .moment-thumbnail {
    height: 100%;
    min-height: 230px;
    max-height: 230px;
    min-width: min(541px, 100vw);
    width: auto;
}

.AutoAddMomentToTraceiD .moment-thumbnail.existing-item,
.AutoAddMomentToTraceiD .first-container.existing-item {
    min-height: max(500px, 88vh);
    max-height: max(500px, 88vh);
}

.AutoAddMomentToTraceiD .second-container {
    min-height: 272px;
    flex-direction: column;
    position: relative;
}

.AutoAddMomentToTraceiD .loading-container {
    min-height: 96px;
}

.AutoAddMomentToTraceiD .overlay {
    top: 0;
    left: 0;
    width: 100%;
    clear: float;
    height: 100%;
    opacity: 0.25;
    position: absolute;
    background-color: var(--confirmed-regular-color);
}

.AutoAddMomentToTraceiD .close-button {
    display: block;
    position: absolute;
    right: 0;
    top: var(--spacing-3x);
    z-index: 100;
}

.AutoAddMomentToTraceiD .close-button-landscape {
    display: none;
}

.AutoAddMomentToTraceiD .playlist-link {
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {
    .AutoAddMomentToTraceiD {
        max-width: 390px;
    }

    .AutoAddMomentToTraceiD .moment-thumbnail {
        min-height: 230px
    }

    .AutoAddMomentToTraceiD .first-container {
        max-width: 390px;
        min-height: 230px;
        max-height: 230px;
        position: relative;
    }

    .AutoAddMomentToTraceiD .second-container {
        position: relative;
    }

    .AutoAddMomentToTraceiD .moment-thumbnail.existing-item,
    .AutoAddMomentToTraceiD .first-container.existing-item {
        min-height: min(500px, 88vh);
        max-height: min(500px, 88vh);
        border-bottom-left-radius: var(--spacing-6x);
        border-bottom-right-radius: var(--spacing-6x);
        overflow: hidden
    }

}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {

    .AutoAddMomentToTraceiD .close-button {
        display: none;
    }

    .AutoAddMomentToTraceiD .close-button-landscape {
        display: block;
        position: absolute;
        right: 0;
        top: var(--spacing-3x);
        z-index: 100;
    }
    
    .AutoAddMomentToTraceiD {
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
    }

    .AutoAddMomentToTraceiD .overlay {
        height: 100vh;
    }

    .AutoAddMomentToTraceiD .first-container,
    .AutoAddMomentToTraceiD .second-container  {
        min-height: unset;
        max-height: unset;
        height: 100vh;
        width: 50vw;
    }
    
    .AutoAddMomentToTraceiD .moment-thumbnail {
        flex-direction: row;
        min-height: 100vh;
        max-height: 100vh;
        min-width: 50vw;
        width: 50vw;
    }
}