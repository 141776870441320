.MyMomentList { 
    min-height: calc(100vh - 80px);
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100vw - 16px);
    padding-top: var(--spacing-6x);
    width: calc(100vw - 16px);
}

.MyMomentList .moments-row {
    align-items: flex-start;
    display: flex;
    justify-content: center;
}

.MyMomentList .moments-row.game {
    align-items: center;
    display: flex;
    justify-content: center;
    height: var(--spacing-10x);
    text-align: center;
}

.MyMomentList .moments-row.moments {
    height: 266px;
}

.MyMomentList + .Shade {
    z-index: 1;
}