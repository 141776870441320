.playlist-info {
    cursor: pointer;
}

.playlist-info:hover {
    background: var(--grey4-color);
    border-radius: var(--spacing-2x);
}

.playlist-info:active {
    background: var(--grey4-pressed-color);
    border-radius: var(--spacing-2x);
}

.playlist-info .Column {
    width: 100%
}

.select-playlist-row {
    padding-right: 0;
    width: 100%;
    cursor: pointer;
}

.select-playlist-row .playlist-name {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.playlist-info .playlist-name {
    width: calc(100% - var(--spacing-10x));
    max-width: unset;
}

.playlist-info .playlist-name.shorten-names {
    width: calc(100% - 170px);
}

.playlist-info .playlist-name.unlock-names {
    width: calc(100% - 170px);
}

.playlist-info .playlist-privacy-column {
    align-self: unset;
    justify-content: center;
}

.playlist-info .playlist-privacy-column .playlist-privacy {
    flex: unset;
    flex-wrap: unset;
    position: absolute;
    right: 0;
    background: transparent;
    line-height: var(--spacing-6x);
    align-items: center;
    border-radius: var(--spacing-4x);
}

.playlist-privacy-column {
    flex: 1 1;
    align-self: center;
}

.playlist-privacy {
    flex: 1 1;
    justify-content: flex-end;
}

.EditSpotlightContainer {
    position: relative;
}

.EditSpotlightContainer .slider {
    position: relative;
}

.EditSpotlightContainer div.slider {
    background-color: var(--black-color);
}

.EditSpotlightContainer .slider .range-fill {
    display: block;
    background: var(--trace-regular-color);
    height: var(--spacing-3x);
    position: absolute;
    top: 0;
    z-index: 298;
}

.EditSpotlightContainer input.spotlight-range[type=range] {
    -webkit-appearance: none;
    height: var(--spacing-3x);
    border-width: 0;
    width: 100%;
    background-color: transparent;
    padding: 0;
    margin: 0;
    z-index: 300;
    display: block;
    position: relative;
}

.EditSpotlightContainer input.spotlight-range[type=range]:focus {
    outline: none;
}

.EditSpotlightContainer input.spotlight-range[type=range]::-webkit-slider-runnable-track {
    background: transparent;
    height: 100%;
}

.EditSpotlightContainer input.spotlight-range[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: var(--spacing-4x);
    width: var(--spacing-3x);
    margin: 0;
    padding: 0;
    border: none;
    background: transparent url("../../assets/img/spotlight-thumb.svg") no-repeat center center;
    position: relative;
}

.EditSpotlightContainer input.spotlight-range[type=range]::-moz-range-track {
    background: transparent;
    height: 100%;
}

.EditSpotlightContainer input.spotlight-range[type=range]::-moz-range-thumb {
    height: var(--spacing-4x);
    width: var(--spacing-3x);
    margin: 0;
    padding: 0;
    border: none;
    background: transparent url("../../assets/img/spotlight-thumb.svg") no-repeat center center;
    position: relative;
    border-radius: 0;
}