.UserPlaylists .side-left,
.UserPlaylists .side-right {
    flex-wrap: nowrap;
}

.UserPlaylists .side-left {
    flex-grow: 1;
}

.UserPlaylists .side-right {
    flex-grow: 0;
    justify-content: flex-end;
    white-space: nowrap;
}

.PlaylistSelector .playlist-info {
    cursor: pointer;
    min-height: var(--spacing-12x);
    max-height: var(--spacing-12x);
    flex-wrap: nowrap;
    width: 100%;
}

.PlaylistSelector .playlist-info .icon-upgrade {
    margin-left: 10px;
    margin-right: var(--spacing-2x);
}

.PlaylistSelector .create-suggested-btn {
    margin-left: 10px;
    margin-right: var(--spacing-2x);
}

.PlaylistSelector .playlist-info:last-child {
    margin-bottom: var(--spacing-4x);
}

.PlaylistSelector .color-square {
    border-radius: var(--spacing);
    height: var(--spacing-4x);
    margin-left: var(--spacing-3x);
    margin-right: var(--spacing-3x);
    width: var(--spacing-4x);
}

.PlaylistSelector .icon-trace-id-filled {
    margin-left: 9px;
    margin-right: 9px;
}

.PlaylistSelector .playlist-info:hover {
    background: var(--grey4-color);
    border-radius: var(--spacing-2x);
}

.PlaylistSelector .playlist-info:active {
    background: var(--grey4-pressed-color);
    border-radius: var(--spacing-2x);
}

.PlaylistSelector .playlist-info .Column {
    width: 100%
}

.PlaylistSelector .select-playlist-row {
    padding-right: 0;
    width: 100%;
    cursor: pointer;
}

.PlaylistSelector .select-playlist-row .icon-upgrade {
    margin-left: 10px;
    margin-right: var(--spacing-2x);
}

.PlaylistSelector .playlist-info .playlist-name {
    max-width: calc(100% - 92px);
    width: calc(100% - 92px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PlaylistSelector .select-playlist-row .playlist-name {
    display: flex;
    align-items: center;
    max-width: calc(100% - 92px);
    width: calc(100% - 92px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PlaylistSelector .playlist-info .playlist-name.shorten-names {
    max-width: calc(100% - 92px);
    width: calc(100% - 92px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PlaylistSelector .playlist-info .playlist-name.unlock-names {
    max-width: calc(100% - 62px);
    width: calc(100% - 62px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.PlaylistSelector .playlist-info .playlist-privacy-column {
    align-self: unset;
    justify-content: center;
}

.PlaylistSelector .playlist-info .playlist-privacy-column .playlist-privacy {
    flex: unset;
    flex-wrap: unset;
    position: absolute;
    right: 0;
    background: transparent;
    line-height: var(--spacing-6x);
    align-items: center;
    border-radius: var(--spacing-4x);
}

.PlaylistSelector .playlist-privacy-column {
    flex: 1 1;
    align-self: center;
}

.PlaylistSelector .playlist-privacy {
    flex: 1 1;
    justify-content: flex-end;
}

@media only screen and (min-width: 541px) and (min-height: 541px) {
    
    .PlaylistSelector {
        max-height: 375px; 
        min-height: min(375px, 90vh);   
    }
    
}