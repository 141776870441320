.InfoPanel {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: 2000;
    background: var(--white2-color);
    overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.InfoPanel::-webkit-scrollbar {
    display: none;
}

.InfoPanel-shade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 195;
}

@media only screen and (min-width: 1024px) {
    .InfoPanel {
        position: absolute;
        left: unset;
        bottom: unset;
        top: var(--spacing-20x);
        right: var(--spacing-14x);
        z-index: 200;

        /* Screen height - 72px header - 56px from the bottom - 8px from the header to info panel */
        max-height: calc(100vh - 72px - 56px - 8px);

        width: 724px;
        border: 2px solid var(--grey4-color);
        box-shadow: 0 var(--spacing-2x) var(--spacing-15x) rgba(0, 0, 0, 0.08);
        border-radius: var(--spacing-8x);

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
}