@keyframes resizeanim {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}
.resize-triggers {
    animation: 1ms resizeanim;
    visibility: hidden;
    opacity: 0;
}
.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
}
.resize-triggers > div {
    background: #eee;
    overflow: auto;
}
.contract-trigger:before {
    width: 200%;
    height: 200%;
}
