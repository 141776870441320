.moment-preview-cell-content {
    position: relative;
    width: 100%;
    padding-bottom: calc(56.25% + 80px);
}

.moment-preview-cell-content .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 56.25%;
}

.moment-preview-cell-content .preview .video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-color);
    border-radius: 10px;
}

.moment-preview-cell-content .game-summary-row {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.moment-preview-cell-content .video-lite {
    border-radius: 10px;
    cursor: pointer;
}

