.VideoPlayer {
    position: relative;
    background-color: var(--black-color);
    line-height: 0;
    box-sizing: border-box;
}

.VideoPlayer .video-player-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.VideoPlayer .video-container {
    width: 100%;
    height: 100%;
    background: var(--pure-black-color);
    overflow: hidden;
}

.VideoPlayer video {
    position: relative;
    background: var(--pure-black-color);
}

.VideoPlayer video.main-video {
    position: absolute;
    z-index: 1;
}

.VideoPlayer .sub-videos {
    position: absolute;
    top: calc(100% - 25px);
    width: 100%;
    transition: all 0.25s ease-in-out;
    overflow-x: scroll;
    background: transparent;
    backdrop-filter: none;
    background: var(--black-color);
    padding-left: 0px;
    width: 100%;
    max-width: 100vw;
    overflow: auto;
}

.VideoPlayer .sub-videos.open {
    top: calc(100% + 47px);
}

.VideoPlayer .sub-videos-content {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-2x) var(--spacing-4x) var(--spacing-4x)
        var(--spacing-4x);
}

.VideoPlayer .sub-video-container {
    transition: all 0.25s;
    height: auto;
    min-height: 46px;
    overflow: hidden;
    width: 87px;
    border: 2px solid var(--black-color);
    border-radius: var(--spacing-2x);
    border-width: 2px;
    margin-right: var(--spacing-4x);
    position: relative;
}

.VideoPlayer video.sub-video {
    background: var(--pure-black-color);
    width: 100%;
    overflow: hidden;
    min-height: 46px;
    border-radius: 6px;
}

.VideoPlayer.zoomed .video-container {
    transform: scale(1.14);
}

.VideoPlayer video.sub-video {
    cursor: pointer;
}

.VideoPlayer canvas {
    position: absolute;
    background: transparent;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.VideoPlayer .large-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110px;
    height: 110px;
    margin-top: -55px;
    margin-left: -55px;
    overflow: hidden;
    text-indent: -999999px;
    z-index: 4000;
    background-image: url(./assets/videoplayer-playspin-play.svg);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: center center;
}

.VideoPlayer .large-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 110px;
    height: 110px;
    margin-top: -55px;
    margin-left: -55px;
    overflow: hidden;
    text-indent: -999999px;
    z-index: 4000;
    background-image: url(./assets/videoplayer-playspin-spin.svg);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: center center;
    animation: spin 2s linear infinite;
}

.VideoPlayer .VideoPlayerControls {
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    transition: 0.25s bottom ease-in-out, 0.25s opacity ease-in-out;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.75));
    pointer-events: none;
    touch-action: none;
}

.VideoPlayer .VideoPlayerControls .VideoControls {
    padding-left: 0;
}

.VideoPlayer .VideoPlayerControls .FullScreenButton,
.VideoPlayer .VideoPlayerControls .SettingsButton {
    position: absolute;
    top: -60px;
    left: var(--spacing-14x);
    height: var(--spacing-10x);
    width: var(--spacing-10x);
    background: var(--grey-transparent-color);
}

.VideoPlayer .VideoPlayerControls .SettingsButton {
    left: var(--spacing-2x);
}

.VideoPlayer .VideoPlayerControls i {
    font-size: var(--body-font-size);
}

.VideoPlayer.active .VideoPlayerControls {
    bottom: 0;
    opacity: 1;
    pointer-events: auto;
    touch-action: auto;
}

.VideoPlayer .UserControls {
    background: var(--black-color);
}

.VideoPlayer .VideoSlider {
    display: none;
}

.VideoPlayer.active .sub-videos-content {
    background: transparent;
}

.VideoPlayer .sub-videos-content {
    min-height: var(--spacing-19x);
}

.VideoPlayer .video-info-overlay {
    background: rgba(0, 0, 0, 0.5);
    border-radius: var(--spacing);
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.VideoPlayer .video-info-overlay.no-stream {
    pointer-events: auto;
    background: linear-gradient(
            to top left,
            rgba(100, 100, 100, 0) 0%,
            rgba(100, 100, 100, 0) calc(50% - 0.8px),
            rgba(100, 100, 100, 1) 50%,
            rgba(100, 100, 100, 0) calc(50% + 0.8px),
            rgba(100, 100, 100, 0) 100%
        ),
        linear-gradient(
            to top right,
            rgba(100, 100, 100, 0) 0%,
            rgba(100, 100, 100, 0) calc(50% - 0.8px),
            rgba(100, 100, 100, 1) 50%,
            rgba(100, 100, 100, 0) calc(50% + 0.8px),
            rgba(100, 100, 100, 0) 100%
        );
}

.VideoPlayer .video-info-overlay p {
    width: 80%;
    text-overflow: ellipsis;
    margin-top: 0px;
    word-spacing: 100vw;
    text-align: center;
    line-height: 1.2;
    overflow: hidden;
}

.VideoPlayer .video-info-overlay i {
    margin-top: var(--spacing);
}

.VideoPlayer .VideoPlayerControls .multicam-button-container {
    position: relative;
}

.VideoPlayer .VideoPlayerControls .multicam-button-container .MulticamTooltip {
    position: absolute;
    top: -58px;
    right: -10px;
    z-index: 1000;
}

/* v2 Mobile Portrait Only */
@media only screen and (max-width: 540px) {
    .VideoPlayer .VideoPlayerControls {
        border-top: 1px solid var(--black-color);
        pointer-events: auto;
        touch-action: auto;
        margin-bottom: -48px;
    }
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (max-height: 540px) {
    .VideoPlayer canvas {
        touch-action: auto !important;
    }
}

/* v2 Mobile Landscape and Larger (SM+) */
@media only screen and (min-width: 541px) {

    .VideoPlayer .VideoPlayerControls .multicam-button-container .MulticamTooltip {
        right: -8px;
        top: -40px;
    }

    .VideoPlayer .video-container {
        height: 100%;
    }
    .VideoPlayer .VideoPlayerControls {
        background: transparent;
        z-index: 4;
        opacity: 0;
        padding-bottom: var(--spacing-4x);
        padding-right: var(--spacing-4x);
        padding-left: var(--spacing-4x);
    }

    .VideoPlayer .VideoPlayerControls.force-open {
        opacity: 1;
    }

    .VideoPlayer .VideoPlayerControls .FullScreenButton,
    .VideoPlayer .VideoPlayerControls .SettingsButton {
        display: flex;
        position: relative;
        top: unset;
        left: unset;
        height: unset;
        width: var(--spacing-14x);;
        background: unset;
    }

    .VideoPlayer .VideoPlayerControls i {
        font-size: 24px;
    }

    .VideoPlayer .UserControls {
        display: inherit;
        background: transparent;
    }
    .VideoPlayer .VideoSlider {
        display: flex;
    }
    .VideoPlayer .placeholder {
        display: none;
        margin-bottom: 0;
    }

    .VideoPlayer .sub-videos {
        display: flex;
        justify-content: flex-start;
        top: auto;
        bottom: -72px;
        z-index: 6;
        opacity: 0;
        background: linear-gradient(
            180deg,
            transparent,
            rgba(0, 0, 0, 0.75) 80%
        );
    }

    .VideoPlayer .sub-videos.open {
        top: auto;
        bottom: 0;
        opacity: 1;
    }

    .VideoPlayer .sub-videos.open ~ .VideoPlayerControls {
        bottom: 46px;
    }

    .VideoPlayer .sub-videos-content {
        justify-content: center;
    }

    .VideoPlayer .video-info-overlay .Label {
        margin-top: var(--spacing);
    }
}

/* v2 Desktop and Larger (LG+) */
@media only screen and (min-width: 1024px) {
    .VideoPlayer .video-info-overlay p {
        width: 90%;
        white-space: nowrap;
        word-spacing: unset;
    }

    .VideoPlayer .video-info-overlay i {
        margin-top: 0;
    }

    .VideoPlayer .VideoPlayerControls {
        border-top: 0px;
        padding-bottom: var(--spacing-8x);
        padding-right: var(--spacing-8x);
        padding-left: var(--spacing-8x);
    }
    .VideoPlayer .sub-videos {
        bottom: -218px;
        opacity: 1;
        padding-left: var(--spacing-14x);
    }
    .VideoPlayer .sub-videos-content {
        background: none;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .VideoPlayer .sub-video-container {
        width: 200px;
        height: auto;
        min-height: 110px;
        position: relative;
    }
    .VideoPlayer video.sub-video {
        min-height: 110px;
    }

    .VideoPlayer .sub-videos.open ~ .VideoPlayerControls {
        bottom: 118px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
