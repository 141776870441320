.UpgradeCompletionDialog .CancelBanner {
    align-items: center;
}

.UpgradeCompletionDialog .SuccessBanner {
    align-items: center;
}

.UpgradeCompletionDialog .SuccerBanner .imagesContainer {
    position: relative;
}

.UpgradeCompletionDialog.SuccerBanner .imagesContainer .badge {
    position: absolute;
    bottom: 0;
    right: 0;
}

.UpgradeCompletionDialog .CancelBanner .tracebot {
    height: 127px;
    width: 90px;
}

.UpgradeCompletionDialog .badge {
    color: var(--white2-color);
    border-width: 2px;
    border-radius: var(--spacing-6x);
    font-size: var(--small-font-size);
    font-weight: var(--heavy-font-weight);
}

.UpgradeCompletionDialog .faqLink {
    color: var(--trace-color);
    text-decoration: underline;
}

.UpgradeCompletionDialog .Button.upgrade-button {
    max-width: unset;
}

.UpgradeCompletionDialog .label-success {
    display: inline-block;
}

.UpgradeCompletionDialog .list-bullets {
    list-style: outside;
}

.UpgradeCompletionDialog .list-bullets li {
    margin-left: var(--spacing-4x);
    margin-bottom: var(--spacing);
}

/* v2 Mobile Landscape Only (SM) */
@media only screen and (min-width: 541px) and (min-height: 541px) {
    .UpgradeCompletionDialog .ModalWindowInner {
        max-width: 500px;
        min-width: 500px;
    }
    
}